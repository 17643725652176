import Vue from "vue";
import VeeValidate, { Field } from "vee-validate";
import { BSToAD } from "bikram-sambat-js";

Vue.use(VeeValidate, {
  inject: true,
  fieldsBagName: "veeFields",
});

VeeValidate.Validator.extend("landlineNo", {
  getMessage: (field: string, params: any[], data: any) =>
    "The " + field + " value is not a valid landline number. e.g. 4479690",
  validate: (value: any) => {
    if (value) {
      return /^([0-9]{7})$/.test(value);
    }
    return false;
  },
});

VeeValidate.Validator.extend("mobileNo", {
  getMessage: (field: string, params: any[], data: any) =>
    "The " + field + " value is not a valid mobile number. e.g. 9860447969",
  validate: (value: any) => {
    if (value) {
      return /^(9[0-9]{9})$/.test(value);
    }
    return false;
  },
});

VeeValidate.Validator.extend("phoneNo", {
  getMessage: (field: string, params: any[], data: any) =>
    "The " +
    field +
    " value is not a valid phone number. e.g. 4479690, 9860447969",
  validate: (value: any) => {
    if (value) {
      return /^([0-9]{7})$|^(9[0-9]{9})$/.test(value);
    }
    return false;
  },
});

VeeValidate.Validator.extend("bsDateOlderThanToday", {
  getMessage: (field: string, params: any[], data: any) => {
    if (field.toLowerCase().endsWith("date")) {
      return "The " + field + " can't be older than today";
    }
    return "The " + field + " date can't be older than today";
  },
  validate: (value: any) => {
    if (value) {
      const engDate = new Date(BSToAD(value));
      const today = new Date();
      engDate.setHours(0, 0, 0, 0);
      today.setHours(0, 0, 0, 0);
      return engDate >= today;
    }
    return false;
  },
});
