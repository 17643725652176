import {
  postApi,
  postApiWithoutToken,
  getApi,
  getApiWithoutLoader,
  postApiWithoutLoader,
  putApi,
  patchApi,
} from "@/store/api/api";
import { Action } from "@/store/actionType";
import { Ownership } from "../models/registration/registration";

export default new (class OneTimeVehicleEntryAPI {
  public async getVehicleEntry(query: string = ""): Promise<any> {
    const action = Action.getVehicleEntry + query;
    const response = await getApi(action);

    return response as any;
  }
  public async createVehicleEntry(entry: any): Promise<any> {
    const action = Action.createVehicleEntry;
    const response = await postApi(action, entry);
    return response as any;
  }

  public async updateVehicleEntry(entry: any, id: any): Promise<any> {
    const action = Action.updateVehicleEntry + id + "/";
    const response = await patchApi(action, entry);
    return response as any;
  }

  // public async deleteDriver(owner: Ownership): Promise<any> {
  //     if (owner.id !== undefined) {
  //         const action = Action.deleteNews + "?id=" + owner.id;
  //         const response = await postApi(action, owner);
  //         return response as any;
  //     }

  //     else {
  //         const action = Action.deleteNews + "?id=" + owner.id;
  //         const response = await postApi(action, owner);
  //         return response as any;
  //     }
  // }

  public async getVehicleEntryById(id: number): Promise<any> {
    const action = `${Action.getVehicleEntry}${id}/`;
    const response = await getApi(action);
    return response as any;
  }
})();
