import store from "@/store";
import {
  Module,
  VuexModule,
  Mutation,
  MutationAction,
  Action,
  getModule,
} from "vuex-module-decorators";
import api from "@/store/api/vehicle";
import oapi from "@/store/api/ownership";
import {
  Vehicle,
  VehiclePaginatedResult,
  Documents,
  routePermits,
  VehicleSearch,
  VehicleSearchResult,
  VehicleListSearchPaginatedResult,
  OwnershipSearchResult,
  VehicleListSearchResult,
  ListofVehicles,
  VehicleHistory,
  VehicleCount,
  VehicleListFilterModel,
  VehicleCSV,
} from "../models/registration/registration";
import { RuleViolationTrendSearch } from "@/store/models/adminGeneral";
import { VehicleListForRuleViolationCsv } from "@/store/models/meta";
import helpers from "@/utils/helpers";

@Module({ name: "vehicle", dynamic: true, store })
class VehicleModule extends VuexModule {
  public vehicle: Vehicle = {} as Vehicle;
  public documents: Documents = {} as Documents;
  public routePermits: routePermits[] = [];
  public routePermit: routePermits = {} as routePermits;
  public vehicleList: Vehicle[] = [];
  public vehicleOnlyList: Vehicle[] = [];
  // public vehiclePaginatedList: VehiclePaginatedResult = {} as VehiclePaginatedResult;
  public vehicleCount: VehicleCount = {} as VehicleCount;
  public ownerName: string = "";
  public approvalStatus: string = "";
  public vehicleSearch: VehicleSearch = {
    shift: "Both",
    page: 1,
    ordering: undefined,
    page_size: undefined,
  } as VehicleSearch;
  public paginatedVehicleList: VehiclePaginatedResult = {} as VehiclePaginatedResult;

  public vehicleSearchList: VehicleListSearchResult[] = [] as VehicleListSearchResult[];
  public vehicleSearchResult: VehicleListSearchPaginatedResult = {} as VehicleListSearchPaginatedResult;
  public vehicles: ListofVehicles = {} as ListofVehicles;
  public vehicleHistory: VehicleHistory[] = [] as VehicleHistory[];
  public vehicleCSV: VehicleCSV[] = [];
  public vehicleForRuleViolationCsv: VehicleListForRuleViolationCsv[] = [];
  @MutationAction
  async setVehicleSearch(vehicleSearch: VehicleSearch) {
    return { vehicleSearch: vehicleSearch };
  }
  @MutationAction
  async searchPlanYourTravel(vehicleSearch: VehicleSearch) {
    let query = "?";
    if (vehicleSearch.source) {
      query += `&source=${vehicleSearch.source}`;
    }
    if (vehicleSearch.destination) {
      query += `&destination=${vehicleSearch.destination}`;
    }
    if (vehicleSearch.shift) {
      query += `&shift=${vehicleSearch.shift}`;
    }
    if (vehicleSearch.page) {
      query += `&page=${vehicleSearch.page}`;
    }
    if (vehicleSearch.page_size) {
      query += `&page_size=${vehicleSearch.page_size}`;
    }
    if (vehicleSearch.ordering) {
      query += `&ordering=${vehicleSearch.ordering}`;
    }
    if (vehicleSearch.vehicle_owners) {
      query += `&vehicle_owners=${vehicleSearch.vehicle_owners}`;
    }
    let result = await api.searchPlanYourTravel(query);
    return { vehicleSearchResult: result, vehicleSearchList: result };
    // return { vehicleSearchList: result };
  }

  @Mutation
  async resetField() {
    this.documents = {} as any;
    this.routePermits = [] as any;
    this.vehicle = {
      documents: {} as Documents,
      routePermits: [{} as routePermits] as routePermits[],
    } as Vehicle;
    return {
      vehicle: this.vehicle as Vehicle,
      routePermit: this.routePermit as routePermits,
    };
  }

  @MutationAction
  async loadAllVehicle(params: VehicleListFilterModel) {
    let query = "?";

    if (params.page) {
      query += `&page=${params.page}`;
    }
    if (params.page_size) {
      query += `&page_size=${params.page_size}`;
    }
    if (params.number) {
      query += `&vehicle_number=${params.number}`;
    }
    if (params.ownername) {
      query += `&vehicle_owner=${parseInt(params.ownername)}`;
    }
    if (params.gps_service_provider) {
      query += `&gps_service_provider=${params.gps_service_provider}`;
    }
    if (params.approvalStatus) {
      query += `&approval_status=${params.approvalStatus}`;
    }
    const data = await api.getVehicle(query);

    return {
      paginatedVehicleList: data as VehiclePaginatedResult,
      vehicleOnlyList: data as Vehicle[],
    };
  }

  @MutationAction
  async getVehicleCSV() {
    const data = await api.getVehicleCSV();

    return { vehicleCSV: data as VehicleCSV[] };
  }

  @MutationAction
  async getVehicleForRuleViolation(params?: RuleViolationTrendSearch) {
    let query = "";
    if(params) {
      query = "?";
      if(params.year) {
        query += `&year=${params.year}`;
      }
      if(params.transportCompany) {
        query += `&transport_company=${params.transportCompany}`;
      }
    }

    if(query) {
      const data = await api.getVehicleCSV(query);
      return { vehicleForRuleViolationCsv: data as VehicleListForRuleViolationCsv[] };
    }
  }

  @MutationAction
  async getVehicleCount() {
    const data = await api.getVehicleCount();
    return { vehicleCount: data as VehicleCount };
  }

  @MutationAction
  async loadAllByStatus(status: VehicleListSearchResult) {
    let query = "?";

    if (status.page) {
      query += `&page=${status.page}`;
    }
    if (status.page_size) {
      query += `&page_size=${status.page_size}`;
    }
    if (status.profile__approval_status) {
      query += `&approval_status=${status.profile__approval_status}`;
    }

    //query += `&submitted=${status.submitted}`;

    const data = await api.getVehicleByStatus(query);
    return { vehicleList: data as Vehicle[] };
  }

  @MutationAction
  async loadAllVehicleByOwner(id: any) {
    const data = await api.getVehicleByOwnerId(id);
    return { vehicleList: data as Vehicle[] };
  }

  @MutationAction
  async getOwnerName(id: any) {
    const data = await oapi.getOwnershipById(id);
    let name = data.profile.companyName;
    return { ownerName: name };
  }

  @MutationAction
  async getApprovalStatus(id: any) {
    const data = await oapi.getOwnershipById(id);
    let name = data.approvalStatus;
    return { approvalStatus: name };
  }

  @MutationAction
  async approveVehicle(id: any) {
    const data = await api.approveVehicle(id);
    return { vehicle: data as Vehicle };
  }

  @MutationAction
  async reapplyVehicle(id: any) {
    const data = await api.reapplyVehicle(id);
    return { vehicle: data as Vehicle };
  }

  @MutationAction
  async createVehicle() {
    const o = this.state as VehicleModule;
    var formData = new FormData();
    o.vehicle.transportAssociation = 5;
    if (!o.vehicle.id) {
      o.vehicle.routePermits.push({
        expiryDate: o.vehicle.routePermits[0].expiryDate,
        expiryDateBs: o.vehicle.routePermits[0].expiryDateBs,
        id: 0,
        isActive: false,
        issuedDate: o.vehicle.routePermits[0].issuedDate,
        issuedDateBs: o.vehicle.routePermits[0].issuedDateBs,
        route: o.vehicle.routePermits[0].route,
        routePermitCertificate:
          o.vehicle.routePermits[0].routePermitCertificate,
      });
    }

    let data = helpers.getNestedFormData(formData, o.vehicle);

    if (o.vehicle.id) {
      formData.append("id", o.vehicle.id as any);
      var result = await api.updateVehicle(data, o.vehicle.id);
    } else {
      var result = await api.createVehicle(data);
    }

    return {};
  }

  @Action
  async deleteVehicle(vehicleId: any) {
    await api.deleteVehicle(vehicleId);
  }

  @MutationAction
  async changeVehicleRoutePermit(vehicleId: any) {
    const o = this.state as VehicleModule;
    var formData = new FormData();
    o.routePermit.vehicle = vehicleId;
    let data = helpers.getNestedFormData(formData, o.routePermit);
    var result = await api.changeVehicleRoute(data);

    return {};
  }

  @MutationAction
  async getVehicleById(id: any) {
    const data = await api.getVehicleById(id);
    return { vehicle: data as Vehicle };
  }

  @MutationAction
  async approveVehicles(params: { id: any; remarks: string }) {
    const data = await api.approveVehicles(params);
    return { vehicle: data as Vehicle };
  }

  @MutationAction
  async physicalCheckVehicles(params: {
    id: any;
    remarks: string;
    location: string;
    date: any;
    nepaliDate: any,
    time: any;
    reshedule: boolean;
  }) {
    const data = await api.physicalCheckVehicles(params);
    return { vehicle: data as Vehicle };
  }

  @MutationAction
  async rejectVehicles(params: { id: any; remarks: string }) {
    const data = await api.rejectVehicles(params);
    return { vehicle: data as Vehicle };
  }

  @MutationAction
  async submitVehicles(li: any) {
    const data = await api.submitForApproval(li);
    return { vehicle: data as Vehicle };
  }

  @MutationAction
  async getTraccarHistory(params: {
    route: number;
    vehicleId: number;
    date: string;
  }) {
    let query = "";
    if (params) {
      query += "?";
      if (params.vehicleId) {
        query += `&deviceid__gps_device__vehicle=${params.vehicleId}`;
      }
      if (params.date) {
        query += `&date=${params.date}`;
      }
    }
    const data = await api.getTraccarHistory(query);
    return { vehicleHistory: data };
  }
}

export default getModule(VehicleModule);
