import store from "@/store";
import {
  Module,
  VuexModule,
  getModule,
  MutationAction,
  Mutation,
  Action,
} from "vuex-module-decorators";
import jwtDecode from "jwt-decode";

import {
  UserLoginInfo,
  JWT,
  DecodedJWTPayload,
  Activation,
  EmailReset,
  ConfirmResetPassword,
  DetailProfile,
} from "@/store/models/jwt";
import API from "@/store/api/jwt";
import router from "@/router";
import { UserDetail } from "@/store/models/auth";
import helpers from "@/utils/helpers";
import { AdminRouter } from "@/utils/routePathContsant";

@Module({ name: "JWTModule", dynamic: true, store })
class JWTModule extends VuexModule {
  AccessToken = "";
  DecodedPayload: DecodedJWTPayload = {} as DecodedJWTPayload;
  isLoggedIn = false;
  RefreshingToken = true;
  UserDetail: UserDetail = {} as UserDetail;
  DetailProfile: DetailProfile = {} as DetailProfile;

  @Mutation
  refreshingToken() {
    this.RefreshingToken = true;
  }

  get loggedIn() {
    return this.isLoggedIn;
  }

  @MutationAction
  async getDetailProfile() {
    let detail = (this.state as JWTModule).DetailProfile;
    if (detail && detail != ({} as DetailProfile)) {
      detail = await API.getDetailProfile();
    }
    return {
      DetailProfile: detail,
      UserDetail: detail,
      isLoggedIn: true,
    };
  }

  @MutationAction
  async updateUserProfile(profile: DetailProfile) {
    var formData = new FormData();
    let data = helpers.getNestedFormData(formData, profile);
    const detail = await API.updateUserProfile(data);
    return {
      DetailProfile: detail,
      UserDetail: detail,
      isLoggedIn: true,
    };
  }

  @MutationAction
  async reapplyVehicleOwner(id: any) {
    const detail = await API.reapplyOwnership(id);
    return {
      DetailProfile: detail,
      UserDetail: detail,
      isLoggedIn: true,
    };
  }

  @MutationAction
  async reapplyGps(id: any) {
    const detail = await API.reapplyGps(id);
    return {
      DetailProfile: detail,
      UserDetail: detail,
      isLoggedIn: true,
    };
  }

  @MutationAction
  async reapplyGpsDevice(id: any) {
    const detail = await API.reapplyGpsDevice(id);
    return {
      DetailProfile: detail,
      UserDetail: detail,
      isLoggedIn: true,
    };
  }

  @MutationAction
  async getJWT(user: UserLoginInfo) {
    const detail = (await API.getJWT(user)) as JWT;
    const decodedPayload = jwtDecode(detail.access);
    return {
      AccessToken: detail.access,
      DecodedPayload: decodedPayload,
      UserDetail: detail.userDetail,
      isLoggedIn: true,
    };
  }

  @MutationAction
  async setJWT(jwt: JWT) {
    const decodedPayload = jwtDecode(jwt.access);
    return {
      AccessToken: jwt.access,
      DecodedPayload: decodedPayload,
      UserDetail: jwt.userDetail,
      isLoggedIn: true,
    };
  }

  @MutationAction
  async setUserProfile(detail: JWT) {
    const decodedPayload = jwtDecode(detail.access);
    return {
      AccessToken: detail.access,
      DecodedPayload: decodedPayload,
      UserDetail: detail.userDetail,
      isLoggedIn: true,
    };
  }

  @Mutation
  setAcessToken(accessToken: string) {
    const decodedPayload = jwtDecode<DecodedJWTPayload>(accessToken);
    this.AccessToken = accessToken;
    this.DecodedPayload = decodedPayload;
  }

  @Mutation
  setUserDetail(userDetail: UserDetail) {
    this.UserDetail = userDetail;
    this.isLoggedIn = true;
  }

  @Action
  async setUserByAccessToken(accessToken: string) {
    await this.setAcessToken(accessToken);
    // const userDetail = await API.getUserDetail();
    // if (userDetail) {
    //   this.setUserDetail(userDetail);
    // }
  }

  @MutationAction
  async refreshAccessToken(params?: { refreshToken?: string }) {
    let detail = (await API.refreshAccessToken(params)) as JWT;

    // eslint-disable-next-line
    let decodedPayload = {} as any;
    let isLoggedIn = false;
    if (!detail || !detail.access) {
      const loggedIn = (this.state as JWTModule).isLoggedIn;
      detail = {} as JWT;
      detail.access = "";
      decodedPayload = {};
      if (loggedIn) {
        router.push(AdminRouter.Root);
      }
    } else {
      decodedPayload = jwtDecode(detail.access);
      isLoggedIn = true;
    }
    return {
      AccessToken: detail.access,
      DecodedPayload: decodedPayload,
      isLoggedIn: isLoggedIn,
      UserDetail: detail.userDetail,
      RefreshingToken: false,
    };
  }

  @MutationAction
  async clearJWT() {
    await API.clearJWT();
    helpers.removeUserProfile();
    return {
      AccessToken: "",
      UserDetail: {} as UserDetail,
      DecodedPayload: {},
      isLoggedIn: false,
    };
  }

  @Action
  async userActivation(data: Activation) {
    const response = await API.userActivation(data);
    return response;
  }

  @Action
  async resendActivation(data: EmailReset) {
    const response = await API.resendActivation(data);
    return response;
  }

  @Action
  async resetPassword(data: EmailReset) {
    const response = await API.resetPassword(data);
    return response;
  }

  @Action
  async confirmResetPassword(data: ConfirmResetPassword) {
    const response = await API.confirmResetPassword(data);
    return response;
  }
}

export default getModule(JWTModule);
