import {
  postApi,
  postApiWithoutToken,
  getApi,
  getApiWithoutLoader,
  postApiWithoutLoader,
  putApi,
} from "@/store/api/api";
import { Action } from "@/store/actionType";
import { PublicUsers } from "../models/registration/registration";

export default new (class PublicAPI {
  public async createPublicUsers(publicuser: any): Promise<any> {
    const action = Action.createPublicUser;
    const response = await postApi(action, publicuser);

    return response as any;
  }

  public async updatePublicUsers(owner: any): Promise<any> {
    const action = Action.updatePublicUser;
    const response = await putApi(action, owner);
    return response as any;
  }

  public async getAllPublicUsers(): Promise<any> {
    const action = Action.getPublicUser;
    const response = await getApi(action);
    return response as any;
  }

  public async getPublicUserById(id: number): Promise<any> {
    const action = `${Action.getPublicUser}${id}/`;
    const response = await getApi(action);
    return response as PublicUsers;
  }
})();
