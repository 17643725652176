import Vue from "vue";
import Vuex from "vuex";
import {
  Module,
  VuexModule,
  Mutation,
  Action,
  getModule,
  MutationAction,
} from "vuex-module-decorators";
import { LiveData, Traccar } from "@/store/models/meta";
import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import moment from "moment";
import store from "@/store";
import { Ownership } from "../models/registration/registration";
import helpers from "@/utils/helpers";

@Module({ name: "commonstore", dynamic: true, store })
class CommonStore extends VuexModule {
  public isLoading: boolean = false;
  public stopId: number = 0;
  public gpsServiceId: number = 0;
  public vehicleOwnerId: number = 0;
  public routeId: number = 0;
  public sectionId: number = 0;
  public value: any;
  public vehicleId: number = 0;
  public traccarUpdates: Traccar[] = [];
  public liveMonitoringData: LiveData[] = [];
  public lmdata: [] = [];
  public loginDialog: boolean = false;
  public isNewMsg: boolean = false;
  public isChatHeadActive: boolean = false;
  public owner: Ownership = {} as Ownership;
  public headerTitle: string = "";
  public featureVehicleId: any;
  @MutationAction
  async setLoginDialog(visible: boolean) {
    return { loginDialog: visible };
  }

  @Mutation
  setLiveMonitoringData(data: any) {
    let routeId = this.routeId;
    let dt = moment(data.datetime).fromNow();
    data["dateString"] = dt;
    this.liveMonitoringData.unshift(data);
    //let filteredobj = this.liveMonitoringData.filter(x => x.route == routeId);
    return this.liveMonitoringData;
  }

  @Action
  getLiveData() {
    let a = this.liveMonitoringData;
    console.log(a);
    return this.liveMonitoringData;
  }

  @Mutation
  setTraccar(Traccar: any) {
    console.log("store traccar data");

    //console.log('Lat' + Traccar[0].Latitude , 'Long' + Traccar[0].Longitude , 'Speed' + Traccar[0].Speed);
    this.traccarUpdates = Traccar;
  }

  @Mutation
  setTraccarSingle(Traccar: Traccar) {
    // if (Traccar.RouteId == this.routeId) {
    let traccarUpdates = this.traccarUpdates;
    let userType = helpers.getUserType();
    let id = helpers.getUserId();
    if (traccarUpdates) {
      let index = traccarUpdates.findIndex(
        (x) => x.deviceId == Traccar.deviceId
      );

      if (index >= 0) {
        traccarUpdates.splice(index, 1, Traccar);
      } else {
        traccarUpdates.push(Traccar);
      }
    } else {
      traccarUpdates = [Traccar];
    }
    if (userType === "vehicle_owner") {
      let b = traccarUpdates.filter(
        (x) => x.vehicleOwnerId !== null && x.vehicleOwnerId === id
      );
      this.traccarUpdates = b;
    } else {
      this.traccarUpdates = traccarUpdates;
    }
  }
  @Mutation
  setGpsServiceId(val: any) {
    this.gpsServiceId = val;
  }
  @Action
  getGpsService() {
    let b = this.gpsServiceId;
    return b;
  }

  @Mutation
  setVehicleOwnerId(val: any) {
    this.vehicleOwnerId = val;
  }
  @Action
  getVehicleOwnerId() {
    let b = this.vehicleOwnerId;
    return b;
  }

  @Mutation
  setRouteId(val: number) {
    this.routeId = val;
  }
  @Action
  getRouted() {
    let b = this.routeId;

    return b;
  }

  @Mutation
  setNewMsg(val: boolean) {
    this.isNewMsg = val;
  }
  @Action
  getNewMsg() {
    let b = this.isNewMsg;

    return b;
  }

  @Mutation
  setChatHead(val: boolean) {
    this.isChatHeadActive = val;
  }
  @Action
  getChatHead() {
    let b = this.isChatHeadActive;

    return b;
  }

  @Mutation
  setHeader(val: string) {
    this.headerTitle = val;
  }
  @Action
  getHeader() {
    let b = this.headerTitle;

    return b;
  }

  @Action
  getTraccar() {
    let b = this.traccarUpdates;

    return b;
  }

  @Mutation
  clearTraccar() {
    this.traccarUpdates = [];
  }

  get traccarFeatures() {
    //    let b= this.traccarUpdates
    let traccar = this.traccarUpdates
      .filter((x) => x.routeId == this.routeId)
      .map((data) => {
        return new Feature({
          geometry: new Point([data.longitude, data.latitude]),
          ...data,
        });
      });

    return traccar;
  }

  get vehicleTrackFeatures() {
    let traccar = this.traccarUpdates.map((data) => {
      return new Feature({
        geometry: new Point([data.longitude, data.latitude]),
        ...data,
      });
    });
    return traccar;
  }

  @Mutation
  setStopId(val: number) {
    this.stopId = val;
  }
  @Action
  getStopId() {
    let b = this.stopId;

    return b;
  }

  @Mutation
  setSectionId(val: number) {
    this.sectionId = val;
  }
  @Action
  getSectionId() {
    let b = this.sectionId;

    return b;
  }
  @Mutation
  setLiveVehicleId(val: number) {
    this.vehicleId = val;
  }
  @Action
  getLiveVehicleId() {
    let b = this.vehicleId;

    return b;
  }
  @Mutation
  setFeatureVehicleId(val: any) {
    this.featureVehicleId = val;
  }
  @Action
  getFeatureVehicleId() {
    let b = this.featureVehicleId;

    return b;
  }
  @Mutation
  setLoading(val: boolean) {
    this.isLoading = val;
  }

  @Action({ commit: "setLoading", rawError: true })
  showLoading() {
    return true;
  }

  @Action({ commit: "setLoading" })
  hideLoading() {
    return false;
  }
}

export default getModule(CommonStore);
