import {
  postApi,
  postApiWithoutToken,
  getApi,
  getApiWithoutLoader,
  postApiWithoutLoader,
  putApi,
} from "@/store/api/api";
import { Action } from "@/store/actionType";
import { Ownership } from "../models/registration/registration";

export default new (class GpsServiceProviderAPI {
  public async getGpsDevice(): Promise<any> {
    const action = Action.getGpsDevice;
    const response = await getApi(action);

    return response as any;
  }
  public async getGpsServiceProvider(query: string = ""): Promise<any> {
    const action = Action.getGpsServiceProvider + query;
    const response = await getApi(action);

    return response as any;
  }
  public async getMainGpsServiceProvider(query: string = ""): Promise<any> {
    const action = Action.getGpsServiceProviderList + query;
    const response = await getApi(action);

    return response as any;
  }
  public async createGpsServiceProvider(driver: any): Promise<any> {
    const action = Action.createGpsServiceProvider;
    const response = await postApi(action, driver);
    return response as any;
  }

  public async createGpsDevice(device: any): Promise<any> {
    const action = Action.createGpsDevice;
    const response = await postApi(action, device);
    return response as any;
  }

  public async updateGpsServiceProvider(
    gpsService: any,
    id: any
  ): Promise<any> {
    const action = Action.updateGpsServiceProvider + id + "/";
    const response = await putApi(action, gpsService);
    return response as any;
  }

  public async updateGpsDevice(device: any, id: any): Promise<any> {
    const action = Action.updateGpsDevice + id + "/";
    const response = await putApi(action, device);
    return response as any;
  }

  // public async deleteDriver(owner: Ownership): Promise<any> {
  //     if (owner.id !== undefined) {
  //         const action = Action.deleteNews + "?id=" + owner.id;
  //         const response = await postApi(action, owner);
  //         return response as any;
  //     }

  //     else {
  //         const action = Action.deleteNews + "?id=" + owner.id;
  //         const response = await postApi(action, owner);
  //         return response as any;
  //     }
  // }

  public async getGpsServiceProviderById(id: number): Promise<any> {
    const action = Action.getGpsServiceProvider + id + "/";
    const response = await getApi(action);
    return response as any;
  }

  public async getGpsDeviceById(id: number): Promise<any> {
    const action = Action.getGpsDevice + id + "/";
    const response = await getApi(action);
    return response as any;
  }

  public async getGpsDeviceByServiceProviderId(id: number): Promise<any> {
    const action = Action.getGpsDevice + "?gps_service_provider=" + id;
    const response = await getApi(action);
    return response as any;
  }

  public async getActiveGpsServiceProvider(): Promise<any> {
    const action = Action.getActiveGpsServiceProvider;
    const response = await getApi(action);
    return response as any;
  }

  public async getActiveGpsDevice(id: number): Promise<any> {
    const action =
      Action.getActiveGpsDevice +
      "?gps_service_provider=" +
      id +
      "&is_active=" +
      true;
    const response = await getApi(action);
    return response as any;
  }

  public async approve(id: any): Promise<any> {
    const action =
      Action.getGpsServiceProvider + id + "/approve_service_provider/";
    const response = await postApi(action, id);
    return response as any;
  }

  public async reject(params: { id: any; remarks: string }): Promise<any> {
    const action =
      Action.getGpsServiceProvider + params.id + "/hold_service_provider/";
    const response = await postApi(action, {
      profile: { remarks: params.remarks },
    });

    return response as any;
  }

  public async approveDevice(id: any): Promise<any> {
    const action = Action.getGpsDevice + id + "/approve_gps_model/";
    const response = await postApi(action, id);
    return response as any;
  }

  public async rejectDevice(params: {
    id: any;
    remarks: string;
  }): Promise<any> {
    const action = Action.getGpsDevice + params.id + "/hold_gps_model/";
    const response = await postApi(action, {
      remarks: params.remarks,
    });

    return response as any;
  }

  public async loadAllOwnerAssociatedWithGpsProvider(){
    const action = Action.getAllOwnerAssociatedToCompany;
    const response = await getApi(action)
    return response as any
  }

  // public async getGPSCSV(): Promise<any> {
  //   const action = Action.gpscsv;
  //   const response = await getApi(action);

  //   return response as any;
  // }
})();
