import Vue from "vue";
import Vuex from "vuex";
import {
  Module,
  VuexModule,
  Mutation,
  Action,
  getModule,
  MutationAction,
} from "vuex-module-decorators";
import store from "@/store";
import {
  District,
  Palika,
  PoliceUser,
  Role,
  Province,
  Traccar,
  Section,
  Stop,
  StopData,
  RouteStop,
  RouteStopSearch,
  RoadLink,
  RouteRoad,
  RouteRoadSearch,
  SpeedSection,
  SpeedSectionSearch,
  VehicleTypeCode,
  VehicleType,
  VehicleList,
  UserType,
  Protocol,
  VehicleOwnerList,
} from "@/store/models/meta";

import { IncidentCategories } from "@/store/models/incident";
import helper from "@/utils/helpers";
import api from "@/store/api/meta";
import { RouteStatus } from "@/utils/constants";
import { Route } from "../models/route";
import { SourceDestination } from "../models/vechileSchedule";

@Module({ name: "metaStore", dynamic: true, store })
class MetaModule extends VuexModule {
  public district: District = {} as District;
  public districtList: District[] = [];
  public districtL: District[] = [];
  public provinceList: Province[] = [];
  public routesList: Route[] = [];
  public route: Route = {} as Route;
  public routesListOfUser: Route[] = [];
  public vehicleTypeCodeList: VehicleTypeCode[] = [];
  public vehicleList: VehicleList[] = [];
  public vehicleOwnerList: VehicleOwnerList[] = [];
  public protocolList: Protocol[] = [];
  public userTypeList: UserType[] = [];
  public sourceDestinationList: SourceDestination[] = [];
  public destinationOnBasisOfSourceList: SourceDestination[] = [];
  public vehicleTypeList: VehicleType[] = [];
  public palika: Palika = {} as Palika;
  public palikaList: Palika[] = [];
  public policeUser: PoliceUser = {} as PoliceUser;
  public policeUserList: PoliceUser[] = [];
  public incidentCategories: IncidentCategories[] = [];
  public complainedCategories: IncidentCategories[] = [];
  public policeStationGeoObj: string = "";
  // public routeGeoObj: string = ""
  // public stopGeoObj: string =""
  public routeGeoObj: any = {};
  public stopGeoObj: any = {};
  public roles: Role[] = [];
  public traccar: Traccar = {} as Traccar;
  public sections: Section[] = [];
  public sectionresult: Section[] = [];
  public stopData: StopData[] = [];
  public stopList: Stop[] = [];
  public stop: Stop = {} as Stop;
  public routeStopList: RouteStop[] = [];
  public routeStop: RouteStop = {} as RouteStop;
  public roadLinkList: RoadLink[] = [];
  public roadLink: RoadLink = {} as RoadLink;
  public routeRoadList: RouteRoad[] = [];
  public routeRoad: RouteRoad = {} as RouteRoad;
  public speedSectionList: SpeedSection[] = [];
  public speedSection: SpeedSection = {} as SpeedSection;
  public selectedIncidentTab: RouteStatus = RouteStatus.Section;
  public bloodGroupList: [] = [];
  public genderList: [] = [];
  public engineModelList: [] = [];
  public shiftList: [] = [];
  public vehicleNumber: string = "";

  @Mutation
  async clearPoliceUserList() {
    this.policeUserList = [] as PoliceUser[];
  }
  @Mutation
  setPalika(palikas: Palika[]) {
    this.palikaList = palikas;
  }

  @Mutation
  setDistrict(districts: District[]) {
    this.districtList = districts;
  }

  @Mutation
  setVehicleType(vehicleType: VehicleType[]) {
    this.vehicleTypeList = vehicleType;
  }

  @Mutation setIncidentCategories(list: IncidentCategories[]) {
    this.incidentCategories = list;
  }

  @Mutation setComplainedCategories(list: IncidentCategories[]) {
    this.complainedCategories = list;
  }

  @MutationAction
  async loadProvice() {
    const provinces = [
      { id: 1, name: "Province 1" },
      { id: 2, name: "Province 2" },
      { id: 3, name: "Province 3" },
      { id: 4, name: "Province 4" },
      { id: 5, name: "Province 5" },
      { id: 6, name: "Province 6" },
      { id: 7, name: "Province 7" },
    ];
    return { provinceList: provinces };
  }

  @MutationAction
  async loadShift() {
    const shifts = [{ id: "Day", name: "Day" }, { id: "Night", name: "Night" }];
    return { shiftList: shifts };
  }

  @MutationAction
  async loadBloodGroup() {
    const bloodGroups = [
      { id: "O-", name: "O-" },
      { id: "O+", name: "O+" },
      { id: "A-", name: "A-" },
      { id: "A+", name: "A+" },
      { id: "B-", name: "B-" },
      { id: "B+", name: "B+" },
      { id: "AB-", name: "AB-" },
      { id: "AB+", name: "AB+" },
    ];
    return { bloodGroupList: bloodGroups };
  }

  @MutationAction
  async loadGender() {
    const genders = [
      { id: "Male", name: "Male" },
      { id: "Female", name: "Female" },
      { id: "Others", name: "Others" },
    ];
    return { genderList: genders };
  }

  @MutationAction
  async loadEngineModel() {
    const engineModel = [
      { id: "diesel", name: "Diesel" },
      { id: "petrol", name: "Petrol" },
    ];
    return { engineModelList: engineModel };
  }
  @MutationAction
  async loadUserTypesDD() {
    var user_types = (this.state as MetaModule).userTypeList;
    if (user_types.length == 0) {
      let b = "?system_users=true";
      user_types = (await api.getUserTypeDD(b)) as UserType[];
    }
    return { userTypeList: user_types as UserType[] };
  }
  @MutationAction
  async loadPolicStationGeoObj() {
    var res = await api.getPoliceStationGeoObj();
    return { policeStationGeoObj: JSON.parse(res) };
  }

  @MutationAction
  async loadRouteGeoObj(e: any) {
    let query = "";
    if (e) {
      query += "?";
      if (e.routeId) {
        query += `&route_id=${e.routeId}`;
      }
      if (e.source) {
        query += `&source=${e.source}`;
      }
      if (e.destination) {
        query += `&destination=${e.destination}`;
      }
    }
    var res = await api.getRouteGeoObj(query);
    return { routeGeoObj: res };
  }

  @MutationAction
  async loadRouteGeoObjBySection(section: any) {
    console.log(section);
    var res = await api.getRouteBySection(section.SectionId, section.RoadId);
    return { routeGeoObj: JSON.parse(res) };
  }

  @MutationAction
  async loadStopGeoObj(e: any) {
    let query = "";
    if (e) {
      query += "?";
      if (e.routeId) {
        query += `&route_id=${e.routeId}`;
      }
      if (e.source) {
        query += `&source=${e.source}`;
      }
      if (e.destination) {
        query += `&destination=${e.destination}`;
      }
    }
    var res = await api.getStopGeoObj(query);
    return { stopGeoObj: res };
  }

  @MutationAction
  async loadTraccar() {
    let _traccar = (this.state as MetaModule).traccar;
    var res = (await api.getTraccar()) as Traccar[];
    var r = res.slice(-1)[0];
    return { traccar: r as Traccar };
  }

  @MutationAction
  async loadSection(routeId: any) {
    let _sections = (this.state as MetaModule).sections;
    if (_sections.length == 0) {
      _sections = (await api.getSections(routeId)) as Section[];
    }
    return { sections: _sections as Section[] };
  }

  @Action
  async createSection(section: Section) {
    (await api.postSections(section)) as Section[];
  }

  @MutationAction
  async loadStopdata() {
    let _stopdata = (this.state as MetaModule).stopData;
    if (_stopdata.length == 0) {
      _stopdata = (await api.getStopData()) as StopData[];
    }
    return { stopData: _stopdata as StopData[] };
  }

  @MutationAction
  async loadStop() {
    const response = (await api.getStop()) as Stop[];
    return { stopList: response as Stop[] };
  }

  @Action
  async createStop(stop: Stop) {
    await api.postStop(stop);
  }

  @MutationAction
  async loadStopById(id: any) {
    const response = await api.getStopById(id);
    return { stop: response as Stop };
  }

  @Action
  async patchStop(stop: Stop) {
    await api.patchStop(stop);
  }

  @MutationAction
  async getRouteStop(params?: RouteStopSearch) {
    let query = "";
    if (params) {
      query = "?";
      if (params.route) {
        query += `&route=${params.route}`;
      }
    }
    const response = await api.getRouteStop(query);
    return { routeStopList: response as RouteStop[] };
  }

  @MutationAction
  async getRouteStopById(id: any) {
    const response = await api.getRouteStopById(id);
    return { routeStop: response as RouteStop };
  }

  @Action
  async createRouteStop(data: any) {
    await api.createRouteStop(data);
  }

  @Action
  async patchRouteStop(data: any) {
    await api.patchRouteStop(data);
  }

  @MutationAction
  async getRoadLink() {
    const response = await api.getRoadLink();
    return { roadLinkList: response as RoadLink[] };
  }

  @MutationAction
  async getRoadLinkById(id: number) {
    const response = await api.getRoadLinkById(id);
    return { roadLink: response as RoadLink };
  }

  @Action
  async createRoadLink(data: RoadLink) {
    await api.createRoadLink(data);
  }

  @Action
  async patchRoadLink(data: RoadLink) {
    await api.patchRoadLink(data);
  }

  @MutationAction
  async getRouteRoad(params?: RouteRoadSearch) {
    let query = "";
    if (params) {
      query = "?";
      if (params.route) {
        query += `&route=${params.route}`;
      }
    }
    const response = await api.getRouteRoad(query);
    return { routeRoadList: response as RouteRoad[] };
  }

  @MutationAction
  async getRouteRoadById(id: any) {
    const response = await api.getRouteRoadById(id);
    return { routeRoad: response as RouteRoad };
  }

  @Action
  async createRouteRoad(obj: any) {
    const response = await api.createRouteRoad(obj);
    return response as any;
  }

  @Action
  async patchRouteRoad(obj: RouteRoad) {
    const response = await api.patchRouteRoad(obj);
    return response as any;
  }

  @MutationAction
  async getSpeedSection(params?: SpeedSectionSearch) {
    let query = "";
    if (params) {
      query = "?";
      if (params.road) {
        query += `&roadlink=${params.road}`;
      }
    }
    const response = await api.getSpeedSection(query);
    return { speedSectionList: response as SpeedSection[] };
  }

  @MutationAction
  async getSpeedSectionById(id: any) {
    const response = await api.getSpeedSectionById(id);
    return { speedSection: response as SpeedSection };
  }

  @Action
  async createSpeedSection(obj: any) {
    const response = await api.createSpeedSection(obj);
    return response as any;
  }

  @Action
  async patchSpeedSection(obj: any) {
    const response = await api.patchSpeedSection(obj);
    return response as any;
  }

  @MutationAction
  async searchSpeedSection(params: any) {
    let query = "";
    if (params) {
      query += "?";
      if (params.road) {
        query += `&id=${params.road}`;
      }
    }

    const _sections = (await api.searchSpeedSections(query)) as [];
    return { speedSectionList: _sections as SpeedSection[] };
  }

  @Mutation
  setSelectedIncidentTab(status: RouteStatus) {
    this.selectedIncidentTab = status;
  }

  @MutationAction
  async loadRoles() {
    let _roles = (this.state as MetaModule).roles;
    if (_roles.length == 0) {
      _roles = (await api.getRoles()) as Role[];
    }
    return { roles: _roles as Role[] };
  }

  @Action
  async loadIncidentCategory() {
    var categories = this.incidentCategories.filter(function(x) {
      if (x.IsComplained === false) {
        return true;
      }
    });
    if (categories.length == 0) {
      categories = (await api.getIncidentCategory()) as IncidentCategories[];
    }
    this.setIncidentCategories(categories);
  }

  @Action
  async loadComplain() {
    var categories = this.complainedCategories.filter(function(x) {
      if (x.IsComplained === true) {
        return true;
      }
    });
    if (categories.length == 0) {
      categories = (await api.getComplainSubCategory()) as IncidentCategories[];
    }
    this.setComplainedCategories(categories);
  }

  @MutationAction
  async loadSourceDestinationDD() {
    var source_destination = (this.state as MetaModule).sourceDestinationList;
    if (source_destination.length == 0) {
      source_destination = (await api.getSourceDestinationDD()) as SourceDestination[];
    }
    let a = source_destination;
    return { sourceDestinationList: source_destination as SourceDestination[] };
  }

  @MutationAction
  async loadDestinationOnBasisOfSource(source: string) {
    const response = await api.getDestinationOnBasisOfSource(source);
    return { destinationOnBasisOfSourceList: response as SourceDestination[] };
  }

  @MutationAction
  async loadRoutesDD(params?: { page?: number }) {
    let query = "";
    if (params) {
      query += "?";
      if (params.page) {
        query += `&page=${params.page}`;
      }
    }
    const routes = (await api.getRoutesDD()) as Route[];
    return { routesList: routes as Route[] };
  }

  @Action
  async createRouteDD(route: Route) {
    const response = await api.postRouteDD(route);
    return response as any;
  }

  @MutationAction
  async getRouteById(id: any) {
    const response = await api.getRoutesOfUser();
    let res = response.find((x: Route) => x.gid === parseInt(id));
    return { route: res as Route };
  }

  @Action
  async patchRouteDD(route: Route) {
    await api.patchRouteDD(route);
  }

  @MutationAction
  async loadRoutesOfUser() {
    var routes = (this.state as MetaModule).routesListOfUser;

    if (routes.length == 0) {
      routes = (await api.getRoutesOfUser()) as Route[];
    }
    return { routesListOfUser: routes as Route[] };
  }

  @MutationAction
  async loadVehicleListDD() {
    var token = helper.getAccessToken();
    var vehicle = (this.state as MetaModule).vehicleList;
    if (vehicle.length == 0 && token != null) {
      vehicle = (await api.getVehicleListDD()) as VehicleList[];
    }
    return { vehicleList: vehicle as VehicleList[] };
  }

  @MutationAction
  async loadProtocolDD() {
    var p = (this.state as MetaModule).protocolList;
    if (p.length == 0) {
      p = (await api.getProtocolListDD()) as Protocol[];
    }
    let a = p;

    return { protocolList: p as Protocol[] };
  }

  @MutationAction
  async getVehicleNumberById(id: any) {
    var vehicle = (this.state as MetaModule).vehicleList;
    if (vehicle.length == 0) {
      vehicle = (await api.getVehicleListDD()) as VehicleList[];
    }
    let res = vehicle
      .filter((x) => x.id === parseInt(id))
      .map((x) => x.vehicleNumber)
      .toString();
    return { vehicleNumber: res };
  }

  @MutationAction
  async loadVehicleByOwnerListDD() {
    var vehicle = (this.state as MetaModule).vehicleList;
    var profile = helper.getUserProfile() as any;
    if (vehicle.length == 0) {
      vehicle = (await api.getVehicleListByOwnerDD(
        profile.id
      )) as VehicleList[];
    }
    return { vehicleList: vehicle as VehicleList[] };
  }

  @MutationAction
  async loadVehicleTypeCodeDD() {
    var codeList = (this.state as MetaModule).vehicleTypeCodeList;
    if (codeList.length == 0) {
      codeList = (await api.getVehicleTypeCodeDD()) as VehicleTypeCode[];
    }
    return { vehicleTypeCodeList: codeList as VehicleTypeCode[] };
  }

  @Action
  async loadVehicleTypeDD(id: any) {
    var codeList = this.vehicleTypeList;
    if (codeList.length == 0) {
      codeList = await api.getVehicleTypeDD();
      this.setVehicleType(codeList);
    }
    var res = codeList.filter((x) => x.typeCode === id);

    return res;
  }

  @MutationAction
  async loadProvince() {
    var provinces = (this.state as MetaModule).provinceList;
    if (provinces.length == 0) {
      provinces = (await api.getProvince()) as Province[];
    }
    return { provinceList: provinces as Province[] };
  }

  @MutationAction
  async loadDis() {
    var district = (this.state as MetaModule).districtL;
    if (district.length == 0) {
      district = (await api.getDistrict()) as District[];
    }
    return { districtL: district as District[] };
  }

  @Action
  async loadDistrict(pid: number) {
    var districts = this.districtList;
    if (districts.length == 0) {
      districts = await api.getDistrict();
      this.setDistrict(districts);
    }
    var res = districts.filter((x) => x.province === pid);
    return res;
  }

  @Action
  async loadMuncipality(did: number) {
    var palikas = this.palikaList;

    if (palikas.length == 0) {
      palikas = await api.getMunicipality();
      this.setPalika(palikas);
    }

    var a = palikas.filter((x) => x.district == did);
    return a;
  }

  @MutationAction
  async loadPoliceUser() {
    var policeUser = (this.state as MetaModule).policeUserList;
    if (policeUser.length == 0) {
      policeUser = (await api.getPoliceUser()) as PoliceUser[];
    }
    return { policeUserList: policeUser as PoliceUser[] };
  }

  @MutationAction
  async getDutyUserByDutyOffice(id: string) {
    var policeUser = (this.state as MetaModule).policeUserList;

    policeUser = (await api.getDutyUserByDutyOffice(id)) as PoliceUser[];
    return { policeUserList: policeUser as PoliceUser[] };
  }

  @MutationAction
  async loadAllVehicleOwner() {
    var data = (this.state as MetaModule).vehicleOwnerList;
    if (data.length == 0) {
      data = (await api.getVehicleOwnerOnly()) as VehicleOwnerList[];
    }
    return { vehicleOwnerList: data as VehicleOwnerList[] };
  }
}

export default getModule(MetaModule);
