import { postApi, getApi, getApiWithoutLoader, postApiWithoutLoader } from "@/store/api/api";
import { Action } from "@/store/actionType";
import helpers from '@/utils/helpers';

export default new class IncidentAPI {


  public async getAllPanicList(): Promise<any> {
    const action = Action.getAllPanicList;
    const response = await getApi(action);
    return response as any;
  }

  public async stopPanicMode(id: any): Promise<any> {
    const action = Action.stopPanicMode + "?id=" + id;
    const response = await getApi(action);
    return response as any;
  }
  public async getIncidentGeoJson(param: any): Promise<any> {
    const action = Action.getIncidentGeoJson + "?status=" + param.status;
    const response = await getApi(action);

    return response as any;
  }
  public async getAll(): Promise<any> {
    const action = Action.getAll;
    const response = await postApiWithoutLoader(action, null);
    return response as any;
  }

  public async getIncidentsByPaging(param: any): Promise<any> {
    const action = Action.getIncidentByPaging;
    const response = await postApi(action, param);
    return response as any;
  }

  public async getLiveMonitoringByPaging(param: any): Promise<any> {
    const action = Action.getLiveMonitoringDataByPaging;
    const response = await postApi(action, param);
    return response as any;
  }

  public async getComplainByPaging(param: any): Promise<any> {
    const action = Action.getComplainByPaging;

    const response = await postApi(action, param);
    return response as any;
  }

  public async getIncident(date: string): Promise<any> {
    const action = Action.getIncident;
    const response = await postApiWithoutLoader(action, { incidenttime: date });
    return response as any;
  }

  public async getPatrolling(date: string): Promise<any> {
    const action = Action.getPatrolling;
    const response = await postApiWithoutLoader(action, { patrollingtime: date });

    return response as any;
  }

  public async startPatrolling(lat: string, lng: string, walkie: string): Promise<any> {
    const action = Action.startPatrolling;
    const response = await postApiWithoutLoader(action, { WalkieTalkieNumber: walkie, Latitude: lat, Longitude: lng });
    return response as any;
  }
  public async stopPatrolling(id: any): Promise<any> {
    const action = Action.stopPatrolling + "?id=" + id;
    const response = await getApi(action);
    return response as any;
  }

  public async updatePatrolling(lat: string, lng: string, patId: string): Promise<any> {
    const action = Action.updatePatrolling;
    const response = await postApiWithoutLoader(action, { PatrollingId: patId, Latitude: lat, Longitude: lng });
    return response as any;
  }

  public async nearestPatrolling(lat: string, lng: string): Promise<any> {
    const action = Action.nearestPatrolling;
    const response = await postApi(action, { Lat: lat, Lng: lng });
    return response as any;
  }

  public async getIncidentMaterials(incidentId: string): Promise<any> {
    const action = Action.getIncidentMaterials + "?id=" + incidentId;
    const response = await getApi(action);
    return response as any;
  }


  public async assignIncident(param: any): Promise<any> {

    const action = Action.assignIncident;
    const response = await postApi(action, param);

    return response as any;
  }

  public async acknowledgeIncident(incidentId: any): Promise<any> {

    const action = Action.acknowledgeIncident;
    var param = { IncidentId: incidentId }
    const response = await postApi(action, param);

    return response as any;
  }

  public async rejectIncident(incidentId: any): Promise<any> {

    const action = Action.rejectIncident;
    var param = { IncidentId: incidentId }
    const response = await postApi(action, param);

    return response as any;
  }

  public async getIncidentMessage(incidentId: any): Promise<any> {

    const action = Action.getIncidentMessage + "/" + incidentId;
    const response = await getApi(action);

    return response as any;
  }

  public async saveIncidentMessage(obj: any): Promise<any> {

    const action = Action.saveIncidentMessage;
    var user = helpers.getUserProfile()
    const response = await postApi(action, obj);

    return response as any;
  }
  public async getIncidentReport(obj: any): Promise<any> {
    const action = Action.getIncidentReport;
    const response = await postApi(action, obj);
    return response as any

  }

};
