import { postApi, postApiWithoutToken, getApi, getApiWithoutLoader, postApiWithoutLoader } from "@/store/api/api";
import { Action } from "@/store/actionType";
import { News } from '../models/news';

export default new class NewsAPI {

  public async createNews(news: any): Promise<any> {
    const action = Action.createNews;
    const response = await postApi(action, news);
    
    return response as any;
  }

  public async updateNews(news: any): Promise<any> {
    const action = Action.editNews;
    const response = await postApi(action, news);
    return response as any;
  }

  public async deleteNews(news:News): Promise<any> {
    if(news.NewsId !== undefined){
        const action = Action.deleteNews+ "?id=" + news.NewsId;
        const response = await postApi(action, news);
        return response as any;
    }
    
   else{
    const action = Action.deleteNews+ "?id=" + news.Id;
    const response = await postApi(action, news);
    return response as any;
   }
  }

  public async getAllNews(id:number): Promise<any> {
    const action = Action.getNews + "/"+ id;
    const response = await getApi(action);
    return response as any;
  }

  public async getNewsById(id:number): Promise<any> {
    const action = Action.getNewsById+"?id="+id;
    const response = await getApi(action);
    return response as News;
  }
  
  public async getAllNewsCategory(): Promise<any> {
    const action = Action.getAllNewsCategory;
    const response = await getApi(action);
    return response as any;
  }

  public async getAllNewsType(): Promise<any> {
    const action = Action.getNewsType;
    const response = await getApi(action);
    return response as any;
  }

}

