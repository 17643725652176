import store from "@/store";
import {
  Module,
  VuexModule,
  Mutation,
  MutationAction,
  Action,
  getModule,
} from "vuex-module-decorators";
import api from "@/store/api/incident";
import mapi from "@/store/api/meta";
import {
  Incident,
  Patrolling,
  NearestPatrolling,
  IncidentMaterial,
  IncidentMessage,
  PanicMode,
} from "@/store/models/incident";
import { IncidentStatus } from "@/utils/constants";
import incident from "@/store/api/incident";
import { eventHub } from "@/utils/eventHub";
import { LiveData, Section, Stop, StopData, Traccar } from "../models/meta";
import { CommonStore } from ".";

@Module({ name: "incidentStore", dynamic: true, store })
class IncidentModule extends VuexModule {
  public RightDashLoader = false;
  public patrollingId: string = "";
  public selectedIncidentId: string = "";
  public selectedStopId: string = "";
  public incidentGeoObj: string = "";
  public incidentPageSize = 30;

  public selectedTab: IncidentStatus = IncidentStatus.Route1;
  public selectedIncidentTab: IncidentStatus = IncidentStatus.Created;

  public lat: number = 27.667320820054307;
  public lng: number = 85.32310303037195;

  public lastSyncedDate: string = "";
  public lastSyncIsSuccess: boolean = true;

  public lastSyncedDateForPatrol: string = "";
  public lastSyncIsSuccessForPatrol: boolean = true;

  public incidents: Incident[] = [];
  public sections: Section[] = [];
  public liveDatas: LiveData[] = [];
  public stops: Stop[] = [];
  public stopData: StopData[] = [];
  public updatedIncidents: Incident[] = [];
  public updateSections: Section[] = [];
  public updateStops: Stop[] = [];
  public updatedLiveData: LiveData[] = [];
  public incidentOrComplain: string = "";

  public patrollings: Record<string, Patrolling> = {};
  public panicModes: Record<string, PanicMode> = {};
  public traccarModes: Record<string, Traccar> = {};
  public stopModes: Record<string, StopData> = {};
  public updatedPatrollings: Patrolling[] = [];
  public nearesetPatrollings: NearestPatrolling[] = [];
  public isPatrollingLoading: boolean = false;
  public isIncidentAssigning: boolean = false;
  public IncidentMessages: IncidentMessage[] = [];
  public IncidentSelectedMaterials: IncidentMaterial[] = [];

  public IncidentReports: Incident[] = [];

  @Mutation
  removePanicMode(id: string) {
    delete this.panicModes[id];
  }
  @Mutation
  setSelectedIncidenId(id: string) {
    this.selectedIncidentId = id;
  }

  @Mutation
  setSelectedStopId(id: string) {
    this.selectedStopId = id;
  }

  @Mutation
  setIsPatrollLoading(flag: boolean) {
    this.isPatrollingLoading = flag;
  }
  @Mutation
  setIsIncidentAssigning(flag: boolean) {
    this.isIncidentAssigning = flag;
  }

  @Mutation
  setSelectedIncidentTab(status: IncidentStatus) {
    this.selectedIncidentTab = status;
  }

  @Mutation
  setSelectedTab(status: IncidentStatus) {
    this.selectedTab = status;
  }

  @Mutation
  setRightDashLoader(flag: boolean) {
    this.RightDashLoader = flag;
  }

  @Mutation
  setLastCallIsSuccess(flag: boolean) {
    this.lastSyncIsSuccess = flag;
  }
  @Mutation
  setlastSyncedDate(date: string) {
    this.lastSyncedDate = date;
  }

  @Mutation
  setlastSyncedDateForPatroll(date: string) {
    this.lastSyncedDateForPatrol = date;
  }

  @Mutation
  setUpdatedIncidents(incidents: Incident[]) {
    this.updatedIncidents = incidents;
  }

  @Mutation
  setUpdatedSections(sections: Section[]) {
    this.updateSections = sections;
  }

  @Mutation
  setUpdatesLiveDta(liveDatas: any) {
    this.updatedLiveData = liveDatas;
  }
  @Mutation
  setUpdatedStops(stops: Stop[]) {
    this.updateStops = stops;
  }
  @Mutation
  setIncidents(incidents: Incident[]) {
    this.incidents = incidents;
  }

  @Mutation
  setSections(sections: Section[]) {
    this.sections = sections;
  }

  @Mutation
  setLiveDatas(datas: any) {
    this.liveDatas = datas;
  }

  @Mutation
  setStops(stops: Stop[]) {
    this.stops = stops;
  }

  @MutationAction
  async getIncidentGeoJson(param: any) {
    var res = await api.getIncidentGeoJson(param);
    return { incidentGeoObj: JSON.parse(res) };
  }

  @MutationAction
  async loadAllPanicList() {
    const res = await api.getAllPanicList();
    const obj = this.state as IncidentModule;
    var panicModes = Object.assign({}, obj.panicModes) as Record<
      string,
      PanicMode
    >;
    for (let i = 0; i < res.length; i++) {
      const key = res[i].PanicModeId;
      panicModes[key] = res[i];
    }
    return {
      panicModes: panicModes,
    };
  }

  @MutationAction
  async loadAllStopList() {
    const res = await mapi.getStopData();
    const obj = this.state as IncidentModule;
    var stopModes = Object.assign({}, obj.stopModes) as Record<
      string,
      StopData
    >;
    for (let i = 0; i < res.length; i++) {
      const key = res[i].Gid;
      stopModes[key] = res[i];
    }
    return {
      stopModes: stopModes,
    };
  }

  @Action
  stopPanicMode(id: any) {
    return api.stopPanicMode(id);
  }

  @Action
  stopPatrolling(id: any) {
    return api.stopPatrolling(id);
  }
  @MutationAction
  async updatePatrollingsFromSocket(param: Patrolling[]) {
    const obj = this.state as IncidentModule;
    var patrollings = Object.assign({}, obj.patrollings) as Record<
      string,
      Patrolling
    >;
    console.log(patrollings);
    let data: any = {};
    var lastSynced = obj.lastSyncedDateForPatrol;

    //data = await api.getPatrolling(lastSynced);

    for (let i = 0; i < param.length; i++) {
      const key = param[i].PatrollingId;
      patrollings[key] = param[i];
    }

    return {
      updatedPatrollings: param as Patrolling[],
      patrollings: patrollings,
      lastSyncedDateForPatrol: data.Synced,
    };
  }

  @MutationAction
  async updatePanicModeFromSocket(param: PanicMode[]) {
    const obj = this.state as IncidentModule;
    var panicModes = Object.assign({}, obj.panicModes) as Record<
      string,
      PanicMode
    >;
    for (let i = 0; i < param.length; i++) {
      const key = param[i].PanicModeId;
      panicModes[key] = param[i];
    }

    return {
      panicModes: panicModes,
    };
  }

  // @MutationAction
  // async updateTraccarFromSocket(param: Traccar[]) {
  // const obj = this.state as IncidentModule;
  //     var traccarModes = Object.assign({}, obj.traccarModes) as Record<
  //         string,
  //         Traccar
  //     >;
  //     for (let i = 0; i < param.length; i++) {

  //         const key = param[i].Id;
  //         traccarModes[key] = param[i];
  //     }

  //     return {
  //         traccarModes: traccarModes
  //     };
  // }

  @MutationAction
  async updateStopModeFromSocket(param: StopData[]) {
    const obj = this.state as IncidentModule;
    var stopModes = Object.assign({}, obj.stopModes) as Record<
      string,
      StopData
    >;
    for (let i = 0; i < param.length; i++) {
      const key = param[i].gid;
      stopModes[key] = param[i];
    }

    return {
      stopModes: stopModes,
    };
  }

  @MutationAction
  async loadIncidents() {
    const obj = this.state as IncidentModule;
    let data: any = {};
    var lastSynced = obj.lastSyncedDate;

    data = await api.getIncident(lastSynced);

    return {
      updatedIncidents: data.Incidents as Incident[],
      incidents: data.Incidents as Incident[],
    };
  }

  @MutationAction
  async loadStopData() {
    const obj = this.state as IncidentModule;
    let data: any = {};
    var lastSynced = obj.lastSyncedDate;

    data = await mapi.getStopData();

    return {
      stopData: data.StopData as StopData[],
    };
  }

  @MutationAction
  async updateIncidentSocket(param: Incident[]) {
    const obj = this.state as IncidentModule;

    var incidents = obj.incidents as Incident[];

    for (var i = 0; i < param.length; i++) {
      if (incidents.length == 0) {
        incidents.push(param[i]);
      } else {
        let index = incidents.findIndex(
          (x) => x.IncidentId == param[i].IncidentId
        );

        if (index > -1) {
          incidents.splice(index, 1);
        }
        incidents.push(param[i]);
      }
    }

    return {
      incidents: incidents,
      updatedIncidents: param,
    };
  }
  @MutationAction
  async updateIncidentOnTabChange() {
    let { incidents, selectedIncidentTab } = this.state as IncidentModule;

    if (selectedIncidentTab === undefined || selectedIncidentTab == null) {
      selectedIncidentTab = IncidentStatus.Created;
    }
    const updated = incidents.filter((x) => x.Status == selectedIncidentTab);

    return { updatedIncidents: updated };
  }

  @MutationAction
  async getUpdatedPatrollings() {
    const obj = this.state as IncidentModule;
    var patrollings = Object.assign({}, obj.patrollings) as Record<
      string,
      Patrolling
    >;
    console.log(patrollings);
    let data: any = {};
    var lastSynced = obj.lastSyncedDateForPatrol;

    data = await api.getPatrolling(lastSynced);

    for (let i = 0; i < data.Patrolling.length; i++) {
      const key = data.Patrolling[i].PatrollingId;
      patrollings[key] = data.Patrolling[i];
    }

    return {
      updatedPatrollings: data.Patrolling as Patrolling[],
      patrollings: patrollings,
      lastSyncedDateForPatrol: data.Synced,
    };
  }

  @MutationAction
  async startPatrolling() {
    const obj = this.state as IncidentModule;
    let lat = obj.lat;
    let lng = obj.lng;

    console.log(lng);
    const data = await api.startPatrolling(
      lat.toString(),
      lng.toString(),
      "xyz444"
    );

    return { patrollingId: data };
  }
  @MutationAction
  async updatePatrolling() {
    const obj = this.state as IncidentModule;
    let lat = obj.lat;
    let lng = obj.lng + 0.0005;
    const data = await api.updatePatrolling(
      lat.toString(),
      lng.toString(),
      obj.patrollingId
    );
    return { lng: lng };
  }

  @MutationAction
  async loadNearestPatrolling(param: any) {
    const result = await api.nearestPatrolling(param.lat, param.lng);
    return {
      nearesetPatrollings: result as NearestPatrolling[],
      isPatrollingLoading: false,
    };
  }

  @MutationAction
  async loadIncidentMaterials(incidentId: string) {
    const obj = this.state as IncidentModule;
    var index = obj.incidents.findIndex((x) => x.IncidentId == incidentId);
    let materials: IncidentMaterial[] = [];
    if (index > -1) {
      if (!obj.incidents[index].IncidentMaterials) {
        obj.incidents[index].IncidentMaterials = [];
      }
      if (obj.incidents[index].IncidentMaterials.length == 0) {
        //load only if length is 0
        const result = await api.getIncidentMaterials(incidentId);
        obj.incidents[index].IncidentMaterials = result as IncidentMaterial[];
      }
      materials = obj.incidents[index].IncidentMaterials;
    }
    return { incidents: obj.incidents, IncidentSelectedMaterials: materials };
  }

  @MutationAction
  async assignIncident(param: any) {
    const obj = this.state as IncidentModule;
    const incidents = obj.incidents;

    await api.assignIncident(param);
    // incidents[param.IncidentId].Status = IncidentStatus.Assigned;
    return { incidents: incidents };
  }

  @MutationAction
  async setAcknowledgeIncident(incidentId: string) {
    await api.acknowledgeIncident(incidentId);
    const obj = this.state as IncidentModule;
    const incidents = obj.incidents;
    const index = incidents.findIndex((x) => x.IncidentId == incidentId);

    // if (index > -1) {
    //     incidents[index].Status = IncidentStatus.Acknowledge;
    // }
    return { incidents: incidents };
  }

  @MutationAction
  async setRejectIncident(incidentId: string) {
    await api.rejectIncident(incidentId);
    const obj = this.state as IncidentModule;
    const incidents = obj.incidents;
    // const index = incidents.findIndex(x => x.IncidentId == incidentId);
    // if (index > -1) {
    //     incidents[index].Status = IncidentStatus.Rejected;
    // }

    return { incidents: incidents };
  }

  @MutationAction
  async getIncidentReport(param: any) {
    const res = (await api.getIncidentReport(param)) as Incident[];
    return { IncidentReports: res };
  }

  @Action
  async getIncidentsByPaging(param: any) {
    this.setRightDashLoader(true);
    let data = await api.getIncidentsByPaging(param);
    this.setUpdatedIncidents(data.Data as Incident[]);
    this.setIncidents(data.Data as Incident[]);
    this.setRightDashLoader(false);
    return data;
  }

  @Action
  async getLiveDataByPaging(param: any) {
    this.setRightDashLoader(true);
    let data = await CommonStore.liveMonitoringData;
    this.setUpdatesLiveDta(data as LiveData[]);
    this.setLiveDatas(data as LiveData[]);
    this.setRightDashLoader(false);
    return data;
  }

  @Action
  async getLiveMonitoringDataByPaging(param: any) {
    this.setRightDashLoader(true);
    let data = await api.getLiveMonitoringByPaging(param);

    console.log(data);
    this.setUpdatesLiveDta(data as LiveData[]);
    this.setLiveDatas(data as LiveData[]);
    this.setRightDashLoader(false);
    return data;
  }

  @Action
  async getSectionsByPaging(param: any) {
    let route_id = CommonStore.routeId;
    this.setRightDashLoader(true);
    let data = await mapi.getSections(route_id);
    this.setUpdatedSections(data.Data as Section[]);
    this.setSections(data.Data as Section[]);
    this.setRightDashLoader(false);
    return data;
    //return null;
  }

  @Action
  async getStopsByPaging(param: any) {
    let routeId = CommonStore.routeId;
    let query = `?route_id=${routeId}`;

    this.setRightDashLoader(true);
    let data = await mapi.getStopData(query);
    this.setUpdatedStops(data.Data as Stop[]);
    this.setStops(data.Data as Stop[]);
    this.setRightDashLoader(false);
    return data;
    //return null;
  }

  @Action
  async getComplainByPaging(param: any) {
    this.setRightDashLoader(true);
    let data = await api.getComplainByPaging(param);
    this.setUpdatedIncidents(data.Data as Incident[]);
    this.setIncidents(data.Data as Incident[]);
    this.setRightDashLoader(false);
    return data;
  }

  @Mutation
  setComplainOrIncident(a: any) {
    this.incidentOrComplain = a;
  }
}

export default getModule(IncidentModule);
