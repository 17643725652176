import {
  patchApi,
  postApi,
  postApiWithoutToken,
  getApi,
  getApiWithoutLoader,
  postApiWithoutLoader,
} from "@/store/api/api";
import { Action } from "@/store/actionType";
import {
  District,
  Palika,
  Stop,
  RouteStop,
  SpeedSection,
} from "@/store/models/meta";

export default new (class MetaAPI {
  public async getVehicleListDD(): Promise<any> {
    const action = Action.getVehicleList;
    const response = await getApi(action);
    return response as any;
  }
  public async getProtocolListDD(): Promise<any> {
    const action = Action.getProtocol;
    const response = await getApi(action);
    return response as any;
  }
  public async getVehicleListByOwnerDD(id: any): Promise<any> {
    const action = Action.getVehicleList + "?vehicle_owner=" + id;
    const response = await getApi(action);
    return response as any;
  }
  public async getSourceDestinationDD(): Promise<any> {
    const action = Action.getSourceDestination;
    const response = await getApi(action);
    return response as any;
  }
  public async getDestinationOnBasisOfSource(source: string): Promise<any> {
    const action =
      Action.getSourceDestination + `get_destination_list/?source=${source}`;
    const response = await getApi(action);
    return response as any;
  }
  public async getUserTypeDD(query: any = ""): Promise<any> {
    const action = Action.getUserType + query;
    const response = await getApi(action);
    return response as any;
  }
  public async getRoutesDD(): Promise<any> {
    const action = Action.getRoutes;
    const response = await getApi(action);
    return response as any;
  }
  public async postRouteDD(params: any): Promise<any> {
    const action = Action.getRoutesOfUser;
    const response = await postApi(action, params);
    return response as any;
  }
  public async patchRouteDD(params: any): Promise<any> {
    const action = Action.getRoutesOfUser + params.gid + "/";
    const response = await patchApi(action, params);
    return response as any;
  }
  public async getRoutesOfUser(): Promise<any> {
    const action = Action.getRoutesOfUser;
    const response = await getApi(action);
    return response as any;
  }
  public async getVehicleTypeCodeDD(): Promise<any> {
    const action = Action.getVehicleTypeCodeDD;
    const response = await getApi(action);
    return response as any;
  }
  public async getVehicleTypeDD(): Promise<any> {
    const action = Action.getVehicleTypeDD;
    const response = await getApi(action);
    return response as any;
  }
  public async getProvince(): Promise<any> {
    const action = Action.getProvince;
    const response = await getApi(action);
    return response as any;
  }
  public async getDistrict(): Promise<any> {
    const action = Action.getDistrict;
    const response = await getApi(action);
    return response as any;
  }

  public async getRoles(): Promise<any> {
    const action = Action.getRoles;
    const response = await getApi(action);
    return response as any;
  }
  public async getMunicipality(): Promise<any> {
    const action = Action.getMunicipality;
    const response = await getApi(action);
    return response as any;
  }

  public async getPoliceUser(): Promise<any> {
    const action = Action.getPoliceUser;
    const response = await getApi(action);
    return response as any;
  }
  public async getPoliceStationGeoObj(): Promise<any> {
    const action = Action.getPoliceStationGeoObj;
    const response = await getApi(action);
    return response as any;
  }

  public async getRouteGeoObj(query: string = ""): Promise<any> {
    const action = Action.getRouteGeoJson + query;
    const response = await getApi(action);
    return response as any;
  }

  public async getStopGeoObj(query: string = ""): Promise<any> {
    const action = Action.getStopGeoJson + query;
    const response = await getApi(action);
    return response as any;
  }

  public async getTraccar(): Promise<any> {
    const action = Action.getTraccarDetails;
    const response = await getApi(action);
    return response as any;
  }

  public async getSections(routeId: any): Promise<any> {
    const action = Action.getSections + "?route_id=" + routeId;
    const response = await getApi(action);
    return response as any;
  }

  public async postSections(section: any): Promise<any> {
    const action = Action.getSections;
    const response = await postApi(action, section);
    return response as any;
  }

  public async getStops(query = ""): Promise<any> {
    const action = `${Action.getStops}${query}`;
    const response = await getApi(action);
    return response as any;
  }

  public async getStopData(query = ""): Promise<any> {
    const action = `${Action.getStopData}${query}`;
    const response = await getApi(action);
    return response as any;
  }

  public async getStop(): Promise<any> {
    const action = `${Action.getStop}`;
    const response = await getApi(action);
    return response as Stop[];
  }

  public async getStopById(id: number): Promise<any> {
    const action = `${Action.getStop}${id}`;
    const response = await getApi(action);
    return response as Stop;
  }

  public async postStop(data: Stop): Promise<any> {
    const action = Action.getStop;
    const response = await postApi(action, data);
    return response as any;
  }

  public async patchStop(data: Stop): Promise<any> {
    const action = Action.getStop + data.gid + "/";
    const response = await patchApi(action, data);
    return response as any;
  }

  public async getRouteStop(query = ""): Promise<any> {
    const action = Action.routeStop + query;
    const response = await getApi(action);
    return response as RouteStop[];
  }

  public async getRouteStopById(id: number): Promise<any> {
    const action = Action.routeStop + id;
    const response = await getApi(action);
    return response as RouteStop[];
  }

  public async createRouteStop(data: any): Promise<any> {
    const action = Action.routeStop;
    const response = await postApi(action, data);
    return response as any;
  }

  public async patchRouteStop(data: any): Promise<any> {
    const action = Action.routeStop + data.id + "/";
    const response = await patchApi(action, data);
    return response as any;
  }

  public async getRoadLink(): Promise<any> {
    const action = Action.roadLink;
    const response = await getApi(action);
    return response as any;
  }

  public async getRoadLinkById(id: any): Promise<any> {
    const action = Action.roadLink + id;
    const response = await getApi(action);
    return response as any;
  }

  public async createRoadLink(data: any): Promise<any> {
    const action = Action.roadLink;
    const response = await postApi(action, data);
    return response as any;
  }

  public async patchRoadLink(data: any): Promise<any> {
    const action = Action.roadLink + `id=${data.gid}/`;
    const response = await patchApi(action, data);
    return response as any;
  }

  public async getRouteRoad(query = ""): Promise<any> {
    const action = Action.routeRoad + query;
    const response = await getApi(action);
    return response as any;
  }

  public async getRouteRoadById(id: any): Promise<any> {
    const action = Action.routeRoad + id;
    const response = await getApi(action);
    return response as any;
  }

  public async createRouteRoad(obj: any): Promise<any> {
    const action = Action.routeRoad;
    const response = await postApi(action, obj);
    return response as any;
  }

  public async patchRouteRoad(obj: any): Promise<any> {
    const action = Action.routeRoad + `${obj.id}/`;
    const response = await patchApi(action, obj);
    return response as any;
  }

  public async getSpeedSection(query = ""): Promise<any> {
    const action = Action.speedSection + query;
    const response = await getApi(action);
    return response as any;
  }

  public async getSpeedSectionById(id: number): Promise<any> {
    const action = Action.speedSection + id;
    const response = await getApi(action);
    return response as any;
  }

  public async createSpeedSection(obj: SpeedSection): Promise<any> {
    const action = Action.speedSection;
    const response = await postApi(action, obj);
    return response as any;
  }

  public async patchSpeedSection(obj: SpeedSection): Promise<any> {
    const action = Action.speedSection + `${obj.id}/`;
    const response = await patchApi(action, obj);
    return response as any;
  }

  public async searchSpeedSections(query = ""): Promise<any> {
    //Endpoints for Searching SpeedSection of particular Route: routes/getSpeedSections/?id=1
    const action = Action.getRoutesOfUser + "getSpeedSections/" + query;
    const response = await getApi(action);
    return response as any;
  }

  public async getRouteBySection(id: number, roadid: number): Promise<any> {
    const action =
      Action.getDataBySectionId + "?sectionId=" + id + "&roadId=" + roadid;
    console.log(action);
    const response = await getApi(action);
    return response as any;
  }

  public async getDutyUserByDutyOffice(id: string): Promise<any> {
    const action = Action.getDutyUserByDutyOffice + "?id=" + id;
    const response = await getApi(action);
    return response as any;
  }

  public async getIncidentCategory(): Promise<any> {
    const action = Action.getIncidentCategory + "?covid=all";
    const response = await getApi(action);
    return response as any;
  }

  public async getComplainSubCategory(): Promise<any> {
    const action = Action.getComplainSubCategory;
    const response = await getApi(action);
    return response as any;
  }

  public async getVehicleOwnerOnly(): Promise<any> {
    const action = Action.getAllVehicleOwner;
    const response = await getApi(action);
    return response as any;
  }
})();
