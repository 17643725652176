import store from "@/store";
import {
  Module,
  VuexModule,
  Mutation,
  MutationAction,
  Action,
  getModule,
} from "vuex-module-decorators";
import api from "@/store/api/registrations";
import helpers from "@/utils/helpers";
import { CommonStore } from ".";
import {
  Geom,
  profile,
  SystemUsers,
  Users,
} from "../models/registration/registration";

@Module({ name: "register", dynamic: true, store })
class UserRegistrationModule extends VuexModule {
  public user: Users = {} as Users;
  public userList: Users[] = [];
  public publicUser: Users = {} as Users;
  public systemUserDetail: SystemUsers = {} as SystemUsers;
  public SysUserList: SystemUsers[] = [];
  public profile: profile = {} as profile;
  public geom: Geom = {} as Geom;

  @Mutation
  async resetField() {
    this.user = {
      profile: {} as profile,
      systemUserDetail: { geom: {} as Geom } as SystemUsers,
    } as Users;
    return { user: this.user as Users };
  }

  @MutationAction
  async loadAllUsers() {
    const data = await api.getUsers();
    return { userList: data as Users[] };
  }

  @MutationAction
  async loadAllSystemUsers(params?: {
    page: number;
    name?: string;
    role?: string;
  }) {

    let query = "";
    if (params) {
      query += "?";
      if (params.page) {
        query += `&page=${params.page}`;
      }
      if(params.name){
        query += `&username=${params.name}`;
      }
      if(params.role){
        query += `&profile__user_type=${params.role}`;
      }
    }
    const data = await api.getSystemUsers(query);
    return { userList: data as Users[] };
  }

  @MutationAction
  async loadAllPublicUsers(params: {page: number; name?: string;}) {
    let query = "";
    if (params) {
      query += "?";
      if (params.page) {
        query += `&page=${params.page}`;
      }
      if(params.name){
        query += `&username=${params.name}`;
      }
    }
    const data = await api.getPublicUsers(query);
    return { userList: data as Users[] };
  }

  @MutationAction
  async loadPublicUserById(id: any) {
    const data = await api.getPublicUserById(id);
    return { publicUser: data as Users };
  }

  @MutationAction
  async loadAllVehicleUsers() {
    const data = await api.getVehicleOwnerUsers();
    return { userList: data as Users[] };
  }

  @MutationAction
  async createUser() {
    let profile = helpers.getUserProfile() as any;
    const o = this.state as UserRegistrationModule;

    var formData = new FormData();
    let data = helpers.getNestedFormData(formData, o.user);
    if (o.user.id) {
      formData.append("id", o.user.id as any);
      var result = await api.updateUsers(data, o.user.id);
    } else {
      var result = await api.createUsers(data);
    }

    return {};
  }

  @MutationAction
  async getUserById(id: any) {
    const data = await api.getUserById(id);
    return { user: data as Users };
  }
}

export default getModule(UserRegistrationModule);
