import { AdminRouter, RootRouter } from "@/utils/newRoutePathConstant";

const admin = {
  path: RootRouter.Root,
  component: () => import("@/newViews/newShared/NewPublicLayout.vue"),
  children: [
    {
      path: RootRouter.NewHome,
      name: "NewHome",
      component: () => import("@/newViews/newPublic/newHome.vue"),
    },
    {
      path: RootRouter.NoticeList,
      name: "NoticeList",
      component: () => import("@/newViews/newPublic/noticeList.vue"),
    },
    {
      path: RootRouter.GpsProviderRegister,
      name: "GpsProviderRegister",
      component: () =>
        import("@/newViews/newAuthscreen/gpsProviderRegister.vue"),
    },
    {
      path: RootRouter.UserRegister,
      name: "UserRegister",
      component: () =>
        import("@/newViews/newAuthscreen/generalUserRegister.vue"),
    },
    {
      path: RootRouter.YatayatRegister,
      name: "YatayatRegister",
      component: () => import("@/newViews/newAuthscreen/yatayatRegister.vue"),
    },
    {
      path: RootRouter.NoticeDetail,
      name: "NoticeDetail",
      component: () => import("@/newViews/newPublic/noticeDetail.vue"),
    },
    {
      path: RootRouter.PlanTravel,
      name: "PlanTravel",
      component: () => import("@/newViews/newPublic/planTravel.vue"),
    },
    {
      path: "/facebook_auth/",
      name: "FacebookAuth",
      component: () => import("@/newViews/newAuthscreen/FacebookAuth.vue"),
    },
    {
      path: "/google_auth/",
      name: "GoogleAuth",
      component: () => import("@/newViews/newAuthscreen/GoogleAuth.vue"),
    },
    {
      path: RootRouter.ForgotPassword,
      name: "ForgotPassword",
      component: () => import("@/newViews/newAuthscreen/forgotPassword.vue"),
    },
    {
      path: RootRouter.ConfirmResetPassword,
      name: "ConfirmResetPassword",
      component: () =>
        import("@/newViews/newAuthscreen/resetPasswordConfirm.vue"),
    },
    {
      path: RootRouter.RegistrationLandingPage,
      name: "RegistrationLandingPage",
      component: () =>
        import("@/newViews/newAuthscreen/registrationLandingPage.vue"),
    },
    {
      path: RootRouter.PublicRegistrationLandingPage,
      name: "PublicRegistrationLandingPage",
      component: () =>
        import("@/newViews/newAuthscreen/publicUserLandingPage.vue"),
    },
    {
      path: "activate/:uid/:token",
      name: "Activate",
      component: () =>
        import(
          /* webpackChunkName: "general" */ "@/newViews/newAuthscreen/publicUserActivation.vue"
        ),
    },
    {
      path: RootRouter.TrackVehicle,
      name: "TrackVehicle",
      component: () => import("@/newViews/newPublic/trackVehicle.vue"),
    },

    // {
    //   path: RootRouter.VehicleSearch,
    //   name: "SearchPlanYourTravel",
    //   component: () => import("@/views/public/vehicleSearch.vue"),
    // },
    // {
    //   path: RootRouter.OwnerRegistration,
    //   component: () => import("@/views/authscreen/ownerRegistration.vue"),
    // },
    // {
    //   path: RootRouter.GPSRegistration,
    //   component: () => import("@/views/authscreen/gpsRegistration.vue"),
    // },
    // {
    //   path: RootRouter.PublicRegister,
    //   component: () => import("@/views/authscreen/publicRegistration.vue"),
    // },
    // {
    //   path: "activate/:uid/:token",
    //   name: "Activate",
    //   component: () =>
    //     import(
    //       /* webpackChunkName: "general" */ "@/views/authscreen/userActivation.vue"
    //     ),
    // },
    // {
    //   path: RootRouter.PublicMap,
    //   name: "PublicMap",
    //   component: () => import("@/views/public/map.vue"),
    // },
    // {
    //   path: RootRouter.PublicNoticeList,
    //   name: "PublicNoticeList",
    //   component: () => import("@/views/public/noticeList.vue"),
    // },
    // {
    //   path: RootRouter.PublicNoticeDetail,
    //   name: "PublicNoticeDetail",
    //   component: () => import("@/views/public/noticeDetail.vue"),
    // },
    // {
    //   path: RootRouter.About,
    //   name: "AboutUs",
    //   component: () => import("@/views/public/about.vue"),
    // },
    // {
    //   path: RootRouter.LandingRegister,
    //   name: "SuccessfullyRegister",
    //   component: () => import("@/views/authscreen/registerLandingPage.vue"),
    // },
    // {
    //   path: RootRouter.GpsLandingRegister,
    //   name: "SuccessLandingfullyRegister",
    //   component: () => import("@/views/authscreen/registerGPSLandingPage.vue"),
    // },
    // {
    //   path: RootRouter.PublicLandingPage,
    //   name: "PublicUserSuccessfullyRegister",
    //   component: () => import("@/views/authscreen/registerPublicPage.vue"),
    // },
    // {
    //   path: RootRouter.ConfirmResetPassword,
    //   component: () => import("@/views/authscreen/resetPasswordConfirm.vue"),
    // },
    // {
    //   path: "/facebook_auth/",
    //   name: "FacebookAuth",
    //   component: () => import("@/views/authscreen/FacebookAuth.vue"),
    // },
    // {
    //   path: "/google_auth/",
    //   name: "GoogleAuth",
    //   component: () => import("@/views/authscreen/GoogleAuth.vue"),
    // },
    // {
    //   path: RootRouter.ResetPassword,
    //   component: () => import("@/views/authscreen/resetPassword.vue"),
    // },
    // {
    //   path: RootRouter.ForgotPassword,
    //   component: () => import("@/views/authscreen/forgotPassword.vue"),
    // },
  ],
};

export default admin;
