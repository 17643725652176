import axios, { AxiosRequestConfig } from "axios";
import Vue from "vue";
import { Action } from "@/store/actionType";
import { eventHub } from "@/utils/eventHub";
import helper from "@/utils/helpers";
import { JWTModule } from "../modules";
import { HasJwtExpired, WaitUntilRefreshed } from "@/utils/jwt";
import router from "@/router";

const vm = new Vue();
let baseUrl = `${process.env.VUE_APP_API_PROTOCOL}://${process.env.VUE_APP_API_BASE_URL}`;
if (baseUrl != "://") {
  baseUrl = "/";
}

const actionBase = axios.create({
  baseURL: `${baseUrl}${Action.Base}`,
});

// Make Axios play nice with Django CSRF
actionBase.defaults.xsrfCookieName = "csrftoken";
actionBase.defaults.xsrfHeaderName = "X-CSRFToken";

actionBase.interceptors.request.use(
  async (config) => {
    if (config.url != `/${Action.refreshToken}` && JWTModule.RefreshingToken) {
      await WaitUntilRefreshed();
    }
    if (
      config.url != `/${Action.refreshToken}` &&
      config.url != `/${Action.login}` &&
      JWTModule.AccessToken &&
      JWTModule.AccessToken != ""
    ) {
      if (HasJwtExpired()) {
        JWTModule.refreshingToken();
        await JWTModule.refreshAccessToken();
      }
      config.headers.common["Authorization"] =
        "Bearer " + JWTModule.AccessToken;
    } else {
      delete config.headers.common["Authorization"];
    }
    return config;
  },
  (error) => {
    vm.$snotify.error(error, "error");
    return new Promise(error);
  }
);

actionBase.interceptors.response.use(
  (config) => {
    return config;
  },
  (error) => {
    /** Handler for logging out if refresh token is expired */
    if (error.response.config.url == `/${Action.refreshToken}`) {
      if (error.response.status == 401) {
        if (error.response.data.message != "NoError") {
          JWTModule.clearJWT().then(() => {
            router.push({ name: "NewHome" });
            vm.$snotify.error(
              "You have been logged out! Please login again",
              "error"
            );
          });
          return new Promise(error);
        }
      }
    }

    /** Handler for refreshing access token */
    if (error.response.config.url != `/${Action.refreshToken}`) {
      if (
        (error.response.status == 403 || error.response.status == 401) &&
        error.response.data.message == "Token is invalid or expired"
      ) {
        JWTModule.refreshingToken();
        JWTModule.refreshAccessToken().then(() => {
          const config = error.response.config;
          if (JWTModule.AccessToken && JWTModule.AccessToken != "") {
            config.headers.Authorization = `Bearer ${JWTModule.AccessToken}`;
            actionBase.request(config);
          }
          return config;
        });
        return new Promise(error);
      }
    }

    /** Handler for error response with and without message */
    if (error.response.data.message) {
      if (error.response.data.message != "NoError") {
        vm.$snotify.error(error.response.data.message, "Error");
      }
    } else {
      eventHub.$emit("network-error");
    }
    return new Promise(error);
  }
);

export async function postApi(
  action: string,
  param: any,
  config?: AxiosRequestConfig
): Promise<any> {
  eventHub.$emit("before-request");
  // console.log("axios api ");
  // console.log(action);
  // console.log(param);
  return await new Promise((resolve, reject) => {
    // setJWT();
    actionBase.post("/" + action, param, config).then(
      (res) => {
        eventHub.$emit("after-response");
        resolve(res.data);
      },
      (error) => {
        eventHub.$emit("after-response");
        var vm = new Vue();
        if (
          (action == `/${Action.refreshToken}` ||
            action == `${Action.refreshToken}`) &&
          error.response &&
          (error.response.status == 400 || error.response.status == 401)
        ) {
          resolve({});
        }
        // if (err.response) {
        //   if (err.response.status == 401) {
        //     eventHub.$emit("token-expired");
        //   } else {
        //     vm.$snotify.error(err.response.data.Message, "error");
        //   }
        // } else {
        //   eventHub.$emit("network-error");
        // }
        eventHub.$emit("error");
        reject(error);
      }
    );
  });
}
export async function putApi(action: string, param: any): Promise<any> {
  eventHub.$emit("before-request");
  // console.log("axios api ");
  // console.log(action);
  // console.log(param);
  return await new Promise((resolve, reject) => {
    setJWT();
    actionBase.put("/" + action, param).then(
      (res) => {
        eventHub.$emit("after-response");
        resolve(res.data);
      },
      (err) => {
        eventHub.$emit("after-response");
        var vm = new Vue();
        if (err.response) {
          if (err.response.status == 401) {
            eventHub.$emit("token-expired");
          } else {
            vm.$snotify.error(err.response.data.Message, "error");
          }
        } else {
          eventHub.$emit("network-error");
        }
        eventHub.$emit("error");
      }
    );
  });
}
export async function postApiWithoutToken(
  action: string,
  param: any
): Promise<any> {
  eventHub.$emit("before-request");
  clearJWT();
  return await new Promise((resolve, reject) => {
    actionBase.post("/" + action, param).then(
      (res) => {
        eventHub.$emit("after-response");
        resolve(res.data);
      },
      (err) => {
        eventHub.$emit("after-response");
        var vm = new Vue();
        // if (err.response) {
        //   if (err.response.status == 401) {
        //     eventHub.$emit("token-expired");
        //   } else {
        //     vm.$snotify.error(err.response.data.Message, "error");
        //   }
        // } else {
        //   eventHub.$emit("network-error");
        // }
        eventHub.$emit("error");
      }
    );
  });
}

export async function postApiWithoutLoader(
  action: string,
  param: any
): Promise<any> {
  return await new Promise((resolve, reject) => {
    console.log(param);
    // setJWT();
    actionBase.post("/" + action, param).then(
      (res) => {
        resolve(res.data);
      },
      (err) => {
        var vm = new Vue();
        if (err.response) {
          if (err.response.status == 401) {
            eventHub.$emit("token-expired");
          }

          //  vm.$snotify.error(err.response.data.Message, "error");
        } else {
        }
        eventHub.$emit("error");
      }
    );
  });
}

export async function patchApi(action: string, param: any): Promise<any> {
  eventHub.$emit("before-request");
  return await new Promise((resolve, reject) => {
    // setJWT();
    actionBase.patch("/" + action, param).then(
      (res) => {
        eventHub.$emit("after-response");
        resolve(res.data);
      },
      (err) => {
        eventHub.$emit("after-response");
        var vm = new Vue();
        if (err.response) {
          if (err.response.status == 401) {
            eventHub.$emit("token-expired");
          } else {
            vm.$snotify.error(err.response.data.Message, "error");
          }
        } else {
          eventHub.$emit("network-error");
        }
        eventHub.$emit("error");
      }
    );
  });
}

export async function deleteApi(action: String): Promise<any> {
  eventHub.$emit("before-request");
  return await new Promise((resolve, reject) => {
    // setJWT();
    actionBase.delete("/" + action).then(
      (res) => {
        eventHub.$emit("after-response");
        resolve(res.data);
      },
      (err) => {
        eventHub.$emit("after-response");
        var vm = new Vue();
        if (err.response) {
          if (err.response.status == 401) {
            eventHub.$emit("token-expired");
          } else {
            vm.$snotify.error(err.response.data.Message, "error");
          }
        } else {
          eventHub.$emit("network-error");
        }
        eventHub.$emit("error");
      }
    );
  });
}

export async function getApi(action: string): Promise<any> {
  return await new Promise((resolve, reject) => {
    eventHub.$emit("before-request");
    // setJWT();
    actionBase.get("/" + action).then(
      (res) => {
        eventHub.$emit("after-response");
        resolve(res.data);
      },
      (err) => {
        eventHub.$emit("after-response");

        var vm = new Vue();
        if (err.response) {
          if (err.response.status == 401) {
            eventHub.$emit("token-expired");
          } else {
            vm.$snotify.error(err.response.data.Message, "error");
          }
        } else {
          eventHub.$emit("network-error");
        }
        eventHub.$emit("error");
      }
    );
  });
}

export async function getApiWithoutToken(action: string): Promise<any> {
  clearJWT();
  return await new Promise((resolve, reject) => {
    eventHub.$emit("before-request");

    actionBase.get("/" + action).then(
      (res) => {
        eventHub.$emit("after-response");
        resolve(res.data);
      },
      (err) => {
        eventHub.$emit("after-response");

        var vm = new Vue();
        if (err.response) {
          if (err.response.status == 401) {
            eventHub.$emit("token-expired");
          } else {
            vm.$snotify.error(err.response.data.Message, "error");
          }
        } else {
          eventHub.$emit("network-error");
        }
        eventHub.$emit("error");
      }
    );
  });
}

export async function getApiWithoutLoader(action: string): Promise<any> {
  return await new Promise((resolve, reject) => {
    // setJWT();
    //eventHub.$emit('before-request');
    actionBase.get("/" + action).then(
      (res) => {
        // eventHub.$emit('after-response');
        resolve(res.data);
      },
      (err) => {
        //eventHub.$emit('after-response');

        var vm = new Vue();
        if (err.response) {
          if (err.response.status == 401) {
            eventHub.$emit("token-expired");
          } else {
            vm.$snotify.error(err.response.data.Message, "error");
          }
        } else {
          //eventHub.$emit('network-error');
        }
        eventHub.$emit("error");
      }
    );
  });
}

function setJWT() {
  const token = helper.getToken();
  actionBase.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}

function clearJWT() {
  delete actionBase.defaults.headers.common["Authorization"];
}
