import { postApi, postApiWithoutToken, getApi, getApiWithoutLoader, postApiWithoutLoader } from "@/store/api/api";
import { Action } from "@/store/actionType";


export default new class ReportAPI {

  public async getIncidentReport(action:Action,obj:any,):Promise<any>{
    //const action = Action.getIncidentReportByStatus;
    
    const response = await postApi(action,obj);
    return response as any

  }

  public async getComplainReport(action:Action,obj:any,):Promise<any>{
    //const action = Action.getIncidentReportByStatus;
    
    const response = await postApi(action,obj);
    return response as any

  }

}