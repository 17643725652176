export enum RootRouter {
  Root = "/",
  Login = "/login",
  SelectRegistrationType = "/register",
  Register = "/register",
  ForgotPassword = "/forgotPassword",
  ResetPassword = "/reset",
  ConfirmResetPassword = "/reset/confirm/:uid/:token",
  ChangePassword = "/changePassword",
  OwnerRegistration = "/register/vehicleOwner",
  GPSRegistration = "/register/gps",
  Home = "/home",
  About = "/about",
  LandingRegister = "/register/success",
  PublicLandingPage = "/public/landing",
  TrackVehicle = "/trackVehicle",
  VehicleSearch = "/vehicleSearch",
  PublicRegister = "/public/register",
  PublicMap = "/public/map",
  PublicNoticeList = "/public/notices",
  PublicNoticeDetail = "/public/notices/:id",
  GpsLandingRegister = "/gps/landing",
  Policy = "/policy",
}

export enum IncidentRouter {
  IncidentDashboard = "/router/dashboard",
}

export enum ComplainRouter {
  ComplainDashboard = "/complain/dashboard",
}

export enum AdminRouter {
  Root = "/admin",
  Profile = "/profile",
  // ProfileEdit = "/profile/edit",
  History = "/history/history",

  //Vehicle
  VehilceList = "/vehicle/list",
  VehicleRegister = "/vehicle/create",
  VehicleApproval = "/vehicle/vehicleList",
  VehicleDetails = "/vehicle/details",
  VehicleRouteChange = "/vehicle/route",
  AllVehicleList = "/vehicles",
  //Driver
  DriverList = "/driver/list",
  DriverRegister = "/driver/register",
  DriverDetail = "/driver/detail",
  OwnerDriverList = "/driver/owner-driver",
  //SystemUsers
  UserList = "/user",
  UserRegister = "/user/register",
  PublicUserList = "/public/users",
  PublicUserDetail = "/public/users/detail",
  VehicleUserList = "/vehicle/users",

  //GpsDevice
  GPSDeviceList = "/gpsdevice",
  GPSDeviceRegister = "/gpsdevice/register",
  GPSDeviceDetail = "/gpsdevice/detail",

  //GpsService
  GPSServiceList = "/gpsservice",
  GPSServiceRegister = "/gpsservice/register",
  GPSServiceDetail = "/gpsservice/detail",
  GPSList = "/gps/list",
  //Ownership = "/authscreen/ownerRegistration",

  //VehicleSchedule
  VehicleScheduleList = "/schedule",
  VehicleScheduleCreate = "/schedule/register",

  //Email
  EmailCreate = "/email/register",

  //Chat
  Chat = "/room",

  //TrafficJam,
  TrafficJam = "/jam",

  //One time entry
  VehicleEntryList = "/entry",
  VehicleEntryCreate = "/entry/register",
  VehicleEntryDetail = "/entry/detail",

  General = "/admin/general",

  //owner
  VehicleOwnerList = "/owner",
  VehicleOwnerDetails = "/owner/details",

  //news
  NewsFeed = "/newsfeed",
  CreateNewsFeed = "/newsfeed/create",

  //route
  Route = "/route",
  //routeinfo
  RouteInfo = "/routeinfo",
  RouteDetail = "/routeinfo/detail",
  CreateRouteInfo = "/routeinfo/create",
  AddStopToRoute = "/routeinfo/addStop",
  AddRoadLink = "/routeinfo/addRoadLink",

  //speedSection
  SpeedSection = "/speedSection",
  CreateSpeedSection = "/speedSection/create",

  //stopinfo
  Stop = "/stop",
  CreateStop = "/stop/create",

  //routestop
  RouteStop = "/routestop",
  CreateRouteStop = "/routestop/create",

  //roadlink
  RoadLink = "/roadlink",
  CreateRoadLink = "/roadlink/create",

  //routeRoad
  RouteRoad = "/routeroad",
  CreateRouteRoad = "/routeroad/create",

  //need to be removed
  ComplainGeneral = "/admin/complain",
  IncidentReportByData = "/incident/report/data",
  ComplainReportByData = "/complain/report/data",
  NewsList = "/news",
  NewsCreate = "/news/create",
  NewsDetail = "/news/detail",
  incidentCategoryAssign = "/incident/catgory/assign",
  incidentCategoryList = "/incdient/category",
  incidentCategoryCreate = "/incident/category/create",
  //FAQ
  FAQList = "/faq/list",
  FAQCreate = "/faq/create",
  FAQDetail = "/faq/detail",

  //FeedBack
  feedBackLst = "/feedback",

  //Notice
  noticeList = "/notices",
  noticeCreate = "/notice/create",
  noticeTypeList = "/notice/types",

  //Reporting
  HistoryReport = "/report",
}
