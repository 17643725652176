import Vue from "vue";
Vue.mixin({
  methods: {
    isFieldRequired(event: any) {
      let index = this.$validator.fields.items.findIndex(
        (x) => x.name == event
      );
      if (index >= 0 && this.$validator.fields.items[index].flags["required"]) {
        return true;
      }
      return false;
    },
    fieldHasErrors(event: any) {
      return this.$validator.errors.has(event);
    },
  },
});
