import {
  postApi,
  postApiWithoutToken,
  getApi,
  getApiWithoutLoader,
  postApiWithoutLoader,
  putApi,
  patchApi,
  deleteApi,
} from "@/store/api/api";
import { Action } from "@/store/actionType";
import { Vehicle, VehicleSearch } from "../models/registration/registration";

export default new (class DriverAPI {
  public async getVehicle(query: string = ""): Promise<any> {
    const action = Action.getVehicle + query;
    const response = await getApi(action);

    return response as any;
  }

  public async getVehicleCount(): Promise<any> {
    const action = Action.getVehicleCount;
    const response = await getApi(action);

    return response as any;
  }
  public async getVehicleCSV(query = ""): Promise<any> {
    const action = Action.vehicleByOwner + query;
    const response = await getApi(action);

    return response as any;
  }

  public async getVehicleByStatus(query = ""): Promise<any> {
    const action = `${Action.getVehicle}${query}`;
    const response = await getApi(action);
    return response as any;
  }

  public async createVehicle(driver: any): Promise<any> {
    const action = Action.createVehicle;
    const response = await postApi(action, driver);

    return response as any;
  }

  public async deleteVehicle(id: any): Promise<any> {
    const action = Action.getVehicle + `${id}/`;
    const response = await deleteApi(action);

    return response as any;
  }

  public async changeVehicleRoute(route: any): Promise<any> {
    const action = Action.changeVehicleRoute;
    const response = await postApi(action, route);

    return response as any;
  }

  public async updateVehicle(driver: any, id: any): Promise<any> {
    const action = Action.updateVehicle + id + "/";
    const response = await patchApi(action, driver);
    return response as any;
  }

  // public async deleteDriver(owner: Ownership): Promise<any> {
  //     if (owner.id !== undefined) {
  //         const action = Action.deleteNews + "?id=" + owner.id;
  //         const response = await postApi(action, owner);
  //         return response as any;
  //     }

  //     else {
  //         const action = Action.deleteNews + "?id=" + owner.id;
  //         const response = await postApi(action, owner);
  //         return response as any;
  //     }
  // }

  public async getVehicleById(id: number): Promise<any> {
    const action = Action.getVehicle + id + "/";
    const response = await getApi(action);
    return response as any;
  }

  public async getVehicleByOwnerId(id: number): Promise<any> {
    const action = Action.getVehicle + "?vehicle_owner=" + id;
    const response = await getApi(action);
    return response as any;
  }

  public async approveVehicle(id: any): Promise<any> {
    const action = Action.approveVehicle + id + "/approve_vehicle/";
    const response = await postApi(action, id);
    return response as any;
  }

  public async reapplyVehicle(id: any): Promise<any> {
    const action = Action.approveVehicle + id + "/reapply_vehicle/";
    const response = await postApi(action, id);
    return response as any;
  }

  public async searchPlanYourTravel(query = ""): Promise<any> {
    const action = `${Action.searchPlanYourTravel}${query}`;
    const response = await getApi(action);
    return response as any;
  }

  public async approveVehicles(params: {
    id: any;
    remarks: string;
  }): Promise<any> {
    const action = Action.approveVehicle + params.id + "/hold_vehicle_owner/";
    const response = await postApi(action, {
      profile: { remarks: params.remarks },
    });
    return response as any;
  }

  public async physicalCheckVehicles(params: {
    id: any;
    remarks: string;
    location: string;
    date: any;
    time: any;
    reshedule: boolean;
  }): Promise<any> {
    const action =
      Action.approveVehicle + params.id + "/schedule_physical_verification/";
    const response = await postApi(action, params);
    return response as any;
  }

  public async rejectVehicles(params: {
    id: any;
    remarks: string;
  }): Promise<any> {
    const action = Action.approveVehicle + params.id + "/hold_vehicle/";
    const response = await postApi(action, params);
    return response as any;
  }

  public async submitForApproval(li: any): Promise<any> {
    const action = Action.submitMultipleVehicles;
    const response = await postApi(action, li);
    return response as any;
  }

  public async getTraccarHistory(query: string): Promise<any> {
    const action = `${Action.traccarHistory}${query}`;
    const response = await getApi(action);
    return response as any;
  }
})();
