import { IncidentRouter, RootRouter } from "@/utils/routePathContsant";
import meta from "@/store/modules/meta";

const incidentroute = {
  path: "/Incident",
  name: "",
  requiresAuth: true,
  component: () => import("@/views/shared/Layout.vue"),
  children: [
    {
      path: IncidentRouter.IncidentDashboard,
      component: () => import("@/views/incident/dashboard.vue"),
      meta: {
        requiresAuth: true,
        allowedRoles: [
          "admin",
          "dotm_staff",
          "police",
          "operational_staff",
          "vehicle_owner",
          "gps_service_provider",
        ],
      },
    },
    {
      path: RootRouter.ChangePassword,
      component: () => import("@/views/authscreen/changePassword.vue"),
      meta: {
        requiresAuth: true,
      },
    },
  ],
};

export default incidentroute;
