








import { Vue, Component } from "vue-property-decorator";
import { eventHub } from "@/utils/eventHub";
//import CommonStore from "@/store/modules/common";
import { AuthModule, CommonStore, JWTModule } from "@/store/modules";
import SocketHandler from "@/utils/socketHandler";
import helpers from "@/utils/helpers";

@Component({
  components: {},
})
export default class App extends Vue {
  totalEventCreated = 0;
  totalEventFinished = 0;
  showMessageForNetworkError = true;

  get isLoading() {
    return CommonStore.isLoading;
  }

  async beforeCreate() {
    await JWTModule.refreshAccessToken();
  }
  created() {
    eventHub.$on("before-request", this.beforeRequest);
    eventHub.$on("after-response", this.afterResponse);
    eventHub.$on("network-error", this.networkError);
    eventHub.$on("token-expired", this.onTokenExpired);
  }

  get percentage() {
    if (this.totalEventCreated === 0) {
      return 0;
    }
    return (this.totalEventFinished / this.totalEventCreated) * 100;
  }

  beforeRequest() {
    this.$Progress.start();
    this.totalEventCreated = this.totalEventCreated + 1;
    if (this.totalEventCreated === 1) {
      this.showMessageForNetworkError = true;
    }
  }

  afterResponse() {
    this.totalEventFinished = this.totalEventFinished + 1;
    if (this.totalEventFinished >= this.totalEventCreated) {
      this.totalEventCreated = 0;
      this.totalEventFinished = 0;
      this.$Progress.finish();
      CommonStore.hideLoading();
    }
  }

  networkError() {
    if (this.showMessageForNetworkError) {
      this.$snotify.error(
        "Please check your internet connection",
        "Network Error"
      );
      this.showMessageForNetworkError = false;
    }
  }
  onTokenExpired() {
    if (!AuthModule.lockTokenExpiredMessage) {
      this.$snotify.error("Token expired", "");
      AuthModule.setLockTokenExpiredMessage(true);
      SocketHandler.closeConnection();
      helpers.removeUserProfile();
      this.$router.push({ name: "Home" });
    }
  }
}
