import {
  postApi,
  postApiWithoutToken,
  getApi,
  getApiWithoutLoader,
  postApiWithoutLoader,
  putApi,
} from "@/store/api/api";
import { Action } from "@/store/actionType";
import { EmailSetup } from "../models/registration/registration";

export default new (class EmailSetupAPI {
  public async getEmail(): Promise<any> {
    const action = Action.getEmail;
    const response = await getApi(action);

    return response as any;
  }
  public async createEmail(entry: any): Promise<any> {
    const action = Action.createEmail;
    const response = await postApi(action, entry);
    return response as any;
  }

  public async sendEmail(entry: any): Promise<any> {
    const action = Action.sendEmail;
    const response = await postApi(action, entry);
    return response as any;
  }

  public async updateEmail(entry: any, id: any): Promise<any> {
    const action = Action.updateEmail + id + "/";
    const response = await putApi(action, entry);
    return response as any;
  }

  public async getEmailById(id: number): Promise<any> {
    const action = `${Action.getEmail}${id}/`;
    const response = await getApi(action);
    return response as any;
  }
})();
