import {
  postApi,
  postApiWithoutToken,
  getApi,
  getApiWithoutLoader,
  postApiWithoutLoader,
  patchApi,
} from "@/store/api/api";
import { Action } from "@/store/actionType";
import { identityTransform } from "ol/proj";
// import { Ownership } from "../models/registration/registration";

export default new (class DriverAPI {
  public async getDriver(query: string = ""): Promise<any> {
    const action = Action.getDriver + query;
    const response = await getApi(action);

    return response as any;
  }

  public async getDriverCSV(): Promise<any> {
    const action = Action.drivercsv;
    const response = await getApi(action);

    return response as any;
  }

  public async createDriver(driver: any): Promise<any> {
    const action = Action.createDriver;
    const response = await postApi(action, driver);

    return response as any;
  }

  public async updateDriver(driver: any, driverId: number): Promise<any> {
    const action = `${Action.editDriver}${driverId}/`;
    const response = await patchApi(action, driver);
    return response as any;
  }

  public async deleteDriver(id: any): Promise<any> {
    const action = `${Action.deleteDriver}?id=${id}`;
    const response = await getApi(action);
    return response as any;
  }

  public async activeDriver(obj: any): Promise<any> {
    const action = Action.activateDriver + `approve_driver/?id=${obj.id}`;
    const response = await getApi(action);
    return response as any;
  }

  // public async deleteDriver(owner: Ownership): Promise<any> {
  //     if (owner.id !== undefined) {
  //         const action = Action.deleteNews + "?id=" + owner.id;
  //         const response = await postApi(action, owner);
  //         return response as any;
  //     }

  //     else {
  //         const action = Action.deleteNews + "?id=" + owner.id;
  //         const response = await postApi(action, owner);
  //         return response as any;
  //     }
  // }

  public async getDriverById(id: number): Promise<any> {
    const action = `${Action.getDriver}${id}/`;
    const response = await getApi(action);
    return response as any;
  }
})();
