import { Module, VuexModule, Mutation, Action, getModule, MutationAction } from 'vuex-module-decorators';
import store from '@/store';
import api from "@/store/api/commonApi";
import { Action as a } from "@/store/actionType";
import { FAQ } from '../models/faq';
import { duration } from 'moment';

@Module({ name: 'faqStore', dynamic: true, store })
class FAQModule extends VuexModule {

    public faqList: FAQ[] = [];
    public faq: FAQ = {} as FAQ;

    @MutationAction
    async loadAllFAQ() {
        //async loadAllFAQ(filter:any){
        //console.log(filter);
        const data = await api.get(a.getAllFAQ);
        for (var i = 0; i < data.length; i++) {
            if (data[i].Question.length > 200) {
                data[i].Question = data[i].Question.slice(0, 200).concat("......");
            }
            if (data[i].Answer.length > 200) {
                data[i].Answer = data[i].Answer.slice(0, 200).concat("......");
            }
        }
        return { faqList: data as FAQ[] };
    }

    @MutationAction
    async saveFAQ() {
        const data = this.state as FAQModule;
        const res = await api.post(a.saveFAQ, data.faq);
        return { faq: res as FAQ };
    }

    @MutationAction
    async getFAQById(id: any) {
        var res = await api.get(a.getFAQById + "/" + id);
        return { faq: res as FAQ };
    }

    @Mutation
    async resetField() {
        this.faq = {} as any;
        return { faq: this.faq as FAQ };
    }

    @MutationAction
    async deleteFAQ(id: any) {
        const data = this.state as FAQModule;
        var index = data.faqList.findIndex(x => x.Id === id);
        if (index >= 0) {
            data.faqList.splice(index, 1);
            
        }
        await api.post(a.deleteFAQ + "?id=" + id, "");

        return {};
    }
}

export default getModule(FAQModule);
