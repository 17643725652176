
import Vue from 'vue';
import Vuex from 'vuex';
import { Module, VuexModule, Mutation, Action, getModule, MutationAction } from 'vuex-module-decorators';
import store from '@/store';
import { District, Palika, PoliceUser } from "@/store/models/meta";

import { UINotifciation, Incident, Patrolling, IncidentMessage } from "@/store/models/incident";
import helper from "@/utils/helpers"
import api from "@/store/api/meta";
import incident from './incident';
import moment from 'moment'
import { IncidentRouter,ComplainRouter } from "@/utils/routePathContsant"
import { MessagingModule } from "@/store/modules";




@Module({ name: 'notificationStore', dynamic: true, store })
class NotificationModule extends VuexModule {
    public notifications: UINotifciation[] = []
    public activeNotification: any = null

    @MutationAction
    async setActiveNotification(e: any) {
        return { activeNotification: e }
    }

    @MutationAction
    async addIncidentNotification(incidents: Incident[]) {
        
        var obj = this.state as NotificationModule;
        var _notififcations = obj.notifications;
        
        for (var i = 0; i < incidents.length; i++) {
            const incident = incidents[i];
            // const m = moment(incident.HistoryDatetime).fromNow();
            const notification = {
                CategoryName: incident.CategoryName,
                 CreatedAt: incident.HistoryDatetime, 
                 Id: incident.IncidentId,
                Title: incident.Description + " has been " + incident.Status,
                 Type: "incident", RoutePath: IncidentRouter.IncidentDashboard,
                Status:incident.Status

            } as UINotifciation

            const index = _notififcations.findIndex(x => x.Id === incident.IncidentId)
            if (index > -1) {
                _notififcations.splice(index, 1);
            }
           
            _notififcations.push(notification);
        }

        return { notifications: _notififcations }
    }
    @MutationAction
    async addComplainNotification(incidents: Incident[]) {
        var obj = this.state as NotificationModule;
        var _notififcations = obj.notifications;
        
        for (var i = 0; i < incidents.length; i++) {
            const incident = incidents[i];
            // const m = moment(incident.HistoryDatetime).fromNow();
            const notification = {
                CategoryName: incident.CategoryName,
                 CreatedAt: incident.HistoryDatetime, 
                 Id: incident.IncidentId,
                Title: incident.Description + " has been " + incident.Status,
                 Type: "complain", RoutePath: ComplainRouter.ComplainDashboard,
                Status:incident.Status

            } as UINotifciation
            console.log(notification);
            const index = _notififcations.findIndex(x => x.Id === incident.IncidentId)
            if (index > -1) {
                _notififcations.splice(index, 1);
            }
           
            _notififcations.push(notification);
        }

        return { notifications: _notififcations }
    }
    @MutationAction
    async addPatrollNotification(patrollings: Patrolling[]) {
        var obj = this.state as NotificationModule;
        var _notififcations = obj.notifications;
        /*
        for (var i = 0; i < patrollings.length; i++) {
            const patroll = patrollings[i];

            const notification = {
                CategoryName: "", CreatedAt: patroll.StartDatetime, Id: patroll.PatrollingId,
                Title: patroll.WalkietalkieNumber, Type: "patroll",
                RoutePath: IncidentRouter.IncidentDashboard
            } as UINotifciation

            const index = _notififcations.findIndex(x => x.Id === patroll.PatrollingId)
            if (index > -1) {
                _notififcations.splice(index, 1);
            }
            _notififcations.push(notification);
        }


*/
        return { notifications: _notififcations }
    }

    @MutationAction
    async removeNotification(id: string) {
        var obj = this.state as NotificationModule;
        var _notififcations = obj.notifications;
        const index = _notififcations.findIndex(x => x.Id === id)
        if (index > -1) {
            _notififcations.splice(index, 1);
        }

        return { notifications: _notififcations }
    }

    @MutationAction
    async addMessageNotification(msg: IncidentMessage) {

        var obj = this.state as NotificationModule;
        var _notififcations = obj.notifications;

        const flg = _notififcations.filter(x => x.Id == msg.IncidentId).length == 0;
        const flg2 = MessagingModule.selectedIncidentId == msg.IncidentId && MessagingModule.isMessageBoxActive

        if (!flg2 && flg) {
            let m = "New message from " + msg.UserName;
            const notification = {
                CategoryName: msg.Message, CreatedAt: msg.CreatedAt, Id: msg.IncidentId,
                Title: m, Type: "message", RoutePath: IncidentRouter.IncidentDashboard
            } as UINotifciation
            _notififcations.push(notification);

        }

        return { notifications: _notififcations }
    }


}


export default getModule(NotificationModule);