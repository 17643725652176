import store from "@/store";
import {
    Module,
    VuexModule,
    Mutation,
    MutationAction,
    Action,
    getModule
} from "vuex-module-decorators";
import api from "@/store/api/report";
import { IncidentReportByStatus, IncidentReportByCategory, IncidentReportByWeekly } from '../models/report';
import { Action as ApiAction } from '@/store/actionType'
import report from '@/store/api/report';
import { Incident } from '../models/incident';
import * as d3 from "d3";
import helpers from '@/utils/helpers';




@Module({ name: 'report', dynamic: true, store })
class ReportModule extends VuexModule {
    fromDate: Date = new Date();
    toDate: Date = new Date();
    incidents: Incident[] = [];
    complaints: Incident[] = [];
    keyValueIncidents: any[] = []
    weeklyIncidentData: any[] = []
    reportByStatus: IncidentReportByStatus[] = []
    reportByCategory: IncidentReportByCategory[] = []
    reportByWeekly: IncidentReportByWeekly[] = []

    @MutationAction
    async getIncidents(param: any) {
        let data = await api.getIncidentReport(ApiAction.getIncidentReportByUser, param)
        let incidents = data.Incidents as Incident[]
        let fromDate = new Date(data.FromDate);
        let toDate = new Date(data.ToDate);

        let kv = d3
            .nest()
            .key(function (d: any) {
                return d.CategoryName;
            })
            .rollup(function (v: any) {
                return v.length;
            })
            .entries(incidents)
            .map(x => {
                return { y: x.value, name: x.key };
            });




        return { incidents: incidents, keyValueIncidents: kv, fromDate: fromDate, toDate: toDate };
    }
    @Mutation
    setWeeklyData(toDate: Date) {
        let weeklyIncidents: any[] = [];;


        //  let toDate = d3.max(this.incidents, x => new Date(x.CreatedDate)) || new Date();
        let fromDate = helpers.subtractDate(toDate, 7);


        weeklyIncidents = this.incidents.filter(x => new Date(x.CreatedDate) >= fromDate && new Date(x.CreatedDate) <= toDate);

        weeklyIncidents = d3.nest()
            .key(function (d: any) {
                return new Date(d.CreatedDate).toDateString()
            })
            .rollup(function (v: any) {
                return v.length;
            })
            .entries(weeklyIncidents)
            .map(x => {
                return { y: x.value, name: x.key };
            });

        weeklyIncidents.sort(function (a: any, b: any) {
            let a1 = new Date(a.name);
            let b1 = new Date(b.name);
            if (a1 < b1) {
                return -1;
            }
            if (a1 > b1) {
                return 1;
            }
            return 0;
        })
        this.weeklyIncidentData = weeklyIncidents;
    }
    @MutationAction
    async getComplains(param: any) {

        let data = await api.getComplainReport(ApiAction.getComplainReportByUser, param)

        let complains = data.Incidents as Incident[]
        let fromDate = new Date(data.FromDate);
        let toDate = new Date(data.ToDate);

        let kv = d3
            .nest()
            .key(function (d: any) {
                return d.CategoryName;
            })
            .rollup(function (v: any) {
                return v.length;
            })
            .entries(complains)
            .map(x => {
                return { y: x.value, name: x.key };
            });




        return { complaints: complains, keyValueIncidents: kv, fromDate: fromDate, toDate: toDate };
    }
    @Mutation
    setWeeklyDataComplains(toDate: Date) {
        let weeklyIncidents: any[] = [];;


        //  let toDate = d3.max(this.incidents, x => new Date(x.CreatedDate)) || new Date();
        let fromDate = helpers.subtractDate(toDate, 7);


        weeklyIncidents = this.complaints.filter(x => new Date(x.CreatedDate) >= fromDate && new Date(x.CreatedDate) <= toDate);

        weeklyIncidents = d3.nest()
            .key(function (d: any) {
                return new Date(d.CreatedDate).toDateString()
            })
            .rollup(function (v: any) {
                return v.length;
            })
            .entries(weeklyIncidents)
            .map(x => {
                return { y: x.value, name: x.key };
            });

        weeklyIncidents.sort(function (a: any, b: any) {
            let a1 = new Date(a.name);
            let b1 = new Date(b.name);
            if (a1 < b1) {
                return -1;
            }
            if (a1 > b1) {
                return 1;
            }
            return 0;
        })
        this.weeklyIncidentData = weeklyIncidents;
    }
}

export default getModule(ReportModule);