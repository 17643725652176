import { AdminRouter, RootRouter } from "@/utils/routePathContsant";

const admin = {
  path: AdminRouter.Root,
  name: "admin",
  component: () => import("@/views/shared/Layout.vue"),
  children: [
    {
      path: AdminRouter.Root,
      component: () => import("@/views/admin/index.vue"),
      meta: {
        // role:'admin',
        requiresAuth: true,
        allowedRoles: [
          "admin",
          "dotm_staff",
          "dotm_patake",
          "police",
          "operational_staff",
          "vehicle_owner",
          "gps_service_provider",
        ],
      },
    },
    {
      path: AdminRouter.Profile,
      component: () => import("@/views/admin/profile/detail.vue"),
      meta: {
        // role:'admin',
        requiresAuth: true,
        allowedRoles: [
          "admin",
          "dotm_staff",
          "dotm_patake",
          "police",
          "operational_staff",
          "vehicle_owner",
          "gps_service_provider",
        ],
      },
    },
    // {
    //   path: AdminRouter.ProfileEdit,
    //   component: () => import("@/views/admin/profile/edit.vue"),
    //   meta: {
    //     // role:'admin',
    //     requiresAuth: true,
    //     allowedRoles: [
    //       "admin",
    //       "dotm_staff",
    //       "police",
    //       "operational_staff",
    //       "vehicle_owner",
    //       "gps_service_provider",
    //     ],
    //   },
    // },
    // {
    //   path: AdminRouter.NewsList,
    //   component: () => import("@/views/admin/news/list.vue"),
    //   meta: {
    //     role: "admin",
    //     requiresAuth: true,
    //   },
    // },

    // {
    //   path: AdminRouter.NewsCreate,
    //   component: () => import("@/views/admin/news/create.vue"),
    //   meta: {
    //     role: "admin",
    //     requiresAuth: true,
    //   },
    // },

    // {
    //   path: AdminRouter.NewsDetail,
    //   component: () => import("@/views/admin/news/detail.vue"),
    //   meta: {
    //     role: "admin",
    //     requiresAuth: true,
    //   },
    // },
    {
      path: AdminRouter.UserList,
      component: () => import("@/views/users/list.vue"),
      meta: {
        // role:'admin',
        requiresAuth: true,
        allowedRoles: ["admin", "dotm_staff"],
      },
    },
    {
      path: AdminRouter.PublicUserList,
      component: () => import("@/views/users/publicUsers.vue"),
      meta: {
        // role:'admin',
        requiresAuth: true,
        allowedRoles: ["admin", "dotm_staff"],
      },
    },
    {
      path: AdminRouter.PublicUserDetail,
      component: () => import("@/views/users/publicUserDetail.vue"),
      meta: {
        // role:'admin',
        requiresAuth: true,
        allowedRoles: ["admin", "dotm_staff"],
      },
    },
    {
      path: AdminRouter.VehicleUserList,
      component: () => import("@/views/users/vehicleOwnerUsers.vue"),
      meta: {
        // role:'admin',
        requiresAuth: true,
        allowedRoles: ["admin", "dotm_staff"],
      },
    },
    {
      path: AdminRouter.UserRegister,
      component: () => import("@/views/users/create.vue"),
      meta: {
        // role:'admin',
        requiresAuth: true,
        allowedRoles: ["admin", "dotm_staff"],
      },
    },
    {
      path: AdminRouter.History,
      name: "HistoryMap",
      component: () => import("@/views/history/history.vue"),
      meta: {
        requiresAuth: true,
        allowedRoles: ["admin", "dotm_staff", "vehicle_owner", "police"],
      },
    },
    {
      path: AdminRouter.VehilceList,
      component: () => import("@/views/registrations/vehicle/list.vue"),
      meta: {
        // role: "admin",
        requiresAuth: true,
        allowedRoles: ["vehicle_owner"],
      },
    },
    {
      name: "vehicles",
      path: AdminRouter.AllVehicleList,
      component: () =>
        import("@/views/registrations/vehicle/allVehicleList.vue"),
      meta: {
        // role: "admin",
        requiresAuth: true,
        allowedRoles: ["admin", "dotm_staff"],
      },
    },
    {
      path: AdminRouter.VehicleRegister,
      component: () => import("@/views/registrations/vehicle/create.vue"),
      meta: {
        // role: "admin",
        requiresAuth: true,
        allowedRoles: ["vehicle_owner"],
      },
    },
    {
      path: AdminRouter.VehicleApproval,
      component: () => import("@/views/registrations/vehicle/vehicleList.vue"),
      meta: {
        // role: "admin",
        requiresAuth: true,
        allowedRoles: ["admin", "dotm_staff"],
      },
    },
    {
      name: "vehicle_detail",
      path: AdminRouter.VehicleDetails,
      component: () => import("@/views/registrations/vehicle/details.vue"),
      meta: {
        // role: "admin",
        requiresAuth: true,
        allowedRoles: ["admin", "dotm_staff", "vehicle_owner"],
      },
    },
    {
      path: AdminRouter.VehicleRouteChange,
      component: () => import("@/views/registrations/vehicle/changeRoute.vue"),
      meta: {
        // role: "admin",
        requiresAuth: true,
        allowedRoles: ["admin", "dotm_staff"],
      },
    },
    {
      path: AdminRouter.DriverList,
      component: () => import("@/views/registrations/driver/list.vue"),
      meta: {
        // role: "admin",
        requiresAuth: true,
        allowedRoles: ["admin", "dotm_staff", "vehicle_owner"],
      },
    },
    {
      path: AdminRouter.OwnerDriverList,
      component: () =>
        import("@/views/registrations/driver/ownerDriverList.vue"),
      meta: {
        // role: "admin",
        requiresAuth: true,
        allowedRoles: ["admin", "dotm_staff"],
      },
    },
    {
      path: AdminRouter.DriverRegister,
      component: () => import("@/views/registrations/driver/create.vue"),
      meta: {
        // role: "admin",
        requiresAuth: true,
        allowedRoles: ["admin", "dotm_staff", "vehicle_owner"],
      },
    },
    {
      path: AdminRouter.DriverDetail,
      component: () => import("@/views/registrations/driver/detail.vue"),
      meta: {
        // role: "admin",
        requiresAuth: true,
        allowedRoles: ["admin", "dotm_staff", "vehicle_owner"],
      },
    },
    {
      path: AdminRouter.GPSDeviceList,
      component: () => import("@/views/gpsServiceProvider/listDevice.vue"),
      meta: {
        //role: "admin",
        requiresAuth: true,
        allowedRoles: [
          "admin",
          "dotm_staff",
          "gps_service_provider",
          "vehicle_owner",
        ],
      },
    },
    {
      path: AdminRouter.GPSDeviceRegister,
      component: () => import("@/views/gpsServiceProvider/createDevice.vue"),
      meta: {
        //role: "admin",
        requiresAuth: true,
        allowedRoles: ["gps_service_provider"],
      },
    },
    {
      path: AdminRouter.GPSList,
      component: () => import("@/views/gpsServiceProvider/mainList.vue"),
      meta: {
        //role: "admin",
        requiresAuth: true,
        allowedRoles: ["admin", "dotm_staff", "gps_service_provider"],
      },
    },

    {
      path: AdminRouter.GPSServiceList,
      component: () => import("@/views/gpsServiceProvider/list.vue"),
      meta: {
        //role: "admin",
        requiresAuth: true,
        allowedRoles: ["admin", "dotm_staff", "vehicle_owner"],
      },
    },
    {
      path: AdminRouter.GPSServiceRegister,
      component: () => import("@/views/gpsServiceProvider/create.vue"),
      meta: {
        // role: "gps_service_provider",
        requiresAuth: true,
        allowedRoles: ["gps_service_provider"],
      },
    },
    {
      name: "gps_provider_detail",
      path: AdminRouter.GPSServiceDetail,
      component: () => import("@/views/gpsServiceProvider/detail.vue"),
      meta: {
        //role: "admin",
        requiresAuth: true,
        allowedRoles: ["admin", "dotm_staff", "vehicle_owner"],
      },
    },
    {
      name: "gps_model_detail",
      path: AdminRouter.GPSDeviceDetail,
      component: () => import("@/views/gpsServiceProvider/deviceDetail.vue"),
      meta: {
        //role: "admin",
        requiresAuth: true,
        allowedRoles: [
          "admin",
          "dotm_staff",
          "gps_service_provider",
          "vehicle_owner",
        ],
      },
    },
    {
      path: AdminRouter.VehicleScheduleList,
      component: () => import("@/views/vechicleSchedule/list.vue"),
      meta: {
        //role: "admin",
        requiresAuth: true,
        allowedRoles: ["admin", "dotm_staff", "vehicle_owner"],
      },
    },
    {
      path: AdminRouter.VehicleScheduleCreate,
      component: () => import("@/views/vechicleSchedule/create.vue"),
      meta: {
        // role: "admin",
        requiresAuth: true,
        allowedRoles: ["vehicle_owner"],
      },
    },
    {
      path: AdminRouter.VehicleEntryList,
      component: () => import("@/views/oneTimeVehicleEntry/list.vue"),
      meta: {
        //role: "admin",
        requiresAuth: true,
        allowedRoles: ["admin", "dotm_staff", "dotm_patake"],
      },
    },
    {
      path: AdminRouter.VehicleEntryCreate,
      component: () => import("@/views/oneTimeVehicleEntry/create.vue"),
      meta: {
        //role: "admin",
        requiresAuth: true,
        allowedRoles: ["admin", "dotm_staff", "dotm_patake"],
      },
    },
    {
      path: AdminRouter.VehicleEntryDetail,
      component: () => import("@/views/oneTimeVehicleEntry/detail.vue"),
      meta: {
        requiresAuth: true,
        allowedRoles: ["admin", "dotm_staff", "dotm_patake"],
      },
    },
    {
      path: AdminRouter.NewsFeed,
      component: () => import("@/views/newsFeed/list.vue"),
      meta: {
        //role: "admin",
        requiresAuth: true,
        allowedRoles: ["admin", "dotm_staff", "police", "operational_staff"],
      },
    },
    {
      path: AdminRouter.CreateNewsFeed,
      component: () => import("@/views/newsFeed/create.vue"),
      meta: {
        //role: "admin",
        requiresAuth: true,
        allowedRoles: ["admin", "dotm_staff", "police", "operational_staff"],
      },
    },
    {
      path: AdminRouter.Route,
      component: () => import("@/views/route/index.vue"),
      meta: {
        //role: "admin",
        requiresAuth: true,
        allowedRoles: ["admin", "dotm_staff", "police", "operational_staff"],
      },
    },
    {
      path: AdminRouter.RouteInfo,
      component: () => import("@/views/routeinfo/list.vue"),
      meta: {
        //role: "admin",
        requiresAuth: true,
        allowedRoles: ["admin", "dotm_staff", "police", "operational_staff"],
      },
    },
    {
      path: AdminRouter.RouteDetail,
      component: () => import("@/views/routeinfo/detail.vue"),
      meta: {
        //role: "admin",
        requiresAuth: true,
        allowedRoles: ["admin", "dotm_staff", "police", "operational_staff"],
      },
    },
    {
      path: AdminRouter.CreateRouteInfo,
      component: () => import("@/views/routeinfo/create.vue"),
      meta: {
        //role: "admin",
        requiresAuth: true,
        allowedRoles: ["admin", "dotm_staff", "police", "operational_staff"],
      },
    },
    {
      path: AdminRouter.AddStopToRoute,
      component: () => import("@/views/routeinfo/addStop.vue"),
      meta: {
        //role: "admin",
        requiresAuth: true,
        allowedRoles: ["admin", "dotm_staff", "police", "operational_staff"],
      },
    },
    {
      path: AdminRouter.AddRoadLink,
      component: () => import("@/views/routeinfo/addRoadLink.vue"),
      meta: {
        //role: "admin",
        requiresAuth: true,
        allowedRoles: ["admin", "dotm_staff", "police", "operational_staff"],
      },
    },
    {
      path: AdminRouter.SpeedSection,
      component: () => import("@/views/speedSection/list.vue"),
      meta: {
        //role: "admin",
        requiresAuth: true,
        allowedRoles: ["admin", "dotm_staff", "police", "operational_staff"],
      },
    },
    {
      path: AdminRouter.CreateSpeedSection,
      component: () => import("@/views/speedSection/create.vue"),
      meta: {
        //role: "admin",
        requiresAuth: true,
        allowedRoles: ["admin", "dotm_staff", "police", "operational_staff"],
      },
    },
    {
      path: AdminRouter.Stop,
      component: () => import("@/views/stoppageinfo/list.vue"),
      meta: {
        //role: "admin",
        requiresAuth: true,
        allowedRoles: ["admin", "dotm_staff", "police", "operational_staff"],
      },
    },
    {
      path: AdminRouter.CreateStop,
      component: () => import("@/views/stoppageinfo/create.vue"),
      meta: {
        //role: "admin",
        requiresAuth: true,
        allowedRoles: ["admin", "dotm_staff", "police", "operational_staff"],
      },
    },
    {
      path: AdminRouter.RouteStop,
      component: () => import("@/views/routestop/list.vue"),
      meta: {
        //role: "admin",
        requiresAuth: true,
        allowedRoles: ["admin", "dotm_staff", "police", "operational_staff"],
      },
    },
    {
      path: AdminRouter.CreateRouteStop,
      component: () => import("@/views/routestop/create.vue"),
      meta: {
        //role: "admin",
        requiresAuth: true,
        allowedRoles: ["admin", "dotm_staff", "police", "operational_staff"],
      },
    },
    {
      path: AdminRouter.RoadLink,
      component: () => import("@/views/roadlink/list.vue"),
      meta: {
        //role: "admin",
        requiresAuth: true,
        allowedRoles: ["admin", "dotm_staff", "police", "operational_staff"],
      },
    },
    {
      path: AdminRouter.CreateRoadLink,
      component: () => import("@/views/roadlink/create.vue"),
      meta: {
        //role: "admin",
        requiresAuth: true,
        allowedRoles: ["admin", "dotm_staff", "police", "operational_staff"],
      },
    },
    {
      path: AdminRouter.RouteRoad,
      component: () => import("@/views/routeroad/list.vue"),
      meta: {
        //role: "admin",
        requiresAuth: true,
        allowedRoles: ["admin", "dotm_staff", "police", "operational_staff"],
      },
    },
    {
      path: AdminRouter.CreateRouteRoad,
      component: () => import("@/views/routeroad/create.vue"),
      meta: {
        //role: "admin",
        requiresAuth: true,
        allowedRoles: ["admin", "dotm_staff", "police", "operational_staff"],
      },
    },
    {
      path: AdminRouter.incidentCategoryList,
      component: () => import("@/views/admin/incidentcategory/list.vue"),
      meta: {
        role: "admin",
        requiresAuth: true,
      },
    },
    {
      path: AdminRouter.incidentCategoryCreate,
      component: () =>
        import("@/views/admin/incidentcategory/incidentCategories.vue"),
      meta: {
        role: "admin",
        requiresAuth: true,
      },
    },
    {
      path: AdminRouter.VehicleOwnerList,
      component: () => import("@/views/registrations/ownership/list.vue"),
      meta: {
        requiresAuth: true,
        allowedRoles: ["admin", "dotm_staff"],
      },
    },
    {
      name: "company_detail",
      path: AdminRouter.VehicleOwnerDetails,
      component: () => import("@/views/registrations/ownership/details.vue"),
      meta: {
        requiresAuth: true,
        allowedRoles: ["admin", "dotm_staff"],
      },
    },
    {
      path: AdminRouter.General,
      component: () => import("@/views/admin/AdminGeneral.vue"),
      meta: {
        requiresAuth: true,
        allowedRoles: [
          "admin",
          "dotm_staff",
          "vehicle_owner",
          "operational_staff",
          "police",
          "gps_service_provider",
        ],
      },
    },
    {
      path: AdminRouter.ComplainGeneral,
      component: () => import("@/views/admin/AdminComplainGeneral.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: AdminRouter.IncidentReportByData,
      component: () => import("@/views/admin/IncidentReportByData.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: AdminRouter.ComplainReportByData,
      component: () => import("@/views/admin/ComplainReportByData.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: AdminRouter.FAQList,
      component: () => import("@/views/admin/faq/list.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: AdminRouter.FAQCreate,
      component: () => import("@/views/admin/faq/create.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: AdminRouter.FAQDetail,
      component: () => import("@/views/admin/faq/detail.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: AdminRouter.feedBackLst,
      component: () => import("@/views/admin/feedback/list.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: AdminRouter.noticeList,
      component: () => import("@/views/admin/notice/list.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: AdminRouter.noticeCreate,
      component: () => import("@/views/admin/notice/create.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: AdminRouter.EmailCreate,
      component: () => import("@/views/emailSetup/edit.vue"),
      meta: {
        // role:'admin',
        requiresAuth: true,
        allowedRoles: ["admin", "dotm_staff"],
      },
    },
    {
      path: AdminRouter.TrafficJam,
      component: () => import("@/views/trafficJam/jam.vue"),
      meta: {
        // role:'admin',
        requiresAuth: true,
        allowedRoles: ["admin", "dotm_staff"],
      },
    },
    {
      path: AdminRouter.Chat,
      component: () => import("@/views/chat/chat.vue"),
      meta: {
        // role:'admin',
        requiresAuth: true,
        allowedRoles: ["admin", "dotm_staff", "operational_staff"],
      },
    },
    {
      path: AdminRouter.HistoryReport,
      component: () => import("@/views/reporting/historyReporting.vue"),
      meta: {
        requiresAuth: true,
      },
    },
  ],
};

export default admin;
