import store from "@/store";
import {
  Module,
  VuexModule,
  Mutation,
  MutationAction,
  Action,
  getModule,
} from "vuex-module-decorators";
import api from "@/store/api/public";
import {
  Ownership,
  profile,
  PublicUsers,
  vechicleOwner,
} from "../models/registration/registration";
import helpers from "@/utils/helpers";

@Module({ name: "public", dynamic: true, store })
class PublicModule extends VuexModule {
  public public: PublicUsers = {} as PublicUsers;
  public profile: profile = {} as profile;
  public publicList: PublicUsers[] = [];

  @Mutation
  async resetField() {
    this.public = {
      profile: {} as profile,
    } as PublicUsers;
    this.profile = {} as any;
    return {
      public: this.public as PublicUsers,
      profile: this.profile as profile,
    };
  }

  // @MutationAction
  // async loadAllPublicUsers() {
  //   const data = await api.getAllPublicUsers();
  //   console.log(data);
  //   return { publicList: data as PublicUsers[] };
  // }

  @MutationAction
  async createPublicUser() {
    const o = this.state as PublicModule;

    var formData = new FormData();
    o.public.profile.userType = "public";
    let data = helpers.getNestedFormData(formData, o.public);

    if (o.public.id) {
      console.log(o.public.id);
      formData.append("Id", o.public.id as any);
      var result = await api.updatePublicUsers(data);
    } else {
      var result = await api.createPublicUsers(data);
    }
    return {};
  }

  @MutationAction
  async getPublicUserById(id: any) {
    const data = await api.getPublicUserById(id);

    return { public: data as PublicUsers };
  }
}

export default getModule(PublicModule);
