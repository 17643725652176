import {
  postApi,
  postApiWithoutToken,
  getApi,
  getApiWithoutLoader,
  postApiWithoutLoader,
} from "@/store/api/api";
import { Action } from "@/store/actionType";
import { Register, UserLoginRequest } from "@/store/models/auth";

export default new (class AuthAPI {
  public async userLogin(login: any): Promise<any> {
    const action = Action.login;
    const response = await postApi(action, login, {
      withCredentials: true,
    });
    return response as any;
  }

  public async facebookLogin(login: any): Promise<any> {
    const action = Action.facebookLogin;
    const response = await postApi(action, login, {
      withCredentials: true,
    });
    return response as any;
  }

  public async googleLogin(login: any): Promise<any> {
    const action = Action.googleLogin;
    const response = await postApi(action, login, {
      withCredentials: true,
    });
    return response as any;
  }

  public async policeLogin(login: any): Promise<any> {
    const action = Action.login;
    const response = await postApiWithoutToken(action, login);
    return response as any;
  }

  public async registerUser(user: Register): Promise<any> {
    const action = Action.registerUser;
    const response = await postApi(action, user);
    return response as any;
  }

  public async forgotPassword(userName: any): Promise<any> {
    const action = Action.forgotPassword;
    const response = await postApiWithoutToken(action, userName);
    return response as any;
  }

  public async resetPassword(reset: any): Promise<any> {
    const action = Action.resetPassword;
    const response = await postApiWithoutToken(action, reset);
    return response as any;
  }

  public async changePassword(change: any): Promise<any> {
    const action = Action.changePassword;
    const response = await postApi(action, change);
    return response as any;
  }
})();
