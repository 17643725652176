import store from "@/store";
import {
  Module,
  VuexModule,
  Mutation,
  MutationAction,
  Action,
  getModule,
} from "vuex-module-decorators";
import api from "@/store/api/newsFeed";
import {
  NewsFeed,
  PaginatedNewsList,
  NewsCategory,
  NewsTypes,
} from "../models/newsFeed";
import helpers from "@/utils/helpers";

@Module({ name: "newsfeed", dynamic: true, store })
class NewsFeedModule extends VuexModule {
  public paginatedNewsList: PaginatedNewsList = {} as PaginatedNewsList;
  public newsList: NewsFeed[] = [];
  public thenews: NewsFeed = {} as NewsFeed;
  public newsCategoryList: NewsCategory[] = [];
  public newsTypeList: NewsTypes[] = [];
  @Mutation
  async resetField() {
    this.thenews = {} as any;

    return { thenews: this.thenews as NewsFeed };
  }

  @MutationAction
  async createNewsFeed() {
    const o = this.state as NewsFeedModule;

    var formData = new FormData();
    let data = helpers.getNestedFormData(formData, o.thenews);

    if (o.thenews.id) {
      formData.append("Id", o.thenews.id as any);
      var result = await api.updateNewsFeed(formData, o.thenews.id);
    } else {
      var result = await api.createNewsFeed(formData);
    }

    return {};
  }

  @MutationAction
  async getAllNewsFeed(params?: {
    page?: number;
    title?: string;
    newsCategory?: string;
  }) {
    let query = "";
    if (params) {
      query += "?";
      if (params.page) {
        query += `&page=${params.page}`;
      }
      if (params.title) {
        query += `&search=${params.title}`;
      }
      if (params.newsCategory) {
        query += `&news_category=${params.newsCategory}`;
      }
    }
    const d = await api.getAllNewsFeed(query);
    const data: any = d;
    return { paginatedNewsList: data as PaginatedNewsList };
  }

  @MutationAction
  async loadNewsFeedById(id: any) {
    var datas = await api.getNewsFeedById(id);

    return { thenews: datas as NewsFeed };
  }

  @MutationAction
  async deleteNewsFeed(news: NewsFeed) {
    const o = this.state as NewsFeedModule;
    if (news.id !== undefined) {
      var i = o.newsList.findIndex((x) => x.id == news.id);

      if (i >= 0) {
        o.newsList.splice(i, 1);
      }
    } else {
      var i = o.newsList.findIndex((x) => x.id == news.id);

      if (i >= 0) {
        o.newsList.splice(i, 1);
      }
    }

    const data = await api.deleteNewsFeed(news);
    return {};
  }

  @MutationAction
  async getAllNewsFeedCategory() {
    const d = await api.getAllNewsFeedCategory();
    const data: any = d;
    return { newsCategoryList: data as NewsCategory[] };
  }

  @MutationAction
  async getAllNewsFeedTypes() {
    const d = await api.getAllNewsFeedTypes();
    const data: any = d;
    return { newsTypeList: data as NewsTypes[] };
  }
}

export default getModule(NewsFeedModule);
