import {
  postApi,
  getApi,
  getApiWithoutLoader,
  postApiWithoutLoader,
  patchApi,
} from "@/store/api/api";
import { Action } from "@/store/actionType";
import helpers from "@/utils/helpers";

export default new (class ChatAPI {
  public async saveMessage(obj: any): Promise<any> {
    const action = Action.saveChatMessage;
    var user = helpers.getUserProfile();
    const response = await postApi(action, obj);

    return response as any;
  }

  public async getChatMessage(query: any): Promise<any> {
    const action = Action.getUserChatMessage + query;
    const response = await getApi(action);
    return response as any;
  }
  public async getlatestChatList(): Promise<any> {
    const action = Action.getLatestChatMessage;
    const response = await getApi(action);

    return response as any;
  }

  public async getChats(query = ""): Promise<any> {
    const action = Action.getChatMessage + query;
    const response = await getApi(action);
    return response as any;
  }

  public async updateLastSeen(id: any): Promise<any> {
    const action = Action.updateLastSeen + "?user_id=" + id;
    const response = await patchApi(action, id);
    return response as any;
  }
})();
