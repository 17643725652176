import store from "@/store";
import {
    Module,
    VuexModule,
    Mutation,
    MutationAction,
    Action,
    getModule
} from "vuex-module-decorators";
import api from "@/store/api/route";
import { Route, RoadSection } from "@/store/models/route";

@Module({ name: "routeStore", dynamic: true, store })
class RouteModule extends VuexModule {
    public RightDashLoader = false

    public lat: number = 27.667320820054307;
    public lng: number = 85.32310303037195;

    public routeSource: Route[] = [];
    public roadSection: RoadSection[] = [];
   
    @MutationAction
    async loadRoute() {
        const result = await api.getRouteGeoJson();
        return { routeSource: result as Route[] }
    }

    @MutationAction
    async loadRoadSection() {
        const result = await api.getRouteGeoJson();
        return { roadSection: result.features }
    }
}

export default getModule(RouteModule);
