import store from "@/store";
import {
  Module,
  VuexModule,
  Mutation,
  MutationAction,
  Action,
  getModule,
} from "vuex-module-decorators";
import api from "@/store/api/ownership";
import {
  Ownership,
  profile,
  vechicleOwner,
  OwnershipSearchResult,
  OwnershipPaginatedResult,
  VehicleOwnerCount,
  TransportCompanyCSV,
} from "../models/registration/registration";
import helpers from "@/utils/helpers";

@Module({ name: "ownership", dynamic: true, store })
class OwnershipModule extends VuexModule {
  public ownership: Ownership = {} as Ownership;
  public profile: profile = {} as profile;
  public vehicleOwner: vechicleOwner = {} as any;
  public ownershipList: Ownership[] = [];
  public paginatedOwnershipList: OwnershipPaginatedResult = {} as OwnershipPaginatedResult;
  public ownerCount: VehicleOwnerCount = {} as VehicleOwnerCount;
  // public ownerCSV: TransportCompanyCSV[] = [];
  @Mutation
  async resetField() {
    this.ownership = {
      profile: {} as profile,
      vehicleOwner: {} as any,
    } as Ownership;
    this.profile = {} as any;
    this.vehicleOwner = {} as any;
    return {
      ownership: this.ownership as Ownership,
      profile: this.profile as profile,
      vehicleOwner: this.vehicleOwner as any,
    };
  }

  @MutationAction
  async loadAllOwner(status: OwnershipSearchResult) {
    let query = "?";

    if (status.page) {
      query += `&page=${status.page}`;
    }
    if (status.page_size) {
      query += `&page_size=${status.page_size}`;
    }
    if (status.profile__approval_status) {
      query += `&profile__approval_status=${status.profile__approval_status}`;
    }
    if (status.profile__district) {
      query += `&profile__district=${status.profile__district}`;
    }
    if (status.profile__palika) {
      query += `&profile__palika=${status.profile__palika}`;
    }
    if (status.name) {
      query += `&name=${status.name}`;
    }

    const data = await api.getAllOwnership(query);
    return { paginatedOwnershipList: data as OwnershipPaginatedResult };
  }

  @MutationAction
  async getVehicleOwnerCount() {
    const data = await api.getVehicleOwnerCount();
    return { ownerCount: data as VehicleOwnerCount };
  }

  // @MutationAction
  // async getVehicleOwnerCSV() {
  //   const data = await api.getVehicleOwnerCSV();

  //   return { ownerCSV: data as TransportCompanyCSV[] };
  // }

  @MutationAction
  async approveOwner(id: any) {
    const data = await api.approveOwnership(id);
    return { ownership: data as Ownership };
  }

  @MutationAction
  async holdOwner(params: { id: any; remarks: string }) {
    const data = await api.holdOwnership(params);
    return { ownership: data as Ownership };
  }

  @MutationAction
  async createOwnership() {
    const o = this.state as OwnershipModule;

    var formData = new FormData();
    o.ownership.profile.userType = "vehicle_owner";

    let data = helpers.getNestedFormData(formData, o.ownership);

    if (o.ownership.id) {
      formData.append("Id", o.ownership.id as any);
      var result = await api.updateOwnership(data);
    } else {
      var result = await api.createOwnership(data);
    }
    return {};
  }

  @MutationAction
  async deleteOwnership() {
    const o = this.state as OwnershipModule;

    var formData = new FormData();
    o.ownership.profile.userType = "vehicle_owner";
    o.ownership.profile.dob = "2021-02-16";
    // o.ownership.profile.dobBs = '2050-4-21';
    o.ownership.profile.gender = "Male";
    o.ownership.profile.approvalStatus = "Deleted";

    let data = helpers.getNestedFormData(formData, o.ownership);

    if (o.ownership.id) {
      formData.append("Id", o.ownership.id as any);
      var result = await api.updateOwnership(data);
    } else {
      var result = await api.createOwnership(data);
    }
    return {};
  }

  @MutationAction
  async getOwnershipById(id: any) {
    const d = await api.getOwnershipById(id);
    const data: any = d;

    return { ownershipList: data as Ownership[] };
  }

  @MutationAction
  async getVehicleOwnerById(id: any) {
    const data = await api.getOwnershipById(id);

    return { ownership: data as Ownership };
  }
}

export default getModule(OwnershipModule);
