import {
  postApi,
  postApiWithoutToken,
  getApi,
  getApiWithoutLoader,
  postApiWithoutLoader,
  putApi,
} from "@/store/api/api";
import { Action } from "@/store/actionType";
import { Ownership } from "../models/registration/registration";

export default new (class OwnershipAPI {
  public async createOwnership(news: any): Promise<any> {
    const action = Action.createOwnership;
    const response = await postApi(action, news);

    return response as any;
  }

  public async updateOwnership(owner: any): Promise<any> {
    const action = Action.editOwnership;
    const response = await postApi(action, owner);
    return response as any;
  }

  public async approveOwnership(id: any): Promise<any> {
    const action = Action.approveVehicleOwner + id + "/approve_vehicle_owner/";
    const response = await postApi(action, id);
    return response as any;
  }

  public async holdOwnership(params: {
    id: any;
    remarks: string;
  }): Promise<any> {
    const action =
      Action.approveVehicleOwner + params.id + "/hold_vehicle_owner/";
    const response = await postApi(action, {
      profile: { remarks: params.remarks },
    });
    return response as any;
  }

  public async deleteOwnership(owner: Ownership): Promise<any> {
    if (owner.id !== undefined) {
      const action = Action.deleteNews + "?id=" + owner.id;
      const response = await postApi(action, owner);
      return response as any;
    } else {
      const action = Action.deleteNews + "?id=" + owner.id;
      const response = await postApi(action, owner);
      return response as any;
    }
  }

  public async getAllOwnership(query = ""): Promise<any> {
    const action = `${Action.getVehicleOwner}${query}`;
    const response = await getApi(action);
    return response as any;
  }

  public async getOwnershipById(id: number): Promise<any> {
    const action = `${Action.getVehicleOwner}${id}/`;
    const response = await getApi(action);
    return response as Ownership;
  }

  public async getVehicleOwnerCount(): Promise<any> {
    const action = Action.getOwnerCount;
    const response = await getApi(action);

    return response as any;
  }

  // public async getVehicleOwnerCSV(): Promise<any> {
  //   const action = Action.ownercsv;
  //   const response = await getApi(action);

  //   return response as any;
  // }
})();
