import store from "@/store";
import {
  Module,
  VuexModule,
  Mutation,
  MutationAction,
  Action,
  getModule,
} from "vuex-module-decorators";
import api from "@/store/api/vehicleSchedule";
import helpers from "@/utils/helpers";
import { CommonStore } from ".";
import { VehicleSchedule } from "../models/vechileSchedule";

@Module({ name: "vehicleSchedule", dynamic: true, store })
class VehicleScheduleModule extends VuexModule {
  public vehicleSchedule: VehicleSchedule = {} as VehicleSchedule;
  public vehicleScheduleList: VehicleSchedule[] = [];

  @Mutation
  async resetField() {
    this.vehicleSchedule = {} as any;
    return { vehicleSchedule: this.vehicleSchedule as VehicleSchedule };
  }

  @MutationAction
  async loadAllSchedule(params: {
    page: number;
    vehicle_owner?: number;
    source?: number;
    destination?: number;
    shift?: string;
  }) {
    let query = "?";
    if (params.page) {
      query += `&page=${params.page}`;
    }
    if (params.vehicle_owner) {
      query += `&vehicle_owner=${params.vehicle_owner}`;
    }
    if (params.source) {
      query += `&source=${params.source}`;
    }
    if (params.destination) {
      query += `&destination=${params.destination}`;
    }
    if (params.shift) {
      query += `&shift=${params.shift}`;
    }
    const data = await api.getVehicleSchedule(query);
    console.log(data);
    return { vehicleScheduleList: data as VehicleSchedule[] };
  }

  @MutationAction
  async createSchedule() {
    let profile = helpers.getUserProfile() as any;
    const o = this.state as VehicleScheduleModule;
    //o.vehicleSchedule.vehicleOwner = profile.userDetail.id

    var formData = new FormData();

    let data = helpers.getNestedFormData(formData, o.vehicleSchedule);
    if (o.vehicleSchedule.id) {
      formData.append("id", o.vehicleSchedule.id as any);
      var result = await api.updateVehicleSchedule(data, o.vehicleSchedule.id);
    } else {
      var result = await api.createVehicleSchedule(data);
    }

    return {};
  }

  @MutationAction
  async getScheduleById(id: any) {
    const data = await api.getVehicleScheduleById(id);
    return { vehicleSchedule: data as VehicleSchedule };
  }
}

export default getModule(VehicleScheduleModule);
