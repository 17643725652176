import Vue from "vue";
import App from "./App.vue";
import router from "@/router";
import store from "./store";
import ElementUI from "element-ui";
import locale from "element-ui/lib/locale/lang/en";
import Snotify, { SnotifyPosition } from "vue-snotify";
import { SnotifyService } from "vue-snotify/SnotifyService";
import "vue-snotify/styles/material.css";
import VueProgressBar from "vue-progressbar";
import helpers from "./utils/helpers";
import { Role } from "./utils/constants";
import "@/mixins/validator";
import { WaitUntilRefreshed } from "./utils/jwt";
import { JWTModule } from "./store/modules";
import "@/vee-validate";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";

import "bootstrap/dist/js/bootstrap.bundle.min.js";
// import 'bootstrap-vue/dist/bootstrap-vue.css';
// import moment from 'moment';
// Vue.use(moment as any);

// import InfiniteLoading from "vue-infinite-loading";

// Vue.use(InfiniteLoading, {
//   /* options */
//   system: {
//     throttleLimit: 200,
//   },
// });

Vue.use(VueProgressBar, {
  color: "#41B883",
  failedColor: "red",
  height: "2px",
  thickness: "3px",
  autoRevert: true,
  autoFinish: false,
});
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

const options = {
  toast: {
    position: SnotifyPosition.leftBottom,
  },
};
Vue.use(Snotify, options);
Vue.use(ElementUI, { locale });
Vue.config.productionTip = false;
/*
Vue.config.errorHandler = function (err, vm, info) {
  console.log(err);
  console.log(vm);
  console.log(info);
  console.log('[Global Error Handler]: Error in ' + info + ': ' + err);
};
*/
// declare module 'vue/types/vue' {
//   interface Vue {
//     $moment: any
//   }
// }
declare module "vue/types/vue" {
  interface Vue {
    $snotify: SnotifyService;
    //  $moment: any
  }
}

router.beforeEach(async (to: any, from: any, next: any) => {
  // eslint-disable-next-line
  if (to.matched.some((record: any) => record.meta.requiresAuth)) {
    // Wait if JWT is refreshing
    await WaitUntilRefreshed();
    if (JWTModule.loggedIn) {
      if (to.meta.allowedRoles && !helpers.hasRoles(to.meta.allowedRoles)) {
        next({
          name: "Home",
        });
      } else {
        next();
      }
    } else {
      next({
        name: "Home",
      });
    }
  } else {
    next();
  }
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
