import store from "@/store";
import {
  Module,
  VuexModule,
  Mutation,
  MutationAction,
  Action,
  getModule,
} from "vuex-module-decorators";
import api from "@/store/api/driver";
import {
  Ownership,
  profile,
  vechicleOwner,
  Driver,
  Documents,
  LicenseDetails,
  driverDetails,
  DriverCSV,
} from "../models/registration/registration";
import helpers from "@/utils/helpers";

@Module({ name: "driver", dynamic: true, store })
class DriverModule extends VuexModule {
  public driver: Driver = {} as Driver;

  public profile: profile = {} as profile;
  public vehicleOwner: vechicleOwner = {} as vechicleOwner;
  public documents: Documents = {} as Documents;

  public driverList: Driver[] = [];
  public licenseDetails: LicenseDetails[] = [];
  public driverDetails: driverDetails = {} as driverDetails;
  public driverCSV: DriverCSV[] = [];
  @Mutation
  async resetField() {
    this.profile = {} as any;
    this.driverDetails = {} as any;
    this.vehicleOwner = {} as any;
    // this.driver = { driverDetails: {} as driverDetails, profile: {} as profile, vehicleOwner: {} as vechicleOwner, documents: {} as Documents, licenseDetails: [] as any } as Driver;
    this.driver = {
      driver: {
        licenseDetails: [{} as LicenseDetails] as LicenseDetails[],
      } as driverDetails,
      profile: {} as profile,
    } as Driver;
    return { driver: this.driver as Driver };
  }

  @MutationAction
  async loadAllDriver(params?: {
    page?: number;
    name?: string;
    company_name?: string;
    district?: string;
    palika?: string;
    owner_id?: number;
  }) {
    let query = "";
    if (params) {
      query += "?";
      if (params.page) {
        query += `&page=${params.page}`;
      }
      if (params.name) {
        query += `&full_name=${params.name}`;
      }
      if (params.company_name) {
        query += `&driver__vehicle_owner__profile__company_name=${params.company_name}`;
      }
      if (params.district) {
        query += `&profile__district=${params.district}`;
      }
      if (params.palika) {
        query += `&profile__palika=${params.palika}`;
      }
      if (params.owner_id) {
        query += `&vehicle_owner=${params.owner_id}`;
      }
    }
    const data = await api.getDriver(query);
    return { driverList: data as Driver[] };
  }

  @MutationAction
  async getDriverCSV() {
    const data = await api.getDriverCSV();
    return { driverCSV: data as DriverCSV[] };
  }

  @Action
  async approveDriver(obj: Driver) {
    await api.activeDriver(obj);
    const data = await api.getDriver();
    return { driverList: data as Driver[] };
  }

  @MutationAction
  async createDriver() {
    const o = this.state as DriverModule;

    var formData = new FormData();
    o.driver.profile.userType = "driver";

    let data = helpers.getNestedFormData(formData, o.driver);

    if (o.driver.id) {
      formData.append("id", o.driver.id as any);
      var result = await api.updateDriver(data, o.driver.id);
    } else {
      var result = await api.createDriver(data);
    }

    return {};
  }

  @MutationAction
  async getDriverById(id: any) {
    const data = await api.getDriverById(id);
    return { driver: data as Driver };
  }

  @MutationAction
  async deleteDriver(id: any) {
    const response = await api.deleteDriver(id);
    return response as any;
  }
}

export default getModule(DriverModule);
