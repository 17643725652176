import store from "@/store";
import {
  Module,
  VuexModule,
  Mutation,
  MutationAction,
  Action,
  getModule,
} from "vuex-module-decorators";
import api from "@/store/api/jamSetup";
import helpers from "@/utils/helpers";
import { CommonStore } from ".";
import { Jam } from "../models/registration/registration";

@Module({ name: "jam", dynamic: true, store })
class TrafficJamModule extends VuexModule {
  public jam: Jam = {} as Jam;
  public jamList: Jam[] = [];
  @Mutation
  async resetField() {
    this.jam = {} as any;
    return { jam: this.jam as Jam };
  }

  @MutationAction
  async loadJam() {
    const data = await api.getJam();
    console.log(data);
    return { jamList: data as Jam[] };
  }

  @MutationAction
  async createJam() {
    const o = this.state as TrafficJamModule;
    let t = o.jam;
    var formData = new FormData();

    let data = helpers.getNestedFormData(formData, o.jam);
    await api.createJam(data);

    return {};
  }

  @MutationAction
  async getJam() {
    const data = await api.getJam();

    return { jam: data as Jam };
  }
}

export default getModule(TrafficJamModule);
