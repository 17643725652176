import {
  postApi,
  postApiWithoutToken,
  getApi,
  getApiWithoutLoader,
  postApiWithoutLoader,
  putApi,
} from "@/store/api/api";
import { Action } from "@/store/actionType";
import { Jam } from "../models/registration/registration";

export default new (class JamSetupAPI {
  public async getJam(): Promise<any> {
    const action = Action.getJam;
    const response = await getApi(action);

    return response as any;
  }
  public async createJam(entry: any): Promise<any> {
    const action = Action.createJam;
    const response = await postApi(action, entry);
    return response as any;
  }

  public async updateJam(entry: any, id: any): Promise<any> {
    const action = Action.updateJam + id + "/";
    const response = await putApi(action, entry);
    return response as any;
  }

  public async getJamById(id: number): Promise<any> {
    const action = `${Action.getJam}${id}/`;
    const response = await getApi(action);
    return response as any;
  }
})();
