import { postApi, getApi, getApiWithoutLoader, postApiWithoutLoader } from "@/store/api/api";
import { Action } from "@/store/actionType";
import helpers from '@/utils/helpers';

export default new class GPSRouteAPI {
    public async getAllRouteList(): Promise<any> {
      const action = Action.getRoutes;
      const response = await getApi(action);
      return response as any;
    }

    public async getRouteGeoJson(): Promise<any> {
      const action = Action.getRouteGeoJson;
      const response = await getApi(action);
  
      return response as any;
    }
}