import Vue from "vue";
import Vuex from "vuex";
import {
  Module,
  VuexModule,
  Mutation,
  Action,
  getModule,
  MutationAction,
} from "vuex-module-decorators";
import store from "@/store";
import {
  UserProfile,
  UserLoginRequest,
  Register,
  ForgotPassword,
  ResetPassword,
  ChangePassword,
  OauthLoginRequest,
} from "@/store/models/auth";
import helper from "@/utils/helpers";
import api from "@/store/api/auth";
import { JWTModule } from ".";

@Module({ name: "authStore", dynamic: true, store })
class AuthModule extends VuexModule {
  public lockTokenExpiredMessage: boolean = false;
  public user: Register = {} as Register;
  public profile: UserProfile = {} as UserProfile;
  public email: ForgotPassword = {} as ForgotPassword;
  public reset: ResetPassword = {} as ResetPassword;
  public change: ChangePassword = {} as ChangePassword;

  get userProfile() {
    const p = helper.getUserProfile();
    return p;
  }
  @Mutation
  setLockTokenExpiredMessage(flag: boolean) {
    this.lockTokenExpiredMessage = flag;
  }

  @Mutation
  setProfile(userProfile: UserProfile) {
    this.profile = userProfile;
  }

  @MutationAction
  async loginUser(param: UserLoginRequest) {
    const m = this.state as AuthModule;
    const data = await api.userLogin(param);
    // helper.setUserProfile(data);
    await JWTModule.setJWT(data);
    return { profile: data as UserProfile };
  }

  @MutationAction
  async facebookLogin(param: OauthLoginRequest) {
    const data = await api.facebookLogin(param);
    await JWTModule.setJWT(data);
    return { profile: data as UserProfile };
  }

  @MutationAction
  async googleLogin(param: OauthLoginRequest) {
    const data = await api.googleLogin(param);
    await JWTModule.setJWT(data);
    return { profile: data as UserProfile };
  }

  @MutationAction
  async policeLoginUser(param: UserLoginRequest) {
    const m = this.state as AuthModule;
    const data = await api.policeLogin(param);

    data.User.UserType = data.UserType;
    helper.setUserProfile(data.User);
    return { profile: data.User as UserProfile };
  }
  @MutationAction
  async policeLogoutUser() {
    helper.setUserProfile(null);
    return { profile: {} as UserProfile };
  }

  @MutationAction
  async registerUser() {
    const o = this.state as AuthModule;
    const data = await api.registerUser(o.user);
    return { user: data as Register };
  }

  @Action
  async changePassword(param: ChangePassword) {
    return await api.changePassword(param);
  }
}

export default getModule(AuthModule);
