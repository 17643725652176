import Vue from "vue";
import Router from "vue-router";
import { RootRouter } from "@/utils/routePathContsant";
// import { RootRouter as newRootRouter } from "@/utils/newRoutePathContsant";
import Incident from "@/router/incident";
import Admin from "@/router/admin";
import Public from "@/router/public";
import NewPublic from "@/router/newPublic";

Vue.use(Router);

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior(to: any, from: any, savedPosition: any) {
    return { x: 0, y: 0 };
  },

  routes: [
    {
      path: RootRouter.Policy,
      name: "Policy",
      component: () => import("@/views/shared/PolicyPage.vue"),
    },
    {
      path: RootRouter.Login,
      name: "Login",
      component: () => import("@/views/authscreen/login.vue"),
    },
    {
      path: RootRouter.SelectRegistrationType,
      name: "SelectRegistrationType",
      component: () => import("@/views/authscreen/selectRegistrationType.vue"),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: RootRouter.Register,
      component: () => import("@/views/authscreen/register.vue"),
      meta: {
        requiresAuth: false,
      },
    },

    {
      path: RootRouter.TrackVehicle,
      component: () => import("@/views/public/trackVehicle.vue"),
    },
    // Public,
    Incident,
    Admin,
    {
      path: "*",
      component: () => import("@/views/errorscreen/notfound.vue"),
    },
    // new ui
    NewPublic,
  ],
});
