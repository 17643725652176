import store from "@/store";
import {
  Module,
  VuexModule,
  Mutation,
  MutationAction,
  Action,
  getModule,
} from "vuex-module-decorators";
import api from "@/store/api/gpsServiceProvider";
import {
  profile,
  Driver,
  Documents,
  driverDetails,
  GpsProvider,
  gpsServiceProvider,
  GpsDevice,
  ActiveVehicleResult,
  GPSServiceCSV,
} from "../models/registration/registration";
import helpers from "@/utils/helpers";
import { CommonStore } from ".";

@Module({ name: "gpsProvider", dynamic: true, store })
class GPSServiceProviderModule extends VuexModule {
  public gpsProvider: GpsProvider = {} as GpsProvider;
  public gpsDevice: GpsDevice = {} as GpsDevice;
  public gpsServiceProvider: gpsServiceProvider = {} as gpsServiceProvider;
  public profile: profile = {} as profile;
  public documents: Documents = {} as Documents;
  public gpsServiceProviderList: GpsProvider[] = [];
  public gpsDeviceList: GpsDevice[] = [];
  public gpsMainList: ActiveVehicleResult[] = [];
  public vehicleOwnerOfProvider: ActiveVehicleResult[] = [];


  // public gpsCSV: GPSServiceCSV[] = [];
  @Mutation
  async resetField() {
    this.profile = {} as any;
    this.gpsProvider = {
      profile: {} as profile,
      gpsServiceProvider: {} as gpsServiceProvider,
    } as GpsProvider;
    this.gpsDevice = {} as GpsDevice;
    return {
      gpsProvider: this.gpsProvider as GpsProvider,
      gpsDevice: this.gpsDevice as GpsDevice,
    };
  }

  @MutationAction
  async loadAllGpsServiceProvider(params?: {page: number; name?: string}) {
    let query = "";
    if (params) {
      query += "?";
      if (params.page) {
        query += `&page=${params.page}`;
      }
      if(params.name){
        query += `&profile__company_name=${params.name}`;
      }
    }
    const data = await api.getGpsServiceProvider(query);
    return { gpsServiceProviderList: data as GpsProvider[] };
  }

  @MutationAction
  async loadAllMainGpsServiceProvider(params?: {
    page: number;
    name?: number;
    model?: number;
  }) {
    let query = "";
    if (params) {
      query += "?";
      if (params.page) {
        query += `&page=${params.page}`;
      }
      if(params.name){
        query += `&vehicle_owner=${params.name}`;
      }
      if(params.model){
        query += `&gps_model=${params.model}`;
      }
    }
    const data = await api.getMainGpsServiceProvider(query);
    console.log(data);
    return { gpsMainList: data as ActiveVehicleResult[] };
  }

  @MutationAction
  async loadAllAssociatedCompanyOfGpsProvider(){
    const data = await api.loadAllOwnerAssociatedWithGpsProvider()
    return {vehicleOwnerOfProvider: data as ActiveVehicleResult[]}
  }

  @MutationAction
  async loadAllGpsDevice() {
    const data = await api.getGpsDevice();
    console.log(data);
    return { gpsDeviceList: data as GpsDevice[] };
  }

  // @MutationAction
  // async getGPSServiceCSV() {
  //   const data = await api.getGPSCSV();

  //   return { gpsCSV: data as GPSServiceCSV[] };
  // }

  @MutationAction
  async createGpsServiceProvider() {
    const o = this.state as GPSServiceProviderModule;

    var formData = new FormData();
    o.gpsProvider.profile.userType = "gps_service_provider";

    let data = helpers.getNestedFormData(formData, o.gpsProvider);

    if (o.gpsProvider.id) {
      formData.append("id", o.gpsProvider.id as any);
      var result = await api.updateGpsServiceProvider(data, o.gpsProvider.id);
    } else {
      var result = await api.createGpsServiceProvider(data);
    }

    return {};
  }

  @MutationAction
  async createGpsDevice() {
    const o = this.state as GPSServiceProviderModule;

    var formData = new FormData();
    let serviceId = CommonStore.gpsServiceId;
    o.gpsDevice.gpsServiceProvider = serviceId;
    o.gpsDevice.isActive = true;
    let data = helpers.getNestedFormData(formData, o.gpsDevice);

    if (o.gpsDevice.id) {
      formData.append("id", o.gpsDevice.id as any);

      var result = await api.updateGpsDevice(data, o.gpsDevice.id);
    } else {
      console.log("create hits");
      var result = await api.createGpsDevice(data);
    }

    return {};
  }

  @MutationAction
  async getServiceProviderById(id: any) {
    const data = await api.getGpsServiceProviderById(id);
    return { gpsProvider: data as GpsProvider };
  }

  @MutationAction
  async getGpsDeviceById(id: any) {
    const data = await api.getGpsDeviceById(id);
    return { gpsDevice: data as GpsDevice };
  }

  @MutationAction
  async getGpsDeviceByServiceId(id: any) {
    const data = await api.getGpsDeviceByServiceProviderId(id);
    return { gpsDeviceList: data as GpsDevice[] };
  }

  @MutationAction
  async loadActiveGpsServiceProvider() {
    var gpsServiceList = (this.state as GPSServiceProviderModule)
      .gpsServiceProviderList;
    if (gpsServiceList.length == 0) {
      gpsServiceList = (await api.getActiveGpsServiceProvider()) as GpsProvider[];
    }

    return { gpsServiceProviderList: gpsServiceList as GpsProvider[] };
  }

  @MutationAction
  async loadGpsDevice(id: number) {
    const data = await api.getActiveGpsDevice(id);
    return { gpsDeviceList: data as GpsDevice[] };
  }

  @MutationAction
  async approve(id: any) {
    const data = await api.approve(id);
    return { gpsProvider: data as GpsProvider };
  }

  @MutationAction
  async reject(params: { id: any; remarks: string }) {
    const data = await api.reject(params);
    return { gpsProvider: data as GpsProvider };
  }

  @MutationAction
  async approveDevice(id: any) {
    const data = await api.approveDevice(id);
    return { gpsDevice: data as GpsDevice };
  }

  @MutationAction
  async rejectDevice(params: { id: any; remarks: string }) {
    const data = await api.rejectDevice(params);
    return { gpsDevice: data as GpsDevice };
  }
}

export default getModule(GPSServiceProviderModule);
