import Vue from 'vue';
import Vuex from 'vuex';
import { Module, VuexModule, Mutation, Action, getModule, MutationAction } from 'vuex-module-decorators';
import store from '@/store';
import { IncidentCategories, UserAssignedCategory } from "@/store/models/incident";
import api from "@/store/api/commonApi";
import { Action as a } from "@/store/actionType";
@Module({ name: 'incidentCategoriesStore', dynamic: true, store })
class IncidentCategoriesModule extends VuexModule {

    public incidentCategories: IncidentCategories = {} as IncidentCategories
    public incidentCategoryList: IncidentCategories[] = []
    public userAssignedCategoryList: UserAssignedCategory[] = []
    public complainIncidentCategoryList: IncidentCategories[] = []

    @Mutation
    async clearFields() {
        this.incidentCategories = {} as IncidentCategories
        return this.incidentCategories
    }
    @MutationAction
    async loadAllIncidentCategory() {
        const action = a.getIncidentExceptComplainCategory;
        const data = await api.get(action);
        
        return { incidentCategoryList: data as IncidentCategories[] }
    }



    @MutationAction
    async loadAllComplainIncidentCategory() {
        const action = a.getAllComplainSubCategory;
        const data = await api.get(action);
        
        console.log(data);
        return { complainIncidentCategoryList: data as IncidentCategories[] }
    }


    @MutationAction
    async loadIncidentCategoryById(id: any) {
        
        const action = a.getIncidentCategoryById + "?id=" + id;
        const ic: IncidentCategories = {} as IncidentCategories
        const data = await api.get(action);
        console.log(data);
        return { incidentCategories: data as IncidentCategories };
    }

    // @MutationAction
    // async saveIncidentCategory() {

    //     const o = this.state as IncidentCategoriesModule;
    //     console.log(o.incidentCategories);
    //     const data = await api.addIncidentCategory(o.incidentCategories);
    //     return { incidentCategories: {} as IncidentCategories }
    // }

    @MutationAction
    async saveIncidentCategory() {
        
        const o = this.state as IncidentCategoriesModule;
        const action = a.addIncidentCategory;
        var formData = new FormData();
        formData.append('IsCovid', o.incidentCategories.IsCovid as any);
        formData.append('IsComplained', o.incidentCategories.IsComplained as any);
        formData.append('CategoryName', o.incidentCategories.CategoryName);
        formData.append('NepaliCategoryName', o.incidentCategories.NepaliCategoryName);
        formData.append('Id', o.incidentCategories.Id as any);
        if ("string" === typeof o.incidentCategories.Icon) {
            formData.append("Icon", o.incidentCategories.Icon);
        }
        else {
            formData.append("Icon", (o.incidentCategories.Icon as any).raw);
        }
        await api.post(action, formData);
        return {}
    }

    @Action
    deleteIncidentCategory(id: any) {
        const action = a.deleteIncidentCategory + "?id=" + id;
        var i = this.incidentCategoryList.findIndex(x => x.Id == id);
        if (i >= 0) {
            this.incidentCategoryList.splice(i, 1);
        }
        api.post(action, id);
    }

    @Action
    async loadUserAssignedCategory(users: any): Promise<UserAssignedCategory[]> {
        const action = a.loadUserAssignedCategory;
      
        const data = await api.post(action, users);
        return data as UserAssignedCategory[]
    }
}


export default getModule(IncidentCategoriesModule);