import { getApi, patchApi, postApi } from "@/store/api/api";
import {
  ConfirmResetPassword,
  EmailReset,
  UserLoginInfo,
  Activation,
} from "@/store/models/jwt";
import { Action } from "@/store/actionType";

export default new (class JWTAPI {
  public async getJWT(user: UserLoginInfo) {
    const response = await postApi(Action.login, user, {
      withCredentials: true,
    });
    return response;
  }
  public async getDetailProfile() {
    const response = await getApi(Action.me);
    return response;
  }

  public async updateUserProfile(profile: any) {
    const response = await patchApi(Action.me, profile);
    return response;
  }

  public async reapplyOwnership(id: any): Promise<any> {
    const action = Action.approveVehicleOwner + id + "/reapply_vehicle_owner/";
    const response = await postApi(action, {});
    return response as any;
  }

  public async reapplyGps(id: any): Promise<any> {
    const action =
      Action.getGpsServiceProvider + id + "/reapply_service_provider/";
    const response = await postApi(action, {});
    return response as any;
  }

  public async reapplyGpsDevice(id: any): Promise<any> {
    const action = Action.getGpsDevice + id + "/reapply_gps_model/";
    const response = await postApi(action, {});
    return response as any;
  }

  public async refreshAccessToken(params?: { refreshToken?: string }) {
    let payload = {};
    if (params) {
      payload = params;
    }
    try {
      const response = await postApi(Action.refreshToken, payload, {
        withCredentials: true,
      });
      return response;
    } catch (error) {
      //pass
    }
  }

  public async clearJWT() {
    const response = await postApi(
      Action.clearToken,
      {},
      {
        withCredentials: true,
      }
    );
    return response;
  }

  public async userActivation(data: Activation) {
    const response = await postApi(Action.activation, data);
    return response;
  }

  public async resendActivation(data: EmailReset) {
    const response = await postApi(Action.resendActivation, data);
    return response;
  }

  public async resetPassword(data: EmailReset) {
    const response = await postApi(Action.resetPassword, data);
    return response;
  }

  public async confirmResetPassword(data: ConfirmResetPassword) {
    const response = await postApi(Action.resetPasswordConfirm, data);
    return response;
  }
})();
