export enum FeatureIcon {
  Incident = "iconThree.svg",
  Patrol = "iconOne.svg",
  Panic = "iconOne.svg",
}

export enum FeatureType {
  Incident = "INCIDENT",
  Patrol = "PATROL",
  Panic = "PANIC",
  Station = "STATION",
}

export enum IncidentStatus {
  Created = "created",
  Acknowledge = "acknowledged",
  Assigned = "assigned",
  Rejected = "rejected",
  Completed = "completed",
  Failed = "failed",
  Route1 = "route1",
  Route2 = "route2",
  Route3 = "route3",
  Route4 = "route4",
}

export enum RouteStatus {
  Section = "section",
  Stops = "stops",
  Live = "live",
}

export enum Role {
  Admin = "admin",
  DutyOfficer = "duty officer",
  CommandOfficer = "command officer",
  Police = "police",
}
