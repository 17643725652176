import IncidentModule from "@/store/modules/incident";
import {
  CommonStore,
  MessagingModule,
  NotificationModule,
} from "@/store/modules";
import { Action } from "@/store/actionType";
import {
  Incident,
  Patrolling,
  UINotifciation,
  IncidentMessage,
  PanicMode,
} from "@/store/models/incident";
import { Chat } from "@/store/models/chat";
import { Traccar, LiveData } from "@/store/models/meta";
import { eventHub } from "@/utils/eventHub";
import helper from "@/utils/helpers";
import { json } from "d3";
import helpers from "@/utils/helpers";

export default class SocketHandler {
  static websocket: WebSocket = {} as WebSocket;
  static isConnectionOpened = false;
  static defaultUrl = "gps_update/";
  static connections: WebSocket[] = [];
  static createConnection(url: any = SocketHandler.defaultUrl) {
    const token = helpers.getAccessToken();

    const protocol = window.location.protocol == "https:" ? "wss" : "ws";
    const SocketBase = `${process.env.VUE_APP_DEV_WS_PROTOCOL ||
      protocol}://${process.env.VUE_APP_DEV_API_BASE_URL ||
      window.location.host}/ws/`;
    // SocketHandler.websocket = new WebSocket(Action.SocketBase + 'socket/open?token=' + token);

    if (!url.includes("token", 0)) {
      url += `?token=${token}`;
    }
    const fullUrl = SocketBase + url;

    if (SocketHandler.isConnectionOpened && SocketHandler.defaultUrl == url) {
      // socket has alerady been connected.
      return SocketHandler.websocket;
    } else if (this.connections.find((x) => x.url == fullUrl)) {
      console.log("Returned By finding connection");
      return this.connections.find((x) => x.url == fullUrl);
    }
    SocketHandler.websocket = new WebSocket(fullUrl);

    SocketHandler.websocket.onopen = function(e: any) {
      if (SocketHandler.defaultUrl == url) {
        SocketHandler.isConnectionOpened = true;
      }
    };
    SocketHandler.websocket.onmessage = function(e) {
      if (e.data) {
        const jsonData = JSON.parse(e.data);
        if (jsonData.type === "live_monitor") {
          const monitoringData = jsonData.data as any;
          CommonStore.setLiveMonitoringData(monitoringData);
        }
        if (jsonData.type === "gps_update") {
          const data = jsonData.data as Traccar[];
          //IncidentModule.updateTraccarFromSocket(data);
          CommonStore.setTraccar(data);
          //eventHub.$emit('socket-traccar',data);
        }
        if (jsonData.type === "gps_update_single") {
          const data = jsonData.data as Traccar;
          //IncidentModule.updateTraccarFromSocket(data);
          CommonStore.setTraccarSingle(data);
          //eventHub.$emit('socket-traccar',data);
        }
        if (jsonData.type === "message") {
          const data = jsonData.data as any;
          console.log(data);
          MessagingModule.addMessage(data);
          if (helpers.hasRoles(["admin", "dotm_staff", "operational_staff"])) {
            MessagingModule.updateLastestChatList(data);
          }
        }
        if (jsonData.type === "message_list") {
          const data = jsonData.data as any;
          MessagingModule.loadAllChatList(data);
        }
      }
    };

    SocketHandler.websocket.onerror = function(e: any) {
      console.log("socket errror");
    };
    SocketHandler.websocket.onclose = (e: any) => {
      if (SocketHandler.defaultUrl == url) {
        SocketHandler.isConnectionOpened = false;
      }
      if (this.connections.find((x) => x.url == fullUrl)) {
        const index = this.connections.findIndex((x) => x.url == fullUrl);
        this.connections.splice(index, 1);
      }
    };
    this.connections.push(SocketHandler.websocket);
    return SocketHandler.websocket;
  }

  static closeConnection() {
    if (SocketHandler.websocket.close) {
      SocketHandler.websocket.close();
    }
  }
}
