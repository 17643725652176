import store from "@/store";
import {
  Module,
  VuexModule,
  Mutation,
  MutationAction,
  Action,
  getModule,
} from "vuex-module-decorators";
import api from "@/store/api/oneTimeVehicleEntry";
import helpers from "@/utils/helpers";
import { CommonStore } from ".";
import { OneTimeVehicleEntry } from "../models/oneTimeVehicleEntry";

@Module({ name: "vehicleEntry", dynamic: true, store })
class VehicleEntryModule extends VuexModule {
  public vehicleEntry: OneTimeVehicleEntry = {} as OneTimeVehicleEntry;
  public vehicleEntryList: OneTimeVehicleEntry[] = [];

  @Mutation
  async resetField() {
    this.vehicleEntry = {} as any;
    return { vehicleEntry: this.vehicleEntry as OneTimeVehicleEntry };
  }

  @MutationAction
  async loadAllVehicleEntry(params: { page: number; vehicleNumber: number }) {
    let query = "?";
    if (params.page) {
      query += `&page=${params.page}`;
    }
    if (params.vehicleNumber) {
      query += `&vehicle=${params.vehicleNumber}`;
    }
    const data = await api.getVehicleEntry(query);
    return { vehicleEntryList: data as OneTimeVehicleEntry[] };
  }

  @MutationAction
  async createVehicleEntry() {
    let profile = helpers.getUserProfile() as any;
    const o = this.state as VehicleEntryModule;

    var formData = new FormData();

    let data = helpers.getNestedFormData(formData, o.vehicleEntry);
    if (o.vehicleEntry.id) {
      formData.append("id", o.vehicleEntry.id as any);
      var result = await api.updateVehicleEntry(data, o.vehicleEntry.id);
    } else {
      var result = await api.createVehicleEntry(data);
    }

    return {};
  }

  @MutationAction
  async getVehicleEntryById(id: any) {
    const data = await api.getVehicleEntryById(id);
    return { vehicleEntry: data as OneTimeVehicleEntry };
  }
}

export default getModule(VehicleEntryModule);
