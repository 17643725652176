import store from "@/store";
import {
  Module,
  VuexModule,
  Mutation,
  MutationAction,
  Action,
  getModule,
} from "vuex-module-decorators";
import api from "@/store/api/emailSetupApi";
import helpers from "@/utils/helpers";
import { CommonStore } from ".";
import { EmailSetup } from "../models/registration/registration";

@Module({ name: "email", dynamic: true, store })
class EmailModule extends VuexModule {
  public email: EmailSetup = {} as EmailSetup;
  public emailList: EmailSetup[] = [];
  @Mutation
  async resetField() {
    this.email = {} as any;
    return { email: this.email as EmailSetup };
  }

  @MutationAction
  async loadEmail() {
    const data = await api.getEmail();
    console.log(data);
    return { emailList: data as EmailSetup[] };
  }

  @MutationAction
  async createEmail() {
    const o = this.state as EmailModule;
    var formData = new FormData();

    let data = helpers.getNestedFormData(formData, o.email);
    await api.createEmail(data);

    return {};
  }

  @MutationAction
  async sendEmail() {
    const o = this.state as EmailModule;
    var formData = new FormData();
    let data = helpers.getNestedFormData(formData, o.email);
    await api.sendEmail(data);

    return {};
  }

  @MutationAction
  async getEmail() {
    const data = await api.getEmail();
    return { email: data as EmailSetup };
  }
}

export default getModule(EmailModule);
