import { postApi,postApiWithoutToken, getApi, getApiWithoutLoader, postApiWithoutLoader } from "@/store/api/api";
import { Action } from "@/store/actionType";
import { Register, UserLoginRequest } from "@/store/models/auth";


export default new class CommonAPI {
    public async postWithoutToken(action:string,param: any): Promise<any> {
        // const action = Action.userLogin;
         const response = await postApiWithoutToken(action, param);
         return response as any;
     }
    public async post(action:string,param: any): Promise<any> {
       // const action = Action.userLogin;
        
        const response = await postApi(action, param);
        return response as any;
    }

    public async getAPIWithoutToken(action: string): Promise<any> {
        
        const response = await getApi(action);
      
        return response as any;
    }
    public async get(action: string): Promise<any> {
        
        const response = await getApi(action);
      
        return response as any;
    }

}