import {
  postApi,
  postApiWithoutToken,
  getApi,
  getApiWithoutLoader,
  postApiWithoutLoader,
  putApi,
} from "@/store/api/api";
import { Action } from "@/store/actionType";
import { Ownership } from "../models/registration/registration";

export default new (class VehicleScheduleAPI {
  public async getVehicleSchedule(query: string = ""): Promise<any> {
    const action = Action.getVehicleSchedule + query;
    const response = await getApi(action);

    return response as any;
  }
  public async createVehicleSchedule(schedule: any): Promise<any> {
    const action = Action.createVehicleSchedule;
    const response = await postApi(action, schedule);
    return response as any;
  }

  public async updateVehicleSchedule(schedule: any, id: any): Promise<any> {
    const action = Action.updateVehicleSchedule + id + "/";
    const response = await putApi(action, schedule);
    return response as any;
  }

  // public async deleteDriver(owner: Ownership): Promise<any> {
  //     if (owner.id !== undefined) {
  //         const action = Action.deleteNews + "?id=" + owner.id;
  //         const response = await postApi(action, owner);
  //         return response as any;
  //     }

  //     else {
  //         const action = Action.deleteNews + "?id=" + owner.id;
  //         const response = await postApi(action, owner);
  //         return response as any;
  //     }
  // }

  public async getVehicleScheduleById(id: number): Promise<any> {
    const action = `${Action.getVehicleSchedule}${id}`;
    const response = await getApi(action);
    return response as any;
  }
})();
