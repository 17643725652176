import {
  postApi,
  postApiWithoutToken,
  getApi,
  getApiWithoutLoader,
  postApiWithoutLoader,
  putApi,
  patchApi
} from "@/store/api/api";
import { Action } from "@/store/actionType";
import { Ownership } from "../models/registration/registration";

export default new (class RegistrationAPI {
  public async getUsers(): Promise<any> {
    const action = Action.getUser;
    const response = await getApi(action);
    return response as any;
  }

  public async getSystemUsers(query: string = ""): Promise<any> {
    const action = Action.getUsersByRole + query 
    // + "&profile__user_type=system_user" ;
    const response = await getApi(action);
    return response as any;
  }

  public async getPublicUsers(query: string = ""): Promise<any> {
    const action = Action.getUsersByRole + query + "&profile__user_type=public";
    const response = await getApi(action);
    return response as any;
  }

  public async getPublicUserById(id: any): Promise<any> {
    const action = Action.getPublicUser + `${id}/`;
    const response = await getApi(action);
    return response as any;
  }

  public async getVehicleOwnerUsers(): Promise<any> {
    const action = Action.getUsersByRole + "?profile__user_type=vehicle_owner";
    const response = await getApi(action);
    return response as any;
  }

  public async createUsers(user: any): Promise<any> {
    const action = Action.createUser;
    const response = await postApi(action, user);
    return response as any;
  }

  public async updateUsers(user: any, id: any): Promise<any> {
    const action = Action.updateUser + id + "/";
    const response = await patchApi(action, user);
    return response as any;
  }

  // public async deleteDriver(owner: Ownership): Promise<any> {
  //     if (owner.id !== undefined) {
  //         const action = Action.deleteNews + "?id=" + owner.id;
  //         const response = await postApi(action, owner);
  //         return response as any;
  //     }

  //     else {
  //         const action = Action.deleteNews + "?id=" + owner.id;
  //         const response = await postApi(action, owner);
  //         return response as any;
  //     }
  // }

  public async getUserById(id: number): Promise<any> {
    const action = `${Action.getUser}${id}/`;
    const response = await getApi(action);
    return response as any;
  }
})();
