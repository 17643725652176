import { JWTModule } from "@/store/modules";

// export function roleChecker(roles: string[]) {
//   for (const role of roles) {
//     if (JWTModule.UserDetail.role === role) {
//       return true;
//     }
//   }
//   return false;
// }

export async function WaitUntilRefreshed() {
  while (JWTModule.RefreshingToken) {
    await new Promise((resolve) => setTimeout(resolve, 100));
  }
}

export function HasJwtExpired() {
  const exp = JWTModule.DecodedPayload.exp;
  const now = Math.floor(new Date().getTime() / 1000);
  if (exp <= now) {
    return true;
  }
  return false;
}
