import incident from "./api/incident";

export enum Action {
  //Base = "https://localhost:44350/api/v1", //localsaroj
  // Base = "http://192.168.10.201/api", //localsaroj
  Base = "api", //anishserver
  SocketBase = "ws://192.168.10.201/ws/", //local

  login = "jwt/",
  refreshToken = "jwt/refresh/",
  clearToken = "jwt/clear/",

  resetPassword = "auth/reset_password/",
  resetPasswordConfirm = "auth/reset_password_confirm/",
  setPassword = "auth/set_password/",
  activation = "auth/activation/",
  resendActivation = "auth/resend_activation/",
  me = "auth/me/",

  facebookLogin = "auth/facebook/",
  googleLogin = "auth/google/",

  getViolationChart = "violation_chart/",
  getRuleViolationTrend = "violation_trend/",
  getRuleViolationTrendCsv = "violation_trend_csv/openpyxl/",
  getRoutes = "routes/all/",
  getRoutesDD = "routes/all/",
  getRoutesOfUser = "routes/",
  getUserType = "user_types/",
  getSourceDestination = "source_destination/",
  getProtocol = "tcprotocol/",
  getVehicleList = "vehicle/vehicle_list/",
  vehicleByOwner = "vehicle/vehicle_list_by_owner/",
  vehicleListCSV = "vehicle/vehicle_list_for_csv/",
  getVehicleTypeCodeDD = "vehicle_category/",
  getVehicleTypeDD = "vehicle_type/",
  // getRouteGeoJson="route/get-geo-json",
  getRouteGeoJson = "road_sections/geojson/",
  // getStopGeoJson="stop/get-geo-json",
  getStopGeoJson = "stops/geojson/",
  getTraccarDetails = "traccar/details",
  getSections = "road_sections/",
  getStop = "stop/",
  getStopData = "stops/",
  getStops = "route/stop",
  routeStop = "route_stop/",
  roadLink = "road_link/",
  routeRoad = "route_road/",
  speedSection = "speed_section/",
  getLiveMonitoringDataByPaging = "vehicleHistory/",
  createOwnership = "register/vehicle_owner/",
  editOwnership = "register/vehicle_owner/",
  getOwnerCount = "register/vehicle_owner/count/",
  ownercsv = "register/vehicle_owner/vehicleOwnerListForCsv/",
  totalDriver = "register​/vehicle_owner​/total_driver_of_owner​/",
  totalCompany = "register/vehicle_owner/total_vehicle_of_owner/",
  createDriver = "driver/",
  editDriver = "driver/",
  getDriver = "driver/",
  deleteDriver = "driver/delete_driver/",
  drivercsv = "driver/get_driver_list_for_csv/",
  activateDriver = "driver/",
  createVehicle = "vehicle/",
  updateVehicle = "vehicle/",
  getVehicle = "vehicle/",
  getVehicleCount = "vehicle/count/",
  approveVehicle = "vehicle/",
  vehicleHistory = "vehicle_history/",
  traccarHistory = "traccar_history/",
  submitMultipleVehicles = "vehicle/submit_vehicles/",
  changeVehicleRoute = "route_permit/",
  createGpsServiceProvider = "gps_service_provider/",
  updateGpsServiceProvider = "gps_service_provider/",
  getGpsServiceProvider = "gps_service_provider/",
  gpscsv = "gps_service_provider/get_provider_for_csv/",
  getGpsServiceProviderList = "gps_service_provider/client_info/",
  getActiveGpsServiceProvider = "gps_service_provider/all_active/",
  getAllOwnerAssociatedToCompany = "gps_service_provider/owner_associated_with_this_provider/",
  createGpsDevice = "gps_model/",
  updateGpsDevice = "gps_model/",
  getGpsDevice = "gps_model/",
  getActiveGpsDevice = "gps_model/",
  getDataBySectionId = "route/get-geo-json-by-section",
  createVehicleSchedule = "vehicle_schedule/",
  updateVehicleSchedule = "vehicle_schedule/",
  getVehicleSchedule = "vehicle_schedule/",
  createVehicleEntry = "one_time_permit/",
  updateVehicleEntry = "one_time_permit/",
  getVehicleEntry = "one_time_permit/",
  getUser = "system_user/",
  createUser = "system_user/",
  updateUser = "system_user/",
  getVehicleOwner = "register/vehicle_owner/",
  approveVehicleOwner = "register/vehicle_owner/",
  approveVehicles = "register/vehicle_owner/",
  newsFeed = "news_feed/",
  newsCategory = "news_feed/news_category/",
  newsType = "news_feed/news_type/",
  getUsersByRole = "users/",
  getPublicUser = "public_user/",
  createPublicUser = "public_user/",
  updatePublicUser = "public_user/",
  createEmail = "smtp_settings/",
  sendEmail = "test_email/",
  updateEmail = "smtp_settings/",
  getEmail = "smtp_settings/",
  createJam = "jam_settings/",
  updateJam = "jam_settings/",
  getJam = "jam_settings/",
  getAllVehicleOwner = "register/vehicle_owner/vehicleOwnerList/",

  searchPlanYourTravel = "plan_travel/",
  planTravelChoices = "plan_travel_choices/",

  getAllPanicList = "panic_mode/get_all",
  stopPanicMode = "panic_mode/stop",

  getAll = "incidents/get_all",
  getIncident = "incidents/get_incident",
  getIncidentByPaging = "incidents/page",
  getComplainByPaging = "complain/page",
  getIncidentReportByUser = "incidents/get_user_wise_incident",
  getComplainReportByUser = "complains/get_user_wise_complain",
  getIncidentGeoJson = "incidents/geojson",

  getPatrolling = "patrolling/get_patrolling",
  stopPatrolling = "patrolling/stop",
  startPatrolling = "patrolling/save",
  updatePatrolling = "patrollingdetail/save",

  nearestPatrolling = "incidents/nearest_patroll",
  getIncidentMaterials = "incidents/get_material",
  getIncidentCategory = "incident_categories/get-list",
  getIncidentExceptComplainCategory = "incident_without_complain_categories/get-list",
  getComplainSubCategory = "complain/get_list", // UserWise
  getAllComplainSubCategory = "complain_categories/get-list",
  getIncidentCategoryById = "incident_category/get_incident_category",
  addIncidentCategory = "incident_categories/add",

  loadUserAssignedCategory = "office/getUserIncidentDetails",
  deleteIncidentCategory = "incident_categories/delete",
  userLogin = "user-login",
  registerUser = "public-user/register",
  forgotPassword = "forget-password",
  changePassword = "auth/set_password/",
  policeUserLogin = "userlogin",
  assignIncident = "incident_assign",
  acknowledgeIncident = "incident_acknowledge",
  rejectIncident = "incident_reject",
  registerDutyOffice = "duty_office/register",
  registerDutyOfficeUser = "duty_office_user/register",
  assignCategory = "police/assign_categories",
  getDutyOffice = "duty_office/get_all",
  getDutyOfficeByDistrictId = "duty_office/get_by_districtId",

  getDutyOfficeById = "duty_office/get_by_id",
  getProvince = "province/",
  getDistrict = "district/",
  getRoles = "role/get_all",
  getMunicipality = "palika/",
  getPoliceUser = "police/get_all_user",
  getDutyUserByDutyOffice = "police/getDutyUserByDutyOffice",
  //FM
  getFM = "fm/get_all",
  getFMById = "fm/get_by_id",
  registerFM = "fm/register",
  deleteFm = "fm/delete",
  //Police Stations
  getPoliceStationGeoObj = "police/stations/geoObj",
  getPoliceStations = "police/stations/get_all",
  createPoliceStation = "police/stations/register",
  getPoliceStationById = "police/stations/get_by_id",
  //Duty office
  createDutyOfficeUser = "duty_office_user/register",
  getAllOfficeUser = "office/get_all_user",
  getOfficeUserById = "office/getUserById",
  deleteDutyUser = "office/delete/user",

  // News Related
  createNews = "news/create-news",
  getNews = "news",
  getNewsById = "news/getNews/id",
  editNews = "news/edit-news",
  deleteNews = "news/delete-news/id",
  getAllNewsCategory = "news/get_all_news_category",
  getNewsType = "news/get_all_news_type",

  // End News Related

  //chat
  getChatMessage = "chat/",
  getUserChatMessage = "chat/get_user_chat/",
  saveChatMessage = "chat/",
  getLatestChatMessage = "chat/get_last_chat/",
  updateLastSeen = "chat/update_seen_status/",

  // incident messaging
  getIncidentMessage = "incident/get_message",
  saveIncidentMessage = "incident/save_message",

  getIncidentReport = "incident/report",
  getIncidentReportByStatus = "incident/status/report",
  getIncidentReportByCategory = "incident/category/report",
  getIncidentReportByWeekly = "incident/weekly/report",

  //Patrolling Distance Covered
  getPatrollingDistanceCovered = "patrolling/distance/covered",

  //faq
  getAllFAQ = "get/all/faq",
  saveFAQ = "save/faq",
  getFAQById = "get/faq",
  deleteFAQ = "delete/faq",

  getPaginatedFeedBack = "feedback",

  //Notice to Admin and DOTM
  adminNotice = "admin_notice/",

  //Captcha
  createCaptcha = "captcha/create_captcha/",
  // validateCaptcha = "captcha/${id}/validate_captcha/",
}
