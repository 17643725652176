import {
  Module,
  VuexModule,
  Mutation,
  Action,
  getModule,
  MutationAction,
} from "vuex-module-decorators";
import store from "@/store";
import { District, Palika, PoliceUser } from "@/store/models/meta";

import {
  UINotifciation,
  Incident,
  Patrolling,
  IncidentMessage,
} from "@/store/models/incident";
import { PaginatedChat, Chat } from "@/store/models/chat";
import helper from "@/utils/helpers";
import api from "@/store/api/incident";
import apii from "@/store/api/chatMessage";
import incident from "./incident";
import moment from "moment";
import { CommonStore, IncidentModule } from "@/store/modules";
import helpers from "@/utils/helpers";

@Module({ name: "messagingStore", dynamic: true, store })
class MessagingModule extends VuexModule {
  public messaging: Record<string, IncidentMessage[]> = {};
  public chatMessaging: Record<string, PaginatedChat> = {};
  public chatUserMessaging: Record<string, PaginatedChat> = {};
  public incidents: string[] = [];
  public showMessageBox: boolean = false;
  public selectedIncidentId: string = "";
  public isMessageBoxActive: boolean = false;
  public selectedIncidentMessages: IncidentMessage[] = [];

  scrollToBottom: number = 0;
  selectedUser: string = "";
  public selectedChatMessages: PaginatedChat = {} as PaginatedChat;
  public selectedChatMessagesList: Chat[] = [] as Chat[];

  latestChatList: Chat[] = [];
  public userChatMessages: PaginatedChat = {} as PaginatedChat;

  public userChatMessagesList: Chat[] = [] as Chat[];
  public userChatPaginatedMessages: PaginatedChat = {} as PaginatedChat;
  userChatNewMessages: boolean = false;

  public chatM: PaginatedChat = {} as PaginatedChat;
  public title: string = "";
  public newChatCount: boolean = false;

  adminSelectedUserUid: string = "";

  @Mutation
  public setIsMessageBoxActive(val: boolean) {
    this.userChatNewMessages = false
    this.isMessageBoxActive = val;
  }

  @MutationAction
  async setAdminSelectedUserUid(val: string) {
    return { adminSelectedUserUid: val };
  }

  @MutationAction
  public async getSelectedUsersChat(params: any) {
    let query = "?";
    if (params) {
      if (params.userId) {
        query += `&user_id=${params.userId}`;
      }
      if (params.pageSize) {
        query += `&page=${params.pageSize}`;
      }
    }
    let {
      chatMessaging,
      incidents,
      chatUserMessaging,
      selectedChatMessagesList,
    } = this.state as MessagingModule;
    // if (!chatMessaging.hasOwnProperty(params.userId)) {

    var res = (await apii.getChatMessage(query)) as PaginatedChat;
    var r = (await apii.getChats()) as PaginatedChat;
    chatMessaging[params.userId] = res;
    chatUserMessaging[params.userId] = r;
    // }

    incidents = [];
    incidents.push(params.userId);
    let t = chatMessaging[params.userId];
    let p = chatUserMessaging[params.userId];

    if (res.results) {
      if (
        selectedChatMessagesList.length == 0 ||
        selectedChatMessagesList[0].publicId !== res.results[0].publicId
      ) {
        selectedChatMessagesList = res.results.reverse();
        console.log(selectedChatMessagesList);
      } else {
        selectedChatMessagesList.unshift(...res.results.reverse());
      }
    }

    return {
      selectedIncidentId: params.userId,
      selectedChatMessages: chatMessaging[params.userId],
      incidents: incidents,
      selectedChatMessagesList: selectedChatMessagesList,
      userChatMessages: chatUserMessaging[params.userId],
    };
  }

  @MutationAction
  public async selectUser(params: any) {
    let query = "?";
    if (params) {
      if (params.pageSize) {
        query += `&page=${params.pageSize}`;
      }
    }
    let { incidents, chatUserMessaging } = this.state as MessagingModule;
    // if (!chatUserMessaging.hasOwnProperty(params.userId)) {
    var res = (await apii.getChats(query)) as PaginatedChat;
    chatUserMessaging[params.userId] = res;
    // }

    incidents = [];
    incidents.push(params.userId);
    let t = incidents;
    let p = chatUserMessaging[params.userId];
    return {
      selectedIncidentId: params.userId,
      incidents: incidents,
      userChatMessages: chatUserMessaging[params.userId],
    };
  }

  @MutationAction
  public async getUserChat(params: any) {
    let query = "?";
    if (params) {
      if (params.pageSize) {
        query += `&page=${params.pageSize}`;
      }
    }
    let { userChatPaginatedMessages, userChatMessagesList } = this
      .state as MessagingModule;

    var res = (await apii.getChats(query)) as PaginatedChat;
    userChatPaginatedMessages = res;

    if (res.results) {
      if (userChatMessagesList.length == 0) {
        userChatMessagesList = res.results.reverse();
        console.log(userChatMessagesList);
      } else {
        userChatMessagesList.unshift(...res.results.reverse());
      }
    }
    return {
      userChatMessagesList: userChatMessagesList,
      userChatPaginatedMessages: userChatPaginatedMessages,
    };
  }

  @MutationAction
  public async addIncidentMessage(obj: IncidentMessage) {
    let st = this.state as MessagingModule;

    if (!st.messaging.hasOwnProperty(obj.IncidentId)) {
      var res = (await api.getIncidentMessage(
        obj.IncidentId
      )) as IncidentMessage[];
      st.messaging[obj.IncidentId] = res;
      st.incidents.push(obj.IncidentId);
    } else {
      st.messaging[obj.IncidentId].push(obj);
    }

    return { messaging: st.messaging };
  }

  @MutationAction
  public async getIncidentMessage(incidentId: any) {
    var res = (await api.getIncidentMessage(incidentId)) as IncidentMessage[];
    let st = this.state as MessagingModule;
    st.selectedIncidentId = incidentId;

    if (!st.messaging.hasOwnProperty(incidentId)) {
      st.incidents.push(incidentId);
    }

    //CreatedAt
    st.messaging[incidentId] = res;
    return {
      messaging: st.messaging,
      selectedIncidentId: st.selectedIncidentId,
    };
  }

  @MutationAction
  async saveIncidentMessage(obj: IncidentMessage) {
    let st = this.state as MessagingModule;
    st.messaging[obj.IncidentId].push(obj);
    let c = await api.saveIncidentMessage(obj);

    return {};
  }

  @MutationAction
  public async addMessageOld(obj: any) {
    console.log(obj);
    let usertype = helpers.getUserType();
    let header = CommonStore.headerTitle;
    let st = this.state as MessagingModule;
    console.log("STATE", st);
    console.log(st.messaging.hasOwnProperty(obj.publicId));
    if (!st.messaging.hasOwnProperty(obj.publicId)) {
      console.log("Here", obj.adminId);
      if (
        (obj.adminId === null || obj.adminId === "None") &&
        usertype !== "admin"
      ) {
        console.log("IsNotAdmin");
        // var ress = (await apii.getChats()) as PaginatedChat;
        // st.chatUserMessaging[obj.publicId] = ress;
        if (
          st.chatUserMessaging[obj.publicId] &&
          st.chatUserMessaging[obj.publicId].results
        ) {
          let results = st.chatUserMessaging[obj.publicId].results;
          if (results) {
            results.push(obj);
          } else {
            results = [obj];
          }
        } else {
          st.chatUserMessaging[obj.publicId] = {
            count: 1,
            next: "",
            previous: "",
            currentPage: 1,
            pageSize: 1,
            totalPages: 1,
            totalObjects: 1,
            results: [obj],
          };
        }
      } else if (usertype === "public") {
        console.log("IsPublic");
        // var ress = (await apii.getChats()) as PaginatedChat;
        // st.chatUserMessaging[obj.publicId] = ress;
        if (
          st.chatUserMessaging[obj.publicId] &&
          st.chatUserMessaging[obj.publicId].results
        ) {
          let results = st.chatUserMessaging[obj.publicId].results;
          if (results) {
            results.push(obj);
          } else {
            results = [obj];
          }
        } else {
          console.log("Admin Here");
          st.chatUserMessaging[obj.publicId] = {
            count: 1,
            next: "",
            previous: "",
            currentPage: 1,
            pageSize: 1,
            totalPages: 1,
            totalObjects: 1,
            results: [obj],
          };
        }
      } else {
        if (
          st.chatMessaging[obj.publicId] &&
          st.chatMessaging[obj.publicId].results
        ) {
          let results = st.chatMessaging[obj.publicId].results;
          if (results) {
            results.push(obj);
          } else {
            results = [obj];
          }
        } else {
          // if (obj.publicId && header != "") {
          //   var res = (await apii.getChatMessage(obj.publicId)) as PaginatedChat;

          //   st.chatMessaging[obj.publicId] = res;
          // }
          st.chatMessaging[obj.publicId] = {
            count: 1,
            next: "",
            previous: "",
            currentPage: 1,
            pageSize: 1,
            totalPages: 1,
            totalObjects: 1,
            results: [obj],
          };
        }
      }

      st.incidents.push(obj.publicId as any);
    } else {
      const resultChatArray = st.chatMessaging[obj.publicId].results;
      if (resultChatArray) {
        resultChatArray.push(obj);
      }
      const resultUserChatArray = st.chatUserMessaging[obj.publicId].results;
      if (resultUserChatArray) {
        resultUserChatArray.push(obj);
      }
    }
    st.selectedChatMessages = st.chatMessaging[obj.publicId];
    st.userChatMessages = st.chatUserMessaging[obj.publicId];
    CommonStore.setNewMsg(true);
    this.newChatCount = true;

    return {
      chatMessaging: st.chatMessaging,
      chatUserMessaging: st.chatUserMessaging,
    };
  }

  @MutationAction
  public async addMessage(obj: any) {
    let currentUserUid = helpers.getUserUId();
    let usertype = helpers.getUserType();
    let header = CommonStore.headerTitle;
    let st = this.state as MessagingModule;
    console.log("STATE", st);

    st.selectedChatMessages = st.chatMessaging[obj.publicId];
    st.userChatMessages = st.chatUserMessaging[obj.publicId];
    CommonStore.setNewMsg(true);
    this.newChatCount = true;
    if (st.adminSelectedUserUid == obj.publicId) {
      st.selectedChatMessagesList.push(obj);
      st.scrollToBottom = new Date().getTime();
    }
    if (currentUserUid == obj.publicId) {
      st.userChatMessagesList.push(obj);
      st.scrollToBottom = new Date().getTime();
    }

    return {
      chatMessaging: st.chatMessaging,
      chatUserMessaging: st.chatUserMessaging,
    };
  }

  @MutationAction
  public async getChatMessage(incidentId: any) {
    var res = (await apii.getChatMessage(incidentId)) as PaginatedChat;
    let st = this.state as MessagingModule;
    st.selectedIncidentId = incidentId;

    if (!st.messaging.hasOwnProperty(incidentId)) {
      st.incidents.push(incidentId);
    }

    //CreatedAt
    st.chatMessaging[incidentId] = res;
    this.chatM = res;

    return {
      chatMessaging: st.chatMessaging,
      selectedIncidentId: st.selectedIncidentId,
    };
  }

  @MutationAction
  async saveChatMessage(obj: Chat) {
    let st = this.state as MessagingModule;
    //let c = await api.saveIncidentMessage(obj);
    let c = await apii.saveMessage(obj);
    const chatArrayresult = st.chatUserMessaging[obj.publicId].results;
    console.log("Saving:", chatArrayresult);
    if (chatArrayresult) {
      chatArrayresult.push(obj);
    } else {
      st.chatUserMessaging[obj.publicId] = {
        count: 1,
        next: "",
        previous: "",
        currentPage: 1,
        pageSize: 1,
        totalPages: 1,
        totalObjects: 1,
        results: [obj],
      };
    }
    console.log("Saved:", st.chatUserMessaging[obj.publicId], chatArrayresult);
    return {};
  }

  @MutationAction
  async updateChatMessage(obj: Chat) {
    let chatUserMessaging = (this.state as MessagingModule).chatUserMessaging;
    const userChatMessageArray = chatUserMessaging[obj.publicId].results;
    if (userChatMessageArray) {
      userChatMessageArray.push(obj);
    }
    return { chatUserMessaging: chatUserMessaging };
  }

  @Action
  async saveChatAdminMessage(obj: Chat) {
    let st = this.state as MessagingModule;
    // let c = await api.saveIncidentMessage(obj);
    let c = await apii.saveMessage(obj);
    return c;
  }

  @MutationAction
  async updateChatAdminMessage(obj: Chat) {
    let chatMessaging = (this.state as MessagingModule).chatMessaging;
    const chatMessageArray = chatMessaging[obj.publicId].results;
    if (chatMessageArray) {
      chatMessageArray.push(obj);
    }
    return { chatMessaging: chatMessaging };
  }

  @MutationAction
  async updateSeenMessage(id: any) {
    let c = await apii.updateLastSeen(id);

    return {};
  }

  @MutationAction
  async loadAllChatList(e: any) {
    let st = this.state as MessagingModule;
    st.latestChatList = e;
    return { latestChatList: e as Chat[] };
  }

  @MutationAction
  async updateLastestChatList(data: any) {
    let latestChatList = (this.state as MessagingModule).latestChatList;
    if (!data.adminId || data.adminId == null || data.adminId == "None") {
      if (latestChatList && latestChatList.length > 0) {
        let index = latestChatList.findIndex(
          (x) => x.publicId == data.publicId
        );
        if (index >= 0) {
          // Delete old chat
          latestChatList.splice(index, 1);
        }
        latestChatList.splice(0, 0, data);
      } else {
        latestChatList = [data];
      }
    }
    return { latestChatList: latestChatList as Chat[] };
  }
}

export default getModule(MessagingModule);
