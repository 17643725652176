import { UserDetail, UserProfile } from "@/store/models/auth";

import moment from "moment";
import { AuthModule, JWTModule } from "@/store/modules";
import { time } from "highcharts";

export default new (class Helper {
  removeUserProfile() {
    localStorage.removeItem("user");
  }
  setUserProfile(obj: UserProfile | null) {
    if (obj) {
      localStorage.setItem("user", JSON.stringify(obj));
      console.log(JSON.stringify(obj));
    } else {
      localStorage.removeItem("user");
    }
  }

  getUserProfile(): UserProfile | undefined {
    // const a = localStorage.getItem("user");
    const userDetail = JWTModule.UserDetail;
    if (userDetail) {
      const profile = {
        id: userDetail.id,
        userDetail: userDetail,
        userName: userDetail.username,
        Email: userDetail.email,
      } as UserProfile;
      if (userDetail.profile && userDetail.profile.userType) {
        profile["userType"] = userDetail.profile.userType;
      }
      AuthModule.setProfile(profile);
      return profile;
    }
    return undefined;
  }

  getUserExtent(): [number, number, number, number] | undefined {
    let profile = this.getUserProfile();
    // if (profile) {
    //   let extents = this.convertStringToExtent(profile.Extent);
    //   if (extents.length === 4) return extents;
    // }
    return undefined;
  }

  convertStringToExtent(str: string): [number, number, number, number] {
    let extents = str.split(",");
    let numbers = [];
    for (var i = 0; i < extents.length; i++) {
      numbers.push(parseFloat(extents[i]));
    }
    return numbers as [number, number, number, number];
  }

  getToken() {
    const profile = this.getUserProfile();
    let k = JWTModule.AccessToken;
    if (profile) {
      return profile.access;
    }
    return undefined;
  }

  getAccessToken() {
    return JWTModule.AccessToken;
  }

  getUserType() {
    const profile = this.getUserProfile();
    if (profile) {
      return profile.userType;
    }
    return undefined;
  }

  getUserName() {
    const profile = this.getUserProfile();
    if (profile) {
      return profile.userName;
    }
    return "";
  }

  getUserId() {
    const profile = this.getUserProfile();
    if (profile) {
      return profile.id;
    }
    return undefined;
  }

  getUserUId() {
    const profile = this.getUserProfile();
    if (profile) {
      return profile.userDetail.profile.uid;
    }
    return undefined;
  }

  toMomentDate(date: any) {
    let d = moment(date).fromNow();
    if (d) return d;
    return "";
  }
  subtractDate(date: any, day: number) {
    let d = moment(date)
      .subtract(day, "day")
      .toDate();
    return d;
  }

  toMomentTime(time: any) {
    let d = moment(time, "HH:mm:ss").format("hh:mm A");
    if (d) return d;
    return "";
  }

  toMomentFormat(date: any) {
    let d = moment(date).format("DD-MM-YYYY");
    return d;
  }

  /**
   * Gives difference(in days) of two dates.
   * Date1 - Date2 = 3 days
   *
   * @param {string} dates
   */
  differenceOfTwoDates(x: string, y: string): number {
    const time1 = moment(x);
    const time2 = moment(y);
    return time1.diff(time2, "days");
  }

  JSONToCSVConvertor(JSONData: any, ReportTitle: string, ShowLabel: boolean) {
    //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
    var arrData = typeof JSONData != "object" ? JSON.parse(JSONData) : JSONData;
    var CSV = "";
    //Set Report title in first row or line

    CSV += ReportTitle + "\r\n\n";

    //var CSV = "Downloaded Date : " + this.toMomentFormat(new Date()) + "\r\n";
    //This condition will generate the Label/Header
    if (ShowLabel) {
      var row = "";

      //This loop will extract the label from 1st index of on array
      for (var index in arrData[0]) {
        //Now convert each value to string and comma-seprated
        row += index + ",";
      }

      row = row.slice(0, -1);

      //append Label row with line break
      CSV += row + "\r\n";
    }

    //1st loop is to extract each row
    for (var i = 0; i < arrData.length; i++) {
      var row = "";

      //2nd loop will extract each column and convert it in string comma-seprated
      for (var index in arrData[i]) {
        row += '"' + arrData[i][index] + '",';
      }

      row.slice(0, row.length - 1);

      //add a line break after each row
      CSV += row + "\r\n";
    }

    if (CSV == "") {
      alert("Invalid data");
      return;
    }

    //Generate a file name
    var fileName = "";
    //this will remove the blank-spaces from the title and replace it with an underscore
    fileName += ReportTitle.replace(/ /g, "_");

    //Initialize file format you want csv or xls
    var uri =
      "data:text/csv;charset=utf-8,%EF%BB%BF," + encodeURIComponent(CSV);

    var link = document.createElement("a") as any;
    link.href = uri;

    link.style = "visibility:hidden";
    link.download = fileName + ".csv";

    //this part will append the anchor tag and remove it after automatic click
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  getNestedFormData(
    formData: FormData,
    data: any,
    prekey: string | null = null
  ) {
    for (let key of Object.keys(data)) {
      let dataKey = key;
      if (prekey) {
        dataKey = `${prekey}[${key}]`;
      } else if (Array.isArray(data)) {
        dataKey = `[${dataKey}]`;
      }
      if (typeof data[key] == "object") {
        if (data[key] instanceof File) {
          formData.append(dataKey, data[key]);
        } else if (data[key] === null) {
        } else {
          formData = this.getNestedFormData(formData, data[key], dataKey);
        }
      } else {
        formData.append(dataKey, data[key]);
      }
    }

    return formData;
  }

  removeFilesForPatch(fileList: { property: any; fieldName: any }[]) {
    for (let file of fileList) {
      const property = file.property;
      const fieldName = file.fieldName;
      let field = property[fieldName];
      if (field === undefined || field === null || typeof field === "string") {
        delete property[fieldName];
      }
    }
  }

  hasRoles(roles: string[]) {
    const profile = this.getUserProfile();
    if (profile && profile.userType) {
      const userRole = profile.userType;
      for (let role of roles) {
        if (userRole == role) {
          return true;
        }
      }
    }
    return false;
  }
})();
