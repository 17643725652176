import store from "@/store";
import {
    Module,
    VuexModule,
    Mutation,
    MutationAction,
    Action,
    getModule
} from "vuex-module-decorators";
import api from "@/store/api/news";
import { News, NewsCategory, NewsTypes } from '../models/news';




@Module({ name: 'news', dynamic: true, store })
class NewsModule extends VuexModule {

    public newsList: News[] = []
    public thenews: News = {} as News
    public newsCategoryList: NewsCategory[] = []
    public newsTypeList: NewsTypes[] = []
    @Mutation
    async resetField() {


        this.thenews = {} as any;


        return { thenews: this.thenews as News }
    }


    @MutationAction
    async createNews() {

        const o = this.state as NewsModule;

        var formData = new FormData();

        formData.append('Title', o.thenews.Title);
        formData.append('ShortDescription', o.thenews.ShortDescription);
        formData.append('LongDescription', o.thenews.LongDescription);
        formData.append('NewsCategoryId', o.thenews.NewsCategoryId as any);
        formData.append('NewsTypeId', o.thenews.NewsTypeId as any);

        formData.append("PublishedDate", o.thenews.PublishedDate);
        var prevFiles = []
        if (o.thenews.FileList) {
            var totalDoc = o.thenews.FileList.length;

            for (var x = 0; x < totalDoc; x++) {
                
                let file = (o.thenews.FileList[x] as any).raw
                if (file) {
                    formData.append("FileList[]", (o.thenews.FileList[x] as any).raw);
                }
                else {
                  //  formData.append("FileList[]", o.thenews.FileList[x]);
                  prevFiles.push(o.thenews.FileList[x].name);
                }

            }
            formData.append("OldFiles", prevFiles.toString());
        }

        if (o.thenews.Id) {
            console.log(o.thenews.Id);
            formData.append('Id', o.thenews.Id as any);
            var result = await api.updateNews(formData);
        }
        else {
            console.log("create hits");
            var result = await api.createNews(formData);
        }

        return {}
    }

    @MutationAction
    async getAllNews(id: number) {
        const d = await api.getAllNews(id);
        const data: any = d;
        console.log(data);
        return { newsList: data as News[] }
    }

    @MutationAction
    async loadNewsById(id: any) {
        var datas = await api.getNewsById(id);
        var newsDoc = datas.newsDocsDTOList;
        var docCollection: any = [];
        var thenews = datas.newsDTO as News;
        var totalDoc = newsDoc.length;

        for (var x = 0; x < totalDoc; x++) {
            var filename = newsDoc[x].Document.substring(newsDoc[x].Document.lastIndexOf('/') + 1);
            var fullUrl = newsDoc[x].Document;
            docCollection.push({
                name: filename,
                url: fullUrl
            });
        }

        thenews.FileList = docCollection;
        return { thenews: thenews as News }
    }

    @MutationAction
    async deleteNews(news: News) {
        const o = this.state as NewsModule;
        if (news.NewsId !== undefined) {
            var i = o.newsList.findIndex(x => x.NewsId == news.NewsId);

            if (i >= 0) {
                o.newsList.splice(i, 1);
            }
        }

        else {
            var i = o.newsList.findIndex(x => x.Id == news.Id);

            if (i >= 0) {
                o.newsList.splice(i, 1);
            }
        }

        const data = await api.deleteNews(news);
        console.log(data);
        return {}
    }

    @MutationAction
    async getAllNewsCategory() {
        const d = await api.getAllNewsCategory();
        const data: any = d;
        return { newsCategoryList: data as NewsCategory[] }
    }

    @MutationAction
    async getAllNewsTypes() {
        const d = await api.getAllNewsType();
        const data: any = d;
        return { newsTypeList: data as NewsTypes[] }
    }
}

export default getModule(NewsModule);